<template>
  <div>
    <div v-if="loading">
      <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg" />
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <div>
            <div v-if="chosenConversation" class="text-center">
              <img class="feathericons-phone p-4 bg-primary" />
              <h6 class="font-weight-bold mt-3 mb-3 ">
                <span v-if="chosenConversation.type === 'one_on_one'">
                  {{ $t("kingOfTheCastle.detailsConversation.title") }}
                  {{ chosenConversation.attendees[0].profile.name }}
                </span>
                <span v-else>
                  {{ $t("kingOfTheCastle.detailsConversation.titleGom") }}
                </span>
              </h6>
            </div>
            <perfect-scrollbar>
              <table
                v-if="chosenConversation"
                id="style-3"
                class="table style-3 table-hover"
              >
                <tbody>
                  <!-- SUBJECT -->
                  <tr :class="isMobileScreenSize ? 'tr-border-top-grey' : ''" v-if="chosenConversation.type === 'one_on_one'">
                    <td class="td-table-mobile col-2 w-5-percent">
                      <span class="font-weight-bold">{{
                        $t("kingOfTheCastle.detailsConversation.comment")
                      }}</span>
                    </td>
                    <td class="td-table-mobile">
                      {{ chosenConversation.subject }}
                    </td>
                  </tr>
                  <!-- BOOKED AT -->
                  <tr :class="isMobileScreenSize ? 'tr-border-top-grey' : ''">
                    <td class="td-table-mobile col-2 w-5-percent">
                      <span class="font-weight-bold">{{
                        $t("kingOfTheCastle.detailsConversation.bookedAt")
                      }}</span>
                    </td>
                    <td class="td-table-mobile">
                      {{
                        moment(chosenConversation.appointment.date).format(
                          "DD.MM.YYYY"
                        )
                      }}
                    </td>
                  </tr>
                  <tr :class="isMobileScreenSize ? 'tr-border-top-grey' : ''">
                    <td class="td-table-mobile col-2 w-5-percent">
                      <span class="font-weight-bold">{{
                        $t("kingOfTheCastle.detailsConversation.beginning")
                      }}</span>
                    </td>
                    <td class="td-table-mobile">
                      {{ chosenConversation.appointment.time }}
                    </td>
                  </tr>
                  <tr :class="isMobileScreenSize ? 'tr-border-top-grey' : ''">
                    <td class="td-table-mobile col-2 w-5-percent">
                      <span class="font-weight-bold">{{
                        $t("kingOfTheCastle.detailsConversation.planedEnding")
                      }}</span>
                    </td>
                    <td class="td-table-mobile">
                      {{ chosenConversation.appointment.endTime }}
                    </td>
                  </tr>
                  <tr
                    :class="isMobileScreenSize ? 'tr-border-top-grey' : ''"
                    v-if="chosenConversation.hasOwnProperty('zoomMeeting')"
                  >
                    <td class="td-table-mobile col-2 w-5-percent">
                      <span class="font-weight-bold">{{
                        $t("kingOfTheCastle.detailsConversation.realEnding")
                      }}</span>
                    </td>
                    <td
                      class="td-table-mobile"
                      v-if="chosenConversation.zoomMeeting"
                    >
                      <div
                        v-if="
                          chosenConversation.zoomMeeting.hasOwnProperty(
                            'duration'
                          )
                        "
                      >
                        {{ chosenConversation.appointment.time }} -
                        {{
                          moment(chosenConversation.appointment.time, "HH:mm")
                            .add(
                              chosenConversation.zoomMeeting.duration,
                              "minutes"
                            )
                            .format("HH:mm")
                        }}
                      </div>
                    </td>
                    <td class="td-table-mobile" v-else>-</td>
                  </tr>
                  <tr
                    :class="isMobileScreenSize ? 'tr-border-top-grey' : ''"
                    v-if="chosenConversation.hasOwnProperty('zoomMeeting')"
                  >
                    <td class="td-table-mobile col-2 w-5-percent">
                      <span class="font-weight-bold">{{
                        $t("kingOfTheCastle.detailsConversation.duration")
                      }}</span>
                    </td>
                    <td
                      class="td-table-mobile"
                      v-if="chosenConversation.zoomMeeting"
                    >
                      <span
                        v-if="
                          chosenConversation.zoomMeeting.hasOwnProperty(
                            'duration'
                          )
                        "
                        >{{ chosenConversation.zoomMeeting.duration }} min</span
                      >
                    </td>
                    <td class="td-table-mobile" v-else>-</td>
                  </tr>
                  <tr
                    :class="isMobileScreenSize ? 'tr-border-top-grey' : ''"
                    v-if="chosenConversation.hasOwnProperty('zoomMeeting') && chosenConversation.type === 'one_on_one' && chosenConversation.host.role !== 'student'"
                  >
                    <td class="td-table-mobile col-2 w-5-percent">
                      <span class="font-weight-bold">{{
                        $t("kingOfTheCastle.detailsConversation.costs")
                      }}</span>
                    </td>
                    <td
                      class="td-table-mobile"
                      v-if="chosenConversation.zoomMeeting"
                    >
                      <div
                        v-if="
                          chosenConversation.zoomMeeting.hasOwnProperty(
                            'duration'
                          )
                        "
                      >
                        {{ formattedPrice }}
                      </div>
                    </td>
                    <td class="td-table-mobile" v-else>-</td>
                  </tr>
                  <tr
                    :class="isMobileScreenSize ? 'tr-border-top-grey' : ''"
                    v-if="chosenConversation.hasOwnProperty('voucher')"
                  >
                    <td class="td-table-mobile col-2 w-5-percent">
                      <span class="font-weight-bold">{{
                        $t("kingOfTheCastle.detailsConversation.voucher")
                      }}</span>
                    </td>
                    <td class="td-table-mobile">
                      {{ chosenConversation.voucher }}
                    </td>
                  </tr>
                  <tr :class="isMobileScreenSize ? 'tr-border-top-grey' : ''">
                    <td class="col-3 td-table-mobile col-2 w-5-percent">
                      <span class="font-weight-bold">
                        {{
                          $t(
                            "kingOfTheCastle.detailsConversation.audioRecording"
                          )
                        }}
                      </span>
                    </td>
                    <td class="col-6 td-table-mobile">
                      <div v-if="chosenConversation.zoomMeeting">
                        <button
                          v-if="
                            chosenConversation.zoomMeeting.recordingReceived
                          "
                          @click="getRecording()"
                          class="btn btn-outline-primary"
                        >
                          {{
                            $t(
                              "kingOfTheCastle.detailsConversation.audioRecording"
                            )
                          }}
                        </button>
                        <span v-else>{{
                          $t("kingOfTheCastle.audioRecordingNotYetAvailable")
                        }}</span>
                      </div>
                      <span v-else>{{
                        $t("kingOfTheCastle.audioRecordingNotYetAvailable")
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </perfect-scrollbar>
          </div>
        </b-col>
      </b-row>
    </div>
    <notifications classes="top-right-notification" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as conversationApi from "../../store/modules/conversations/conversations.api";
import { addNotification } from "@/utils/notificationHandler";
import moment from "moment";
import FileSaver from "file-saver";
import { convertToLocal } from "@/utils/timezoneHelper";
import screenSizeMixin from "@/mixins/screenSize";

export default {
  name: "ConversationSummary",
  props: {
    conversation: Object,
    conversationSlot: Object
  },
  mixins: [screenSizeMixin],
  data() {
    return {
      moment: moment,
      loading: false,
      loadingError: false,
      currentUser: null,
      chosenConversation: null,
      dummyClients: [
        {
          _id: "Seelenschreiben",
          firstName: "Max",
          lastName: "Mustermann",
          dateTime: "13.12.2021 - 20:00",
          isActive: false
        }
      ],
      dummyConversation: {
        date: "13.12.2021",
        time: "20:00",
        conversationDetails: {
          _id: "12345",
          phoneNumber: "123456789",
          email: "max.mustermann@gmail.com",
          country: "Deutschland",
          plz: "94032",
          town: "Passau",
          address: "Spitalhofstr 8",
          bookedAt: "12.12.2019 - 21:59",
          beginning: "13.12.2019 - 19:30",
          plannedEnding: "13.12.2019 - 20:00",
          realEnding: "13.12.2019 - 19:56",
          comment: "Test",
          duration: "26 Minutes",
          costs: "56.76€",
          zoomId: "123459789",
          ratingId: "14",
          rating: "noch nicht vertieft",
          voucher: "",
          purchaseUrl: "Link",
          purchaseId: "UKWUPF5U003"
        }
      }
    };
  },
  computed: {
    ...mapGetters("users", ["getCurrentUser"]),
    formattedPrice() {
      if (this.chosenConversation.zoomMeeting) {
        if (this.chosenConversation.zoomMeeting.duration) {
          const price =
            this.chosenConversation.zoomMeeting.duration *
            this.chosenConversation.host.companionStudentSettings.prices
              .conversation;
          const num = Number(price);
          const roundedString = num.toFixed(2);
         // const roundedPrice = Number(roundedString);
       //   return roundedPrice;
          return roundedString + " €";
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    }
  },
  methods: {
    getRecording() {
      conversationApi
        .getRecording(this.chosenConversation._id)
        .then(res => {
          const recording = res.data;
          const file = new File([recording], "Audiodatei.m4a");
          FileSaver.saveAs(file);

          addNotification(
            "success",
            this.$t("misc.success"),
            this.$t("kingOfTheCastle.success.recordingFound")
          );
        })
        .catch(err => {
          addNotification(
            "error",
            this.$t("misc.error"),
            this.$t("kingOfTheCastle.error.recordingNotFound")
          );
        });
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }

    if (this.conversation) {
      this.chosenConversation = this.conversation;
    }
    if (this.conversationSlot) {
      this.loading = true;
      this.loadingError = false;
      conversationApi
        .listConversations()
        .then(res => {
          const conversations = res.data.data;
          let conversation = null;
          if (this.conversationSlot.conversation) {
            conversation = conversations.find(
              conv => conv._id === this.conversationSlot.conversation._id
            );
          } else {
            conversation = conversations.find(
              conv => conv._id === this.conversationSlot._id
            );
          }
          const convertedUtc = convertToLocal(
            conversation.appointment.date,
            conversation.appointment.endDate,
            conversation.appointment.time,
            conversation.appointment.endTime
          );
          const formattedAppointment = {
            date: convertedUtc.date,
            endDate: convertedUtc.endDate,
            time: convertedUtc.time,
            endTime: convertedUtc.endTime
          };
          const formattedConversation = {
            appointment: formattedAppointment,
            attendees: conversation.attendees,
            createTimeStamp: conversation.createTimeStamp,
            host: conversation.host,
            status: conversation.status,
            subject: conversation.subject,
            updateTimeStamp: conversation.updateTimeStamp,
            zoomMeeting: conversation.zoomMeeting,
            type: conversation.type,
            _id: conversation._id
          };
          this.chosenConversation = formattedConversation;
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
          throw err;
        });
    }
  }
};
</script>

<style scoped></style>
