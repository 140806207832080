
import Vue from "vue";
import i18n from "@/config/i18n";
import Sidebar from "@/components/layout/Sidebar.vue";
import Header from "@/components/layout/Header.vue";
import { mapActions, mapGetters } from "vuex";
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import moment from "moment";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import * as conversationApi from "@/store/modules/conversations/conversations.api";
import { convertToLocal } from "@/utils/timezoneHelper";
import { helpers, maxLength, required } from "vuelidate/lib/validators";
import * as userApi from "@/store/modules/users/users.api";
import { addNotification } from "@/utils/notificationHandler";
const germanAlpha = helpers.regex("gerAlpha", /^[a-zA-ZäöüÄÖÜß]+$/);
const telephoneNumber = helpers.regex("phoneNumber", /^[+0][0-9+]{1,15}$/);
moment.locale("de");
import screenSizeMixin from "../mixins/screenSize";

const pageTitleTemplate: string =
  i18n.t("pages.titleFormat") +
  (process.env.NODE_ENV !== "production"
    ? " (" + i18n.t("environments." + process.env.NODE_ENV) + ")"
    : "");

export default Vue.extend({
  name: "AppLayout",
  mixins: [screenSizeMixin],
  data() {
    return {
      loadingPortal: true,
      loadingPortalError: false,
      isInsertFullNameModalOpen: false,
      profileFirstName: null,
      profileLastName: null,
      profileTelNr: null,
      renderEverything: false,
      futureAppointments: [],
      isStartConversationWithPhone: false,
      isShowComment: false,
      loading: false,
      loadingError: false,
      timerInterval: null,
      timerIntervalCron: null,
      variantOption: "warning",
      currentUser: null,
      isTimeUp: false,
      moment: moment,
      showNotificationTop: true,
      timerEnabled: true,
      showTimerMinimum: 1200000, // 20min  3600000 = 60min
      forceRerenderTimer: Date.now(),
      futureAppointmentsWithoutZoom: []
    };
  },
  validations: {
    profileFirstName: { germanAlpha, required },
    profileLastName: { germanAlpha, required },
    profileTelNr: { telephoneNumber, telNrMaxLength: maxLength(15) }
  },
  components: {
    Sidebar,
    Header
  },
  computed: {
    ...mapGetters("sideBar", ["isSidebarOpen"]),
    ...mapGetters("users", ["getCurrentUser"]),
    formattedTimeCounter() {
      if (this.timeToNextAppointmentMs <= this.showTimerMinimum) {
        if (this.timeToNextAppointmentMs >= 0) {
          const nextAppointmentMoment = moment("2015-01-01")
            .startOf("day")
            .milliseconds(this.timeToNextAppointmentMs);
          return nextAppointmentMoment.format("mm:ss");
        } else {
          return "00:00";
        }
      }
      return "-";
    },
    formattedVariantOption() {
      if (this.timeToNextAppointmentMs < 1000) {
        return "danger";
      } else {
        return "warning";
      }
    },
    nextAppointment() {
      if (this.futureAppointments.length > 0) {
        return this.futureAppointments[0];
      }
      return null;
    },
    timeToNextAppointmentMs() {
      if (this.nextAppointment && this.forceRerenderTimer) {
        const toDay = moment();
        const toDayTime = moment(toDay, "HH:mm");
        if (
          moment(this.nextAppointment.appointment.time, "HH.mm").diff(
            toDayTime
          ) < this.showTimerMinimum
        ) {
          const timeDifference = moment(
            this.nextAppointment.appointment.time,
            "HH:mm"
          ).diff(toDayTime);
          return timeDifference;
        }
      }
      return this.showTimerMinimum + 1000;
    }
  },
  methods: {
    ...mapActions("sideBar", ["toggleSidebar"]),
    ...mapActions("users", ["setCurrentUser", "setCurrentUserExtern"]),
    expandHeader() {
      this.toggleSidebar();
    },
    setIntervalFinished(isTimeUp) {
      this.isTimeUp = isTimeUp;
    },
    hideToast() {
      if (this.currentUser.role !== EUserRoles.CLIENT) {
        this.$bvToast.hide("zoom-meeting-toast");
      }
    },
    toast() {
      if (this.currentUser.role !== EUserRoles.CLIENT) {
        this.$bvToast.show("zoom-meeting-toast");
      }
    },
    openZoomMeetingCompanionModal() {
      this.$refs["zoomMeetingCompanionModal"].show();
    },
    closeZoomMeetingCompanionModal() {
      this.$refs["zoomMeetingCompanionModal"].hide();
    },
    closeZoomMeetingClientModal() {
      this.$refs["zoomMeetingClientModal"].hide();
    },
    showComment() {
      this.isShowComment = !this.isShowComment;
    },
    startZoomConversation() {
      window.open(this.nextAppointment.zoomMeeting.startUrl);
    },
    joinZoomConversation() {
      window.open(this.nextAppointment.zoomMeeting.joinUrl);
    },
    initFutureAppointments(conversations) {
      this.futureAppointments = [];
      const tmpFutureAppointments = [];
      conversations.forEach(conv => {
        const zoomMeeting = {
          _id: conv.zoomMeeting._id,
          startUrl: conv.zoomMeeting.startUrl,
          joinUrl: conv.zoomMeeting.joinUrl,
          duration: conv.zoomMeeting.duration,
          recordingReceived: conv.zoomMeeting.recordingReceived
        };
        const appointment = conv.appointment;
        const convertedUtc = convertToLocal(
          appointment.date,
          appointment.endDate,
          appointment.time,
          appointment.endTime
        );
        const formattedAppointment = {
          date: convertedUtc.date,
          endDate: convertedUtc.endDate,
          time: convertedUtc.time,
          endTime: convertedUtc.endTime,
          _id: appointment._id
        };
        const client = conv.attendees[0];
        const host = conv.host;
        const futureAppointment = {
          zoomMeeting: zoomMeeting,
          appointment: formattedAppointment,
          client: client,
          host: host,
          status: conv.status,
          subject: conv.subject,
          _id: conv._id,
          updateTimeStamp: conv.updateTimeStamp,
          createTimeStamp: conv.createTimeStamp
        };
        const toDay = moment().format("YYYY-MM-DD");
        const toDayTime = moment().format("HH:mm");
        const toDayTimeFormatted = moment(toDayTime, "HH:mm");

        if (moment(formattedAppointment.date).diff(moment(toDay)) >= 0) {
          if (
            moment(formattedAppointment.time, "HH:mm").diff(
              toDayTimeFormatted
            ) > -300000 // 5min
          ) {
            tmpFutureAppointments.push(futureAppointment);
            //this.futureAppointments.push(futureAppointment);
          }
        }
      });
      if (tmpFutureAppointments.length === this.futureAppointments.length) {
        return;
      } else {
        this.futureAppointments = JSON.parse(
          JSON.stringify(tmpFutureAppointments)
        );
      }
      const tempFutureAppointments = this.futureAppointments.sort(
        (a, b) =>
          parseInt(moment(a.appointment.time, "HH:mm").format("HHmm")) -
          parseInt(moment(b.appointment.time, "HH:mm").format("HHmm"))
      );
      this.futureAppointments = JSON.parse(
        JSON.stringify(tempFutureAppointments)
      );
    },
    listConversations() {
      //this.loading = true;
      // this.loadingError = false;
      conversationApi.listConversations({}).then(res => {
        const conversations = res.data.data;
        if (conversations.length > 0) {
          this.conversations = [];
          const conversationsWithZoom = conversations.filter(
            conversation => conversation.zoomMeeting
          );
          this.initFutureAppointments(conversationsWithZoom);
        }
        //  this.loading = false;
        // this.loadingError = false;
      });
      //.catch(err => {
      // this.loading = false;
      // this.loadingError = true;
      //});
    },
    refreshTimerCountdown() {
      if (this.timeToNextAppointmentMs <= this.showTimerMinimum) {
        if (this.currentUser.role === EUserRoles.CLIENT) {
          if (
            this.timeToNextAppointmentMs >= -2000 &&
            this.timeToNextAppointmentMs < 2000
          ) {
            this.isTimeUp = true;
            this.$refs["zoomMeetingClientModal"].show();
          }
        }
        if (this.timeToNextAppointmentMs < -5 * 60 * 1000) {
          this.futureAppointments.shift();
          this.isTimeUp = false;
        }
        this.forceRerenderTimer = Date.now();
        this.toast();
      } else {
        this.hideToast();
      }
    },
    updateUserProfile() {
      // this.loading = true;
      // this.loadingError = false;
      if (this.currentUser) {
        let profileData;
        if (this.profileTelNr) {
          profileData = {
            profile: {
              givenName: this.profileFirstName,
              familyName: this.profileLastName,
              phoneNumber: this.profileTelNr
            }
          };
        } else {
          profileData = {
            profile: {
              givenName: this.profileFirstName,
              familyName: this.profileLastName
            }
          };
        }
        userApi
          .updateUser(this.currentUser._id, profileData)
          .then(res => {
            userApi.getOwnUser().then(res => {
              const user = res.data.data;
              this.setCurrentUserExtern(user);
              addNotification(
                "success",
                this.$t("misc.success"),
                this.$t("profile.accountSettingsSaved")
              );
              this.renderEverything = false;
              setTimeout(() => (this.renderEverything = true), 2000);
              this.$refs["insertFullNameModal"].hide();
              window.location.replace("/dashboard");
            });
          })
          .catch(err => {
            addNotification(
              "error",
              this.$t("misc.error"),
              this.$t("profile.error.somethingWentWrong")
            );
          });
      }
    },
    closeInsertFullNameModal() {
      this.$refs["insertFullNameModal"].hide();
      this.isInsertFullNameModalOpen = false;
      this.profileFirstName = null;
      this.profileLastName = null;
      this.profileTelNr = null;
    },
    openInsertFullNameModal() {
      this.isInsertFullNameModalOpen = true;
      this.$refs["insertFullNameModal"].show();
    }
  },
  mounted() {
    this.setCurrentUser();
    const interval = 1000; // 1 Sec
    const inter = setInterval(() => {
      if (this.getCurrentUser) {
        this.currentUser = this.getCurrentUser;
        this.loadingPortal = false;
        clearInterval(inter);
      } else {
        this.loadingPortal = true;
      }
    }, interval);

    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
      if (
        !this.currentUser.profile.givenName ||
        !this.currentUser.profile.familyName
      ) {
        setTimeout(() => {
          this.openInsertFullNameModal();
        }, 2100);
      }
    }
    if (this.currentUser) {
      if (this.currentUser.role !== EUserRoles.ADMINISTRATOR) {
        this.listConversations();

        // NOTE: For testing purposes, this can be changed to 5 seconds here!
        const intervalCron = 300000; // 5 min
        // const intervalCron = 120000; // 2 min
        // const intervalCron = 30000; // 30 sec
        this.timerIntervalCron = setInterval(() => {
          if (this.getCurrentUser) {
            this.currentUser = this.getCurrentUser;
          }
          this.listConversations();
        }, intervalCron);

        const interval = 1000; // 1 Sec
        this.timerInterval = setInterval(() => {
          this.refreshTimerCountdown();
        }, interval);
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  }
});
