import developmentConfig from "@/config/environments/config.development";
import productionConfig from "@/config/environments/config.production";
import stagingConfig from "@/config/environments/config.staging";

export interface AppConfig {
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
  };
  sentry: {
    enabled: boolean;
    dsn: string;
  };
  logoutReturnUrl: string;
  backendUrl: string;
  frontendUrl: string;
  calendarModuleTimeZone: string;
  gomDuration: number;
  attendeeCancelPeriod: number;
}

let config: AppConfig;
switch (process.env.NODE_ENV) {
  case "production":
    config = productionConfig;
    break;
  case "staging":
    config = stagingConfig;
    break;
  default:
    config = developmentConfig;
    break;
}

export default config;
