<template>
  <div>
    <table
      v-if="
        !isMobileScreenSize &&
          !isModalMobileScreenSize &&
          (!isTabletScreenSize || !isSidebarOpen)
      "
      id="style-3"
      class="table style-3  table-hover"
    >
      <thead>
        <tr>
          <th class="text-center">
            {{ $t("conversations.status.title") }}
          </th>
          <th class="text-center">
            {{ $t("conversations.type") }}
          </th>
          <th class="text-center">
            {{ $t("conversations.date") }}
          </th>
          <th>
            {{ $t("conversations.comment") }}
          </th>
          <th>
            {{ $t("conversations.companion") }}
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(conversation, cIdx) in conversations" :key="cIdx">
          <td v-if="conversation.status !== 'cancelled'" class="text-center">
            <b-badge
              v-if="conversation.status === 'pending'"
              class="bg-danger font-size-2 shadow-none"
            >
              {{ $t("conversations.status.pending") }}
            </b-badge>
            <b-badge
              v-else-if="conversation.status === 'scheduled'"
              class="bg-success font-size-2 shadow-none"
            >
              {{ $t("conversations.status.scheduled") }}
            </b-badge>
            <b-badge
              v-else-if="conversation.status === 'unpaid'"
              class="bg-danger font-size-2 shadow-none"
            >
              {{ $t("conversations.status.unpaidClient") }}
            </b-badge>
            <b-badge
              v-else-if="conversation.status === 'completed'"
              class="bg-outline-primary font-size-2 shadow-none"
            >
              {{ $t("conversations.status.completed") }}
            </b-badge>
            <b-badge
              v-else-if="conversation.status === 'failed'"
              class="bg-danger font-size-2 shadow-none"
            >
              {{ $t("conversations.status.failed") }}
            </b-badge>
          </td>
          <td class="text-center" v-if="conversation.status !== 'cancelled'">
            <span>{{ $t(`conversations.types.${conversation.type}`) }}</span>
          </td>
          <td
            v-if="conversation.status !== 'cancelled'"
            class="text-center table-column-min-width-160px"
          >
            <span>
              {{ moment(conversation.appointment.date).format("DD.MM.YYYY") }} -
              {{ conversation.appointment.time }}
            </span>
          </td>
          <td v-if="conversation.status !== 'cancelled'">
            <p
              class="break-word"
              :class="
                conversation.subject.length > 150
                  ? 'restrict-paragraph-two-lines'
                  : ''
              "
            >
              {{ conversation.subject }}
            </p>
            <div
              v-if="
                (isTabletScreenSize || isSmDesktopScreenSize) &&
                  conversation.subject.length > 150
              "
              class="text-center"
            >
              <img
                class="feathericons-plus-circle bg-primary p-3 cursor-pointer"
                @click.prevent="openShowUserCommentModal(conversation.subject)"
              />
              <span
                class="color-primary cursor-pointer"
                @click.prevent="openShowUserCommentModal(conversation.subject)"
              >
                {{ $t("actions.showUserComment") }}
              </span>
            </div>
          </td>
          <td v-if="conversation.status !== 'cancelled'">
            <span v-if="conversation.host">
              {{ conversation.host.profile.name }}
            </span>
          </td>
          <td v-if="conversation.status === 'scheduled'">
            <div v-if="conversation.zoomMeeting">
              <div v-if="isShowZoomMeetingTimeCorrect(conversation)">
                <button
                  @click="joinZoomConversation(conversation)"
                  class="btn btn-outline-info"
                >
                  {{ $t("conversations.tableButtons.joinAppointment") }}
                </button>
              </div>
              <div v-else>
                <button
                  @click="emitOpenEditAppointmentModal(conversation)"
                  class="btn btn-outline-primary"
                  v-if="conversation.type === 'one_on_one'"
                >
                  {{ $t("conversations.tableButtons.rescheduleAppointment") }}
                </button>
                <button
                  @click="emitOpenLeaveAppointmentModal(conversation)"
                  class="btn btn-outline-danger"
                  :disabled="!conversation.canLeave"
                  v-else
                >
                  {{ $t("conversations.tableButtons.leaveAppointment") }}
                </button>
                <button
                  @click="emitOpenCancelAppointmentModal(conversation)"
                  class="btn btn-outline-danger"
                  :disabled="!conversation.canCancel"
                >
                  {{ $t("conversations.tableButtons.cancelAppointment") }}
                </button>
              </div>
            </div>
            <div v-else>
              <button
                @click="emitOpenEditAppointmentModal(conversation)"
                class="btn btn-outline-primary"
                v-if="conversation.type === 'one_on_one'"
              >
                {{ $t("conversations.tableButtons.rescheduleAppointment") }}
              </button>
              <button
                @click="emitOpenLeaveAppointmentModal(conversation)"
                class="btn btn-outline-danger"
                :disabled="!conversation.canLeave"
                v-else
              >
                {{ $t("conversations.tableButtons.leaveAppointment") }}
              </button>
              <button
                class="btn btn-outline-danger ml-1"
                @click="emitOpenCancelAppointmentModal(conversation)"
                :disabled="!conversation.canCancel"
              >
                {{ $t("conversations.tableButtons.cancelAppointment") }}
              </button>
            </div>
          </td>
          <td v-else-if="conversation.status === 'pending'">
            <div v-if="conversation.hasOwnProperty('transaction')">
              <button
                @click.prevent="
                  emitOpenTransactionMaybePaidModal(
                    conversation.transaction.buyUrl.url
                  )
                "
                class="btn btn-outline-danger"
              >
                {{ $t("conversations.tableButtons.payScheduleFee") }}
              </button>
            </div>
          </td>
          <td v-else-if="conversation.status === 'unpaid'">
            <div v-if="conversation.hasOwnProperty('transaction')">
              <div v-if="conversation.transaction.hasOwnProperty('buyUrl')">
                <button
                  @click.prevent="
                    emitOpenTransactionMaybePaidModal(
                      conversation.transaction.buyUrl.url
                    )
                  "
                  class="btn btn-outline-danger"
                >
                  {{ $t("conversations.tableButtons.payAppointment") }}
                </button>
              </div>
              <div v-else class="color-danger">
                <span>{{
                  $t("conversations.tableButtons.billingOnDemandInfo")
                }}</span>
              </div>
            </div>
          </td>
          <td v-else-if="conversation.status === 'completed'">
            <button
              class="btn btn-outline-primary"
              @click.prevent="emitOpenConversationDetailsModal(conversation)"
            >
              {{ $t("misc.showAppointment") }}
            </button>
          </td>
          <!--<td v-else>
          <p class="color-danger">
            {{ $t("conversations.bookingFailed") }}
          </p>
        </td>-->
        </tr>
      </tbody>
    </table>
    <table v-else id="style-2" class="table style-3  table-hover">
      <thead>
        <tr>
          <th>
            {{ $t("conversations.editCalendar") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="tr-border-top-grey"
          v-for="(conversation, cIdx) in conversations"
          :key="cIdx"
        >
          <table v-if="conversation.status !== 'cancelled'" style="width: 100%">
            <tr>
              <td
                class="col-3 border-0 font-weight-bold td-table-mobile td-first-col-mobile w-20-percent"
              >
                <span>{{ $t("conversations.status.title") }}</span>
              </td>
              <td class="col-9 border-0 td-table-mobile td-first-col-mobile">
                <StatusColumn :conversation="conversation" />
              </td>
            </tr>
            <tr>
              <td
                class="col-3 border-0 font-weight-bold td-table-mobile td-first-col-mobile w-20-percent"
              >
                <span>{{ $t("conversations.type") }}</span>
              </td>
              <td class="col-9 border-0 td-table-mobile td-first-col-mobile">
                <span>{{
                  $t(`conversations.types.${conversation.type}`)
                }}</span>
              </td>
            </tr>
            <tr>
              <td
                class="border-0 font-weight-bold td-table-mobile w-20-percent"
              >
                <span> {{ $t("conversations.date") }}</span>
              </td>
              <td class="border-0 td-table-mobile">
                <span>
                  {{
                    moment(conversation.appointment.date).format("DD.MM.YYYY")
                  }}
                  -
                  {{ conversation.appointment.time }}
                </span>
              </td>
            </tr>
            <tr>
              <td
                class="border-0 font-weight-bold td-table-mobile w-10-percent"
              >
                <span> {{ $t("conversations.comment") }}</span>
              </td>
              <td class="border-0 td-table-mobile break-word">
                <p class="restrict-paragraph-two-lines">
                  {{ conversation.subject }}
                </p>
                <div v-if="conversation.subject.length > 150">
                  <img
                    class="feathericons-plus-circle bg-primary p-3 cursor-pointer"
                    @click.prevent="
                      openShowUserCommentModal(conversation.subject)
                    "
                  />
                  <span
                    class="color-primary cursor-pointer"
                    @click.prevent="
                      openShowUserCommentModal(conversation.subject)
                    "
                  >
                    {{ $t("actions.showUserComment") }}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td
                class="border-0 font-weight-bold td-table-mobile w-20-percent"
              >
                <span>{{ $t("conversations.companion") }}</span>
              </td>
              <td class="border-0 td-table-mobile">
                <span v-if="conversation.host">
                  {{ conversation.host.profile.name }}
                </span>
              </td>
            </tr>

            <tr>
              <td
                class="border-0 font-weight-bold td-table-mobile w-20-percent"
              >
                <span>{{ $t("conversations.attendee") }}</span>
              </td>
              <td class="border-0 td-table-mobile">
                <span v-if="conversation.attendees">
                  <span
                    v-for="(attendee, attendeeIdx) in conversation.attendees"
                    :key="attendeeIdx"
                  >
                    <span v-if="attendeeIdx > 0">, </span>
                    {{ attendee.profile.givenName }}
                  </span>
                </span>
              </td>
            </tr>
          </table>

          <div
            v-if="conversation.status !== 'cancelled'"
            class="text-center ml-auto mr-auto pb-4 pt-4 pl-3 pr-3"
            style="width: 100%;"
          >
            <ButtonsColumn
              :conversation="conversation"
              :emit-is-show-zoom-meeting-time-correct="
                isShowZoomMeetingTimeCorrect
              "
              :emit-join-zoom-conversation="joinZoomConversation"
              :emit-open-conversation-details-modal="
                emitOpenConversationDetailsModal
              "
              :emit-open-edit-appointment-modal="emitOpenEditAppointmentModal"
              :emit-open-transaction-maybe-paid-modal="
                emitOpenTransactionMaybePaidModal
              "
              :emit-open-leave-appointment-modal="emitOpenLeaveAppointmentModal"
              :emit-open-cancel-appointment-modal="emitOpenCancelAppointmentModal"
            />
          </div>
        </tr>
      </tbody>
    </table>
    <!-- Show Comment Modal -->
    <b-modal
      ref="showUserCommentModal"
      size="lg"
      :title="$t('modals.showUserCommentModal.title')"
      hide-footer
      @hide="closeShowUserCommentModal()"
    >
      <b-row>
        <b-col>
          <p v-if="userComment" class="break-word">
            {{ userComment }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <button
            class="btn btn-outline-primary"
            @click.prevent="closeShowUserCommentModal()"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import * as moment from "moment";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import { displayUsernameToEmail } from "../../utils/userHelper";
import StatusColumn from "@/components/conversations/ConversationsTableColumn/StatusColumn";
import ButtonsColumn from "@/components/conversations/ConversationsTableColumn/ButtonsColumn";
import screenSizeMixin from "@/mixins/screenSize";
import { mapGetters } from "vuex";
import { convertToLocal, convertToLocalMoment } from "@/utils/timezoneHelper";
import { EConversationTypes } from "@/store/modules/conversations/EConversationTypes";

export default {
  name: "ConversationsTable",
  components: { ButtonsColumn, StatusColumn },
  props: {
    currentUser: Object,
    sortedConversations: Array,
    isMobileScreenSize: Boolean,
    isTabletScreenSize: Boolean,
    isModalMobileScreenSize: Boolean,
    isSmDesktopScreenSize: Boolean,
    openConversationDetailsModal: Function,
    openTransactionMaybePaidModal: Function,
    openEditAppointmentModal: Function,
    openLeaveAppointmentModal: Function,
    openCancelAppointmentModal: Function
  },
  data() {
    return {
      displayUsernameToEmail,
      EUserRoles,
      moment,
      userComment: null,
      conversations: []
    };
  },
  computed: {
    ...mapGetters("sideBar", ["isSidebarOpen"])
  },
  methods: {
    openShowUserCommentModal(userComment) {
      this.userComment = userComment;
      this.$refs["showUserCommentModal"].show();
    },
    closeShowUserCommentModal() {
      this.userComment = null;
      this.$refs["showUserCommentModal"].hide();
    },
    emitOpenCancelAppointmentModal(conversation) {
      this.openCancelAppointmentModal(conversation);
    },
    emitOpenLeaveAppointmentModal(conversation) {
      this.openLeaveAppointmentModal(conversation);
    },
    emitOpenEditAppointmentModal(conversation) {
      this.openEditAppointmentModal(conversation);
    },
    emitOpenTransactionMaybePaidModal(buyUrl) {
      this.openTransactionMaybePaidModal(buyUrl);
    },
    emitOpenConversationDetailsModal(conversation) {
      this.openConversationDetailsModal(conversation);
    },
    joinZoomConversation(conversation) {
      window.open(conversation.zoomMeeting.joinUrl);
    },
    isShowZoomMeetingTimeCorrect(conversation) {
      const appointment = convertToLocal(
        conversation.appointment.date,
        conversation.appointment.endDate,
        conversation.appointment.time,
        conversation.appointment.endTime
      );

      const today = moment().format("YYYY-MM-DD");
      const toDayTime = moment().format("HH:mm");
      const toDayTimeFormatted = moment(toDayTime, "HH:mm");

      if (moment(appointment.date).diff(moment(today)) >= 0) {
        if (
          moment(appointment.time, "HH:mm").diff(toDayTimeFormatted) < 1200000
        ) {
          // 1200000 ms === 20 min
          // 3600000 ms === 60 min
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    this.conversations = this.sortedConversations.map(conversation => {
      const conversationMoment = convertToLocalMoment(
        conversation.appointment.date,
        conversation.appointment.time
      );
      const nowMoment = moment();
      const canLeave = conversationMoment.subtract(1, "days") > nowMoment;
      const canCancel =
        (!conversation.zoomMeeting || !conversation.zoomMeeting.joinUrl) &&
        conversationMoment.subtract(1, "days") > nowMoment;
      return {
        ...conversation,
        canLeave,
        canCancel,
        subject:
          conversation.type === EConversationTypes.ONE_ON_ONE
            ? conversation.subject
            : conversation.appointment.metadata.isFollowupSlot
            ? this.$t("conversations.gomSubjectFollowup")
            : this.$t("conversations.gomSubject")
      };
    });
  }
};
</script>

<style scoped></style>
