// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import moment, { Moment } from "moment-timezone";
import config from "@/config/config";

function paddy(num: number, padlen: number, padchar: string) {
  padchar = typeof padchar !== "undefined" ? padchar : "0";
  const pad = new Array(1 + padlen).join(padchar);
  return (pad + num).slice(-pad.length);
}

function formatDate(moment: Moment) {
  return `${paddy(moment.year(), 4, "0")}-${paddy(moment.month() + 1, 2, "0")}-${paddy(moment.date(), 2, "0")}`;
}

function formatTime(moment: Moment) {
  return `${paddy(moment.hour(), 2, "0")}:${paddy(moment.minute(), 2, "0")}`;
}

export function convertToLocalMoment(date, time) {
  return moment.tz(`${date} ${time}`, config.calendarModuleTimeZone).local();
}

export function convertToLocal(date, endDate, time, endTime) {
  const startDateMoment = moment
    .tz(`${date} ${time}`, config.calendarModuleTimeZone)
    .local();

  const endDateMoment = moment
    .tz(`${endDate} ${endTime}`, config.calendarModuleTimeZone)
    .local();

  return {
    date: formatDate(startDateMoment),
    endDate: formatDate(endDateMoment),
    time: formatTime(startDateMoment),
    endTime: formatTime(endDateMoment)
  };
}

export function convertToCalendarTime(date, endDate, time, endTime) {
  const startDateMoment = moment(`${date} ${time}`).tz(
    config.calendarModuleTimeZone
  );

  const endDateMoment = moment(`${endDate} ${endTime}`).tz(
    config.calendarModuleTimeZone
  );

  return {
    date: formatDate(startDateMoment),
    endDate: formatDate(endDateMoment),
    time: formatTime(startDateMoment),
    endTime: formatTime(endDateMoment)
  };
}
