import i18n from "@/config/i18n";

const sidebarElements = [
  {
    id: "dashboard",
    title: i18n.t("sidebar.dashboard"),
    icon: "feathericons-home",
    to: "dashboard",
    userRoles: "client"
  },
  {
    id: "soulLetters",
    title: i18n.t("sidebar.soulLetters"),
    icon: "feathericons-feather",
    to: "soulLetters",
    userRoles: "client"
  },
  {
    id: "conversations",
    title: i18n.t("sidebar.conversations"),
    icon: "feathericons-phone",
    to: "conversations",
    userRoles: "client"
  },
  {
    id: "vouchers",
    title: i18n.t("vouchers.sideBarTitle"),
    icon: "feathericons-book",
    to: "vouchers",
    userRoles: "admin"
  },
  {
    id: "kingOfTheCastle",
    title: i18n.t("kingOfTheCastle.sideBarTitle"),
    icon: "feathericons-book-open",
    to: "kingOfTheCastle",
    userRoles: "admin"
  },
  {
    id: "unpaidConversations",
    title: i18n.t("unpaidConversations.title"),
    icon: "feathericons-file-text",
    to: "unpaidConversations",
    userRoles: "admin"
  },
  {
    id: "loginAs",
    title: i18n.t("loginAs.sideBarTitle"),
    icon: "feathericons-eye",
    to: "loginAs",
    userRoles: "admin"
  }
];

export default sidebarElements;
