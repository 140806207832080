export default {
  state: {
    isTimeReminderNotificationOpen: false
  },
  getters: {
    isTimeReminderNotificationOpen(state) {
      return state.isTimeReminderNotificationOpen;
    }
  },
  mutations: {
    setTimeReminderNotificationOpen(state, payload) {
      state.isTimeReminderNotificationOpen = payload === false;
    }
  },
  actions: {
    toggleTimeReminderNotification({ commit, dispatch, state }, payload) {
      commit(
        "setTimeReminderNotificationOpen",
        !state.isTimeReminderNotificationOpen
      );
    }
  },
  modules: {}
};
