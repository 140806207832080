<template>
  <div id="content" class="main-content">
    <div class="m-4">
      <div class="row layout-spacing layout-top-spacing" id="cancel-row">
        <div class="col-xl-12 col-sm-12 layout-spacing">
          <div class="statbox widget box box-shadow">
            <div class="widget-header">
              <b-row>
                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                  <h4>{{ $t("kingOfTheCastle.title") }}</h4>
                </div>
              </b-row>
            </div>
            <div class="widget-content widget-content-area">
              <div v-if="loading">
                <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                  <div
                    class="spinner-border text-success align-self-center loader-lg"
                  />
                </div>
              </div>
              <div v-else-if="loadingError" class="cm-loading-error">
                {{ $t("calendarModule.errors.loadingError") }}
              </div>
              <b-row v-else>
                <b-col class="col-4">
                  <h6 class="font-weight-bold mb-5">
                    {{ $t("kingOfTheCastle.companionsAndStudents") }}
                  </h6>
                  <div v-if="loadingUser">
                    <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                      <div
                        class="spinner-border text-success align-self-center loader-lg"
                      />
                    </div>
                  </div>
                  <div v-else-if="loadingErrorUser" class="cm-loading-error">
                    {{ $t("calendarModule.errors.loadingError") }}
                  </div>
                  <div v-else>
                    <perfect-scrollbar>
                      <ul
                        v-if="ownUser"
                        style="height: 100vh;"
                        class="list-group list-group-media"
                      >
                        <li
                          class="list-group-item list-group-item-action cursor-pointer"
                          :class="ownUser.profile.isActive ? 'active' : ''"
                          @click="
                            changeActiveOwnUser(), getUserHistory(ownUser)
                          "
                        >
                          <div class="media">
                            <div class="mr-3">
                              <img
                                alt="avatar"
                                :src="ownUser.profile.picture"
                                class="img-fluid rounded-circle max-width-profile-picture-small"
                              />
                            </div>
                            <div class="media-body">
                              <h6 class="tx-inverse break-word">
                                {{ ownUser.profile.name }}
                              </h6>
                              <p
                                v-if="
                                  ownUser.profile.role === EUserRoles.COMPANION
                                "
                                class="mg-b-0"
                              >
                                {{ $t("kingOfTheCastle.companions") }}
                              </p>
                              <p
                                v-else-if="
                                  ownUser.profile.role === EUserRoles.STUDENT
                                "
                                class="mg-b-0"
                              >
                                {{ $t("kingOfTheCastle.student") }}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <ul
                        v-else-if="!ownUser"
                        style="height:100vh;"
                        class="list-group list-group-media"
                      >
                        <li
                          v-for="(user, uIdx) in allUsers"
                          :key="uIdx"
                          class="list-group-item list-group-item-action cursor-pointer"
                          :class="user.profile.isActive ? 'active' : ''"
                          @click="changeActiveUser(uIdx), getUserHistory(user)"
                        >
                          <div class="media">
                            <div class="mr-3">
                              <img
                                alt="avatar"
                                :src="user.profile.picture"
                                class="img-fluid rounded-circle max-width-profile-picture-small"
                              />
                            </div>
                            <div class="media-body">
                              <h6 class="tx-inverse break-word">
                                {{ user.profile.name }}
                              </h6>
                              <p
                                v-if="
                                  user.profile.role === EUserRoles.COMPANION
                                "
                                class="mg-b-0"
                              >
                                {{ $t("kingOfTheCastle.companions") }}
                              </p>
                              <p
                                v-else-if="
                                  user.profile.role === EUserRoles.STUDENT
                                "
                                class="mg-b-0"
                              >
                                {{ $t("kingOfTheCastle.student") }}
                              </p>
                              <p
                                v-else-if="
                                  user.profile.role === EUserRoles.CLIENT
                                "
                                class="mg-b-0"
                              >
                                {{ $t("kingOfTheCastle.client") }}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </perfect-scrollbar>
                  </div>
                </b-col>
                <b-col class="col-3 border-right border-left border-grey">
                  <div v-if="loadingSoulLettersConversations">
                    <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                      <div
                        class="spinner-border text-success align-self-center loader-lg"
                      />
                    </div>
                  </div>
                  <div
                    v-else-if="loadingErrorSoulLettersConversations"
                    class="cm-loading-error"
                  >
                    {{ $t("calendarModule.errors.loadingError") }}
                  </div>
                  <div v-else>
                    <h6 class="font-weight-bold mb-5">
                      {{ $t("kingOfTheCastle.conversationsAndLetters") }}
                    </h6>
                    <!-- SoulLetters-->
                    <p
                      v-if="historySoulLetters"
                      class="font-weight-bold mb-4 mt-4"
                    >
                      <span v-if="historySoulLetters.length > 0">{{
                        $t("kingOfTheCastle.soulLetters")
                      }}</span>
                    </p>
                    <perfect-scrollbar>
                      <ul
                        style="max-height: 50vh;"
                        class="list-group list-group-media"
                      >
                        <li
                          v-for="(letter, lIdx) in historySoulLetters"
                          :key="lIdx"
                          :class="letter.isActive ? 'active' : ''"
                          class="list-group-item list-group-item-action cursor-pointer mb-3"
                          @click="changeActiveSoulLetter(lIdx, letter)"
                        >
                          <div class="media">
                            <div class="media-body">
                              <h6 class="tx-inverse break-word">
                                {{ letter.title }} mit<br />
                                <p class="mg-b-0">
                                  {{ letter.sender.profile.name }} <br />
                                  {{
                                    moment(
                                      letter.latestRevision.createTimeStamp
                                    ).format("YYYY-MM-DD")
                                  }}
                                </p>
                              </h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </perfect-scrollbar>
                    <!-- Conversations -->
                    <p
                      v-if="historyConversations"
                      class="font-weight-bold mb-4 mt-4"
                    >
                      <span v-if="historyConversations.length > 0">{{
                        $t("kingOfTheCastle.conversations")
                      }}</span>
                    </p>
                    <perfect-scrollbar>
                      <ul
                        style="max-height: 50vh;"
                        class="list-group list-group-media"
                      >
                        <li
                          v-for="(conversation, cIdx) in historyConversations"
                          :key="cIdx"
                          :class="conversation.isActive ? 'active' : ''"
                          class="list-group-item list-group-item-action cursor-pointer border border-grey border-radius-5px mb-3"
                          @click="changeActiveConversation(cIdx, conversation)"
                        >
                          <div class="media">
                            <div class="media-body">
                              <h6 class="tx-inverse break-word">
                                {{ conversation.subject }} mit<br />
                                <p class="mg-b-0">
                                  {{ conversation.attendees[0].profile.name
                                  }}<br />
                                  {{ conversation.appointment.date }}:
                                  {{ conversation.appointment.time }} -
                                  {{ conversation.appointment.endTime }}
                                </p>
                              </h6>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </perfect-scrollbar>
                  </div>
                </b-col>
                <b-col class="col-5">
                  <div v-if="loadingDetails">
                    <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                      <div
                        class="spinner-border text-success align-self-center loader-lg"
                      />
                    </div>
                  </div>
                  <div v-else-if="loadingErrorDetails" class="cm-loading-error">
                    {{ $t("calendarModule.errors.loadingError") }}
                  </div>
                  <div v-else style="position: fixed; width: 35%;">
                    <SoulLetterSummary
                      v-if="isSoulLetterClicked"
                      :soul-letter="chosenSoulLetter"
                      :key="rerenderSoulLetter"
                    />
                    <ConversationSummary
                      v-if="isConversationClicked"
                      :conversation="chosenConversation"
                      :key="rerenderConversation"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import * as userApi from "@/store/modules/users/users.api";
import * as moment from "moment";
import { convertToLocal } from "@/utils/timezoneHelper";
export default {
  name: "KingOfTheCastle",
  title: "Historie",
  components: {
    ConversationSummary: () =>
      import("@/components/conversations/ConversationSummary"),
    SoulLetterSummary: () =>
      import("@/components/soulLetters/SoulLetterSummary")
  },
  data() {
    return {
      rerenderConversation: false,
      rerenderSoulLetter: false,
      isConversationClicked: false,
      isSoulLetterClicked: false,
      chosenConversation: null,
      chosenSoulLetter: null,
      moment,
      EUserRoles,
      loading: false,
      loadingDetails: false,
      loadingUser: false,
      loadingSoulLettersConversations: false,
      loadingError: false,
      loadingErrorDetails: false,
      loadingErrorUser: false,
      loadingErrorSoulLettersConversations: false,
      historySoulLetters: null,
      historyConversations: null,
      currentUser: null,
      allUsers: [],
      ownUser: null,
      lastUserIdx: null,
      lastConversationIdx: null,
      lastSoulLetterIdx: null,
      chosenConversationIdx: null,
      chosenSoulLetterIdx: null
    };
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"])
  },
  methods: {
    formatSoulLetters(soulLetters) {
      const formattedLetters = [];
      soulLetters.forEach(letter => {
        const formattedLetter = {
          _id: letter._id,
          title: letter.title,
          isActive: false,
          status: letter.status,
          sender: letter.sender,
          recipient: letter.recipient,
          latestRevision: letter.latestRevision
        };
        formattedLetters.push(formattedLetter);
      });
      return formattedLetters;
    },
    formatConversations(conversations) {
      const formattedConversations = [];
      conversations.forEach(conversation => {
        const convertedUtc = convertToLocal(
          conversation.appointment.date,
          conversation.appointment.endDate,
          conversation.appointment.time,
          conversation.appointment.endTime
        );
        const formattedAppointment = {
          date: convertedUtc.date,
          endDate: convertedUtc.endDate,
          time: convertedUtc.time,
          endTime: convertedUtc.endTime
        };
        let formattedConversation;
        if (conversation.zoomMeeting) {
          formattedConversation = {
            _id: conversation._id,
            subject: conversation.subject,
            isActive: false,
            host: conversation.host,
            attendees: conversation.attendees,
            appointment: formattedAppointment,
            zoomMeeting: conversation.zoomMeeting
          };
        } else {
          formattedConversation = {
            _id: conversation._id,
            subject: conversation.subject,
            isActive: false,
            host: conversation.host,
            attendees: conversation.attendees,
            appointment: formattedAppointment
          };
        }
        formattedConversations.push(formattedConversation);
      });
      return formattedConversations;
    },
    getUserHistory(currentUser) {
      this.historyConversations = [];
      this.historySoulLetters = [];
      if (currentUser.profile.isActive) {
        this.loadingSoulLettersConversations = true;
        this.loadingErrorSoulLettersConversations = false;
        userApi
          .getUserHistory(currentUser._id)
          .then(res => {
            const userHistory = res.data.data;
            const formattedSoulLetters = this.formatSoulLetters(
              userHistory.letters
            );
            this.historySoulLetters = formattedSoulLetters;

            const formattedConversations = this.formatConversations(
              userHistory.conversations
            );
            this.historyConversations = formattedConversations;

            this.loadingSoulLettersConversations = false;
            this.loadingErrorSoulLettersConversations = false;
          })
          .catch(err => {
            this.loadingSoulLettersConversations = false;
            this.loadingErrorSoulLettersConversations = true;
          });
      }
    },
    setGlobalSoulLetter(soulLetter) {
      this.chosenSoulLetter = soulLetter;
      this.chosenConversation = null;
      //this.historyConversations = [];
    },
    setGlobalConversation(conversation) {
      this.chosenConversation = conversation;
      this.chosenSoulLetter = null;
      //TODO: HIER CHECKEN
      // this.historySoulLetters = [];
    },
    changeActiveSoulLetter(lIdx, letter) {
      this.setGlobalSoulLetter(letter);
      this.rerenderSoulLetter = !this.rerenderSoulLetter;
      this.isSoulLetterClicked = true;
      const currentSoulLetterIdx = parseInt(lIdx, 10);
      this.chosenSoulLetterIdx = currentSoulLetterIdx;
      if (this.lastSoulLetterIdx === null) {
        this.lastSoulLetterIdx = currentSoulLetterIdx;
      }
      if (this.lastSoulLetterIdx !== currentSoulLetterIdx) {
        this.historySoulLetters[currentSoulLetterIdx].isActive = !this
          .historySoulLetters[currentSoulLetterIdx].isActive;
        this.historySoulLetters[this.lastSoulLetterIdx].isActive = false;
        this.lastSoulLetterIdx = currentSoulLetterIdx;
      } else if (this.lastSoulLetterIdx === currentSoulLetterIdx) {
        this.historySoulLetters[currentSoulLetterIdx].isActive = !this
          .historySoulLetters[currentSoulLetterIdx].isActive;
      }
      if (this.isConversationClicked) {
        this.historyConversations[this.lastConversationIdx].isActive = false;
        this.isConversationClicked = false;
      }
    },
    changeActiveConversation(cIdx, conversation) {
      this.setGlobalConversation(conversation);
      this.rerenderConversation = !this.rerenderConversation;
      this.isConversationClicked = true;
      const currentConversationIdx = parseInt(cIdx, 10);
      this.chosenConversationIdx = currentConversationIdx;
      if (this.lastConversationIdx === null) {
        this.lastConversationIdx = currentConversationIdx;
      }
      if (this.lastConversationIdx !== currentConversationIdx) {
        this.historyConversations[currentConversationIdx].isActive = !this
          .historyConversations[currentConversationIdx].isActive;
        this.historyConversations[this.lastConversationIdx].isActive = false;
        this.lastConversationIdx = currentConversationIdx;
      } else if (this.lastConversationIdx === currentConversationIdx) {
        this.historyConversations[currentConversationIdx].isActive = !this
          .historyConversations[currentConversationIdx].isActive;
      }
      if (this.isSoulLetterClicked) {
        this.historySoulLetters[this.lastSoulLetterIdx].isActive = false;
        this.isSoulLetterClicked = false;
      }
    },
    changeActiveOwnUser() {
      this.ownUser.profile.isActive = !this.ownUser.profile.isActive;
    },
    changeActiveUser(uIdx) {
      this.isSoulLetterClicked = false;
      this.isConversationClicked = false;
      this.lastSoulLetterIdx = null;
      this.lastConversationIdx = null;
      const currentUserIdx = parseInt(uIdx, 10);
      if (this.lastUserIdx === null) {
        this.lastUserIdx = currentUserIdx;
      }
      if (this.lastUserIdx !== currentUserIdx) {
        this.allUsers[currentUserIdx].profile.isActive = !this.allUsers[
          currentUserIdx
        ].profile.isActive;
        this.allUsers[this.lastUserIdx].profile.isActive = false;
        this.lastUserIdx = currentUserIdx;
      } else if (this.lastUserIdx === currentUserIdx) {
        this.allUsers[currentUserIdx].profile.isActive = !this.allUsers[
          currentUserIdx
        ].profile.isActive;
      }
    },
    getAllUsers() {
      this.loadingUser = false;
      this.loadingErrorUser = false;
      userApi
        .getAllUsers()
        .then(res => {
          const users = res.data.data;
          this.allUsers = [];
          this.ownUser = null;
          if (
            this.currentUser.role === EUserRoles.COMPANION ||
            this.currentUser.role === EUserRoles.STUDENT
          ) {
            const ownUser = users.find(
              user => user._id === this.currentUser._id
            );
            const ownUserFormatted = {
              _id: ownUser._id,
              profile: {
                username: ownUser.profile.username,
                firstName: ownUser.profile.givenName,
                lastName: ownUser.profile.familyName,
                name: ownUser.profile.name,
                picture: ownUser.profile.picture,
                role: ownUser.role,
                isActive: false
              },
              companionStudentSettings: ownUser.companionStudentSettings
            };
            this.ownUser = ownUserFormatted;
          } else if (this.currentUser.role === EUserRoles.ADMINISTRATOR) {
            const allUsersWithoutAdmin = users.filter(
              user => user.role !== EUserRoles.ADMINISTRATOR
            );
            allUsersWithoutAdmin.forEach(user => {
              this.allUsers.push({
                _id: user._id,
                profile: {
                  username: user.profile.username,
                  firstName: user.profile.givenName,
                  lastName: user.profile.familyName,
                  name: user.profile.name,
                  picture: user.profile.picture,
                  role: user.role,
                  isActive: false
                },
                companionStudentSettings: user.companionStudentSettings
              });
            });
          }
          this.loadingUser = false;
          this.loadingErrorUser = false;
        })
        .catch(err => {
          this.loadingErrorUser = true;
          this.loadingUser = false;
        });
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    this.getAllUsers();
  }
};
</script>

<style scoped></style>
