import Vue from "vue";
import App from "./App.vue";
import router from "./config/routes";
import store from "./store";
import { Auth0Plugin } from "./auth";
import PortalVue from "portal-vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueMeta from "vue-meta";
import i18n from "@/config/i18n";
import config from "@/config/config";
import Notifications from "vue-notification";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import Clipboard from "v-clipboard";
import CountryFlag from "vue-country-flag";
Vue.component("country-flag", CountryFlag);
import titleMixin from "../src/mixins/pageTitle";
Vue.mixin(titleMixin);
import VueObserveVisibility from "vue-observe-visibility";
import VueClipboard from "vue-clipboard2";

import "@/assets/styles/theme.scss";
import "@/assets/sass/assets/main.scss";
import "@/assets/sass/assets/loader.scss";
import "@/assets/sass/plugins/loaders/custom-loader.scss";
import "@/assets/sass/assets/plugins.scss";
import "@/assets/sass/assets/structure.scss";
import "@/assets/sass/assets/scrollspyNav.scss";
import "@/assets/sass/assets/users/user-profile.scss";
import "@/assets/sass/assets/users/account-setting.scss";
import "@/assets/sass/assets/tables/table-basic.scss";
import "@/assets/sass/assets/elements/tooltip.scss";
import "@/assets/sass/assets/elements/alert.scss";
import "@/assets/sass/assets/components/custom-modal.scss";
import "@/assets/sass/assets/notifications/notifications.scss";
import "@/assets/sass/assets/components/custom-list-group.scss";
import "@/assets/sass/assets/components/tabs-accordion/custom-tabs.scss";
import "@/assets/sass/assets/components/tabs-accordion/custom-accordions.scss";
import "@/assets/css/vueTransitions/transitions.scss";

import "@/assets/sass/assets/calendar/calendar.scss";
import "@/assets/sass/assets/calendar/calendarModule.scss";
import "@/assets/sass/assets/calendar/calendarSlotTile.scss";
import "@/assets/sass/assets/calendar/calendarClient.scss";

import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

import "@/assets/plugins/dropify/dropify.min.css";
import "@/assets/css/icons/feathericons.css";
import Vuetify from 'vuetify'

Vue.use(Auth0Plugin, {
  domain: config.auth0.domain,
  clientId: config.auth0.clientId,
  authorizationParams: {
    audience: config.auth0.audience,
  },
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(PortalVue);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMeta);
Vue.use(Notifications);
Vue.use(PerfectScrollbar);
Vue.use(Clipboard);
Vue.use(VueObserveVisibility);
Vue.use(Vuetify);
Vue.use(VueClipboard);

if (config.sentry.enabled) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: process.env.NODE_ENV,
    app: Vue,
    logErrors: true,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    tracesSampleRate: 1
  });
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
