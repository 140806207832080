<template>
  <div class="p-0 m-0">
    <div v-if="loading">
      <div class="d-flex justify-content-between mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg"/>
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-row class="mb-4">
        <b-col>
          <p>
            {{ $t("conversations.descriptionForCalendar") }}
          </p>
        </b-col>
      </b-row>
      <div>
        <!-- SHOW CALENDAR SECTION -->
        <b-row>
          <b-col class="col-md-12">
            <CalendarModuleSettings
              :theCalendars="calendars"
              @calendarAddedOrChanged="reload"
              @showSlotList="
                () => {
                  showSlotList = true;
                }
              "
            />
          </b-col>
        </b-row>
        <div class="cm-hr-line" />
        <div v-if="calendar">
          <CalendarSettings
            :theCalendar="calendar"
            :calendarMode="calendarMode"
            @changedCalendarMode="selectCalendarMode"
            @calendarAddedOrChanged="reload"
          />

          <!-- Redundant If/Else below to force rerender ;) -->
          <Calendar
            v-if="calendarMode === 'week'"
            calendarMode="week"
            :theCalendar="calendar"
            :key="isReloadSlots"
            :withFloatingRangeSelector="true"
          />
          <Calendar
            v-else
            calendarMode="day"
            :key="isReloadSlots"
            :theCalendar="calendar"
            :withFloatingRangeSelector="true"
          />
        </div>
        <div v-else>
          <div v-if="calendars.length === 0" class="cm-no-entities">
            <span>
              {{ $t("calendarModule.errors.noEntities.calendars") }}
            </span>
          </div>
          <div v-else class="cm-no-entities">
            <span>
              {{ $t("calendarModule.errors.selectCalendar") }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as cmApi from "../../store/modules/calendarModule/calendarModule.api";

export default {
  name: "CalendarModule",
  components: {
  //  SlotList: () => import("../../components/calendarModule/common/SlotList"),
    // DefaultSlots: () => import("../../components/calendarModule/DefaultSlots"),
    CalendarModuleSettings: () =>
      import("../../components/calendarModule/CalendarModuleSettings"),
    Calendar: () => import("../../components/calendarModule/Calendar"),
    CalendarSettings: () =>
      import("../../components/calendarModule/CalendarSettings")
    // CalendarDropdown: () =>
    // import("../../components/calendarModule/common/CalendarDropdown")
  },
  data() {
    return {
      isReloadSlots: false,
      cmApi: cmApi,
      loading: false,
      loadingError: false,
      calendars: [],
      calendar: null,
      calendarMode: "week",
      showSlotList: false,
      dummyCalendarData: {
        _id: "be01508b-18b4-4d30-8412-00d08ceec90f",
        requiresPermission: false,
        defaultSlotsDaysBefore: 14,
        defaultSlotsExcludedDates: [],
        name: "Dummy Calendar",
        metadata: {
          color: "#73D8FF",
          aboveAddressText: "Anzeige Infotext Oberhalb",
          addressText: null,
          belowAddressText: "Anzeige Infotext Unterhalb",
          customFieldPermissions: null,
          isInactive: false
        },
        ownerId: "5af2a1f9-0cbe-4158-b53f-ae36b6c4ec7c",
        creationTimeStamp: "2021-06-23 06:13:16.839Z",
        updateTimeStamp: "2021-06-23 06:13:16.839Z",
        _v: 0
      }
    };
  },
  methods: {
    selectCalendar(calendar) {
      this.calendar = calendar;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    selectCalendarMode(calendarMode) {
      this.isReloadSlots = !this.isReloadSlots;
      this.calendarMode = calendarMode;
    },
    reload() {
      this.isReloadSlots = !this.isReloadSlots;

      this.calendars = [];
      this.calendar = null;
      this.calendars.push(this.dummyCalendarData);
      this.calendar = this.calendars[0];
    }
  },
  created() {
    this.reload();
  }
};
</script>

<style scoped></style>
