<template>
  <div id="content" class="main-content">
    <div class="m-4">
      <b-row class="layout-spacing layout-top-spacing" id="cancel-row">
        <div class="col-xl-12 col-sm-12 layout-spacing">
          <div class="statbox widget box box-shadow">
            <div class="widget-header">
              <b-row>
                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                  <h4>{{ $t("newBookings.title") }}</h4>
                </div>
              </b-row>
            </div>
            <div class="widget-content widget-content-area">
              <div v-if="loading">
                <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                  <div class="spinner-border text-success align-self-center loader-lg" />
                </div>
              </div>
              <div v-else-if="loadingError" class="cm-loading-error">
                {{ $t("calendarModule.errors.loadingError") }}
              </div>
              <b-form v-else>
                <b-form-row>
                  <b-col class="col-md-8">
                    <b-button
                      class="btn btn-outline-primary mb-6 mr-2"
                      @mouseover="isHovering = true"
                      @mouseout="isHovering = false"
                      @click="backToDashboard()"
                    >
                      <b-img
                        class="feathericons-arrow-left p-2"
                        :class="{
                          'bg-white': isHovering,
                          'bg-primary': !isHovering
                        }"
                      />
                      {{ $t("newBookings.back") }}
                    </b-button>
                  </b-col>
                  <b-col class="col-md-4">
                    <b-form-group
                      class="text-center mt-n6"
                    >
                      <div class="avatar avatar-xl">
                        <b-img
                          alt="avatar"
                          :src="
                            chosenCompanion
                              ? chosenCompanion.profile.picture
                              : ''
                          "
                          class="rounded-circle max-width-profile-picture"
                        />
                      </div>
                      <p class="mt-3">{{ $t("newBookings.yourCompanion") }}</p>
                      <h6>
                        {{
                          chosenCompanion ? chosenCompanion.profile.name : "N/A"
                        }}
                      </h6>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <!-- Booking Selection -->
                <router-view />
                <!--<p v-else class="font-weight-bold">{{$t("bookingSelection.error.userDoesntExist")}}</p>-->
                <!-- <BookingSelection
                :chosen-companion="chosenCompanion"
                :selected-calendar="dummyCalendarData"
                :key="isReloadBookingSelection"
                @conversationSavedSuccess="conversationSavedSuccess"
                @conversationSavedError="conversationSavedError"
              />-->
              </b-form>
            </div>
          </div>
        </div>
      </b-row>
      <notifications classes="top-right-notification" />
    </div>
  </div>
</template>

<script>
import { addNotification } from "@/utils/notificationHandler";
import * as userApi from "@/store/modules/users/users.api";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "NewBooking",
  title: "Buchungen",
  components: {
    //BookingSelection: () => import("./BookingSelection")
  },
  props: {
    chosenCompanion: Object,
    chosenConversation: Object,
  },
  data() {
    return {
      loading: false,
      loadingError: false,
      isReloadBookingSelection: false,
      isHovering: false,
    };
  },
  computed: {
    ...mapGetters("bookingSelection", ["loadingError"]),
  },
  methods: {
    ...mapMutations("bookingSelection", ["setCompanion", "setLoadingError"]),
    backToDashboard(){
      this.$router.push("dashboard");
    },
    conversationSaved() {
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
    },
    conversationSavedSuccess() {
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
      addNotification(
        "success",
        this.$t("misc.success"),
        this.$t("bookingSelection.success.createConversationSuccess")
      );
    },
    conversationSavedError() {
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
      addNotification(
        "error",
        this.$t("misc.error"),
        this.$t("bookingSelection.error.createConversationError")
      );
    },
    getChosenCompanion(givenUserId) {
      this.loading = true;
      this.loadingError = false;
      userApi
        .getUser(givenUserId)
        .then(res => {
          const user = res.data.data;
          this.chosenCompanion = user;
          this.setCompanion(this.chosenCompanion);
          this.setLoadingError(false);
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.setLoadingError(true);
          this.loading = false;
          this.loadingError = true;
        });
    }
  },
  mounted() {
    const givenUserId = this.$route.params.companionId;
    if (givenUserId) {
      this.getChosenCompanion(givenUserId);
    }
  }
};
</script>

<style scoped></style>
