import axios from "axios";
import config from "@/config/config";
import { getInstance } from "@/auth/index";
import store from '../store/modules/admins/loginAs';



const axiosInstance = axios.create({
  baseURL: config.backendUrl
});

axiosInstance.interceptors.request.use(async config => {
  const authService = getInstance();
  const token = await authService.getTokenSilently();
  config.headers.Authorization = `Bearer ${token}`;
  if(store.state.userId){
    config.headers["x-login-as"] = store.state.userId;
  }
  return config;
});

export default axiosInstance;
