<template>
  <Companions/>
</template>

<script>
import Vue from "vue";
import i18n from "@/config/i18n";

export default Vue.extend({
  name: "Dashboard",
  title: "Dashboard",
  components: {
    Companions: () => import("../conversations/Companions.vue")
  }
});
</script>