<template>
  <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-spacing">
        <!-- Content -->
        <div class="col-xl-4 col-lg-6 col-md-5 col-sm-12 layout-top-spacing">
          <div class="user-profile layout-spacing">
            <div class="widget-content widget-content-area">
              <div class="d-flex justify-content-between">
                <h3 class="">{{ $t("actions.profile") }}</h3>
                <a
                  @click="navigateTo('accountSettings')"
                  class="btn mt-2 edit-profile"
                >
                  <b-img class="feathericons-edit-3 p-3  bg-white" />
                </a>
              </div>
              <div v-if="currentUser" class="text-center user-info">
                <div v-if="currentUser.hasOwnProperty('profile')">
                  <b-img
                    v-if="currentUser.role !== EUserRoles.CLIENT"
                    :src="currentUser.profile.picture"
                    alt="avatar"
                    class="max-width-profile-picture"
                  />
                  <p class="">{{ currentUser.profile.name }}</p>
                </div>
              </div>
              <div class="user-info-list">
                <div v-if="currentUser">
                  <ul class="contacts-block list-unstyled text-center">
                    <li class="contacts-block__item">
                      <b-img class="feathericons-mail p-3 bg-dark" />
                      <span class="ml-2">{{ currentUser.profile.email }}</span>
                    </li>
                    <li
                      v-if="currentUser.profile.hasOwnProperty('phoneNumber')"
                      class="contacts-block__item"
                    >
                      <b-img class="feathericons-phone p-3 bg-dark" />
                      <span class="ml-2">{{
                        currentUser.profile.phoneNumber
                      }}</span>
                    </li>
                  </ul>
                </div>
                <b-row v-if="currentUser.role === EUserRoles.STUDENT || currentUser.role === EUserRoles.COMPANION">
                  <b-col class="mt-5">
                    <h6>{{ $t("profile.myPersonalCalendar") }}</h6>
                    <div class="input-group mb-4">
                      <input
                        type="text"
                        class="form-control"
                        :value="personalCalendarLink"
                        :disabled="true"
                      />
                      <div class="input-group-append">
                        <button
                          @click.prevent="copyLinkToClipboard()"
                          class="btn btn-primary"
                          type="button"
                        >
                          {{ $t("misc.copy") }}
                        </button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <transition name="fade">
                  <b-row v-if="isCopyButtonClicked">
                    <b-col>
                      <div class="mt-2 mb-2">
                        <div
                          class="alert alert-light-success border-0 mb-4"
                          role="alert"
                        >
                          <button
                            @click.prevent="isCopyButtonClicked = false"
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <img
                              class="feathericons-x bg-dark p-3 mt-n1 ml-1"
                            />
                          </button>
                          {{ $t("profile.calendarLikedCopied") }}
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import config from "@/config/config";

export default {
  name: "MyProfile",
  title: "Profil",
  data() {
    return {
      EUserRoles,
      currentUser: null,
      profileImage: this.$auth.user.picture,
      profileFirstName: this.$auth.user.given_name,
      profileLastName: this.$auth.user.family_name,
      profileEmail: this.$auth.user.email,
      profileTelNr: this.$auth.user.phoneNumber,
      isCopyButtonClicked: false
    };
  },
  computed: {
    ...mapGetters("users", ["getCurrentUser"]),
    personalCalendarLink() {
    //  const base = "https://portal.clemenskuby.com/";
      const base = config.frontendUrl;
      const bookingSelectionCompanion = "bookingSelectionCompanion";
      const bookingSelectionCalendar = "bookingSelectionCalendar";
      const ownUserId = this.currentUser._id;
      return (
        base +
        bookingSelectionCompanion +
        "/" +
        ownUserId +
        "/" +
        bookingSelectionCalendar
      );
    }
  },
  methods: {
    ...mapActions("users", ["setCurrentUser"]),
    copyLinkToClipboard() {
      this.$clipboard(this.personalCalendarLink);
      this.isCopyButtonClicked = true;
      setInterval(() => {
        this.isCopyButtonClicked = false;
      }, 5000);
    },
    navigateTo(destination) {
      if (this.$route.path.includes(destination)) {
        this.$router.go();
      } else {
        this.$router.push(destination).catch(err => {
          return;
        });
      }
    }
  },
  mounted() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
  }
};
</script>

<style scoped></style>
