import apiClient from "../../../auth/apiClient";


export const getAllVouchers = () => {
  return apiClient.get(`/vouchers`);
};

export const checkVoucher = (data) => {
  return apiClient.put(`/vouchers/voucher`, data);
};

export const createVoucher = (data) => {
  return apiClient.post(`/vouchers/`,data);
};

export const getVoucher = (voucherId) => {
  return apiClient.get(`/vouchers/${voucherId}`);
};

export const updateVoucher = (voucherId, data) => {
  return apiClient.put(`/vouchers/${voucherId}`, data);
};

export const deleteVoucher = (voucherId) => {
  return apiClient.delete(`/vouchers/${voucherId}`);
};

