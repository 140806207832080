<template>
  <b-col cols="12">
    <div>
      <div v-if="loading">
        <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
          <div
            class="spinner-border text-success align-self-center loader-lg"
          />
        </div>
      </div>
      <div v-else-if="loadingError" class="cm-loading-error">
        {{ $t("calendarModule.errors.loadingError") }}
      </div>
      <div v-else>
        <div class="calendar-wrapper-client">
          <div class="d-flex">
            <div class="flex-fill text-left">
              <b-icon-arrow-left
                @click="changeMonth('pastMonth')"
                font-scale="1.5"
                style="cursor: pointer"
              />
            </div>
            <div class="flex-fill text-center">
              {{ start.format("MMMM .YY") }}
            </div>
            <div class="flex-fill text-right">
              <b-icon-arrow-right
                @click="changeMonth('futureMonth')"
                font-scale="1.5"
                style="cursor: pointer"
              />
            </div>
          </div>
          <table
            class="calendar-table"
            id="calendarBody"
            v-if="selectedCalendar !== null && selectedCalendar !== ''"
          >
            <tr style="font-weight: 700">
              <td class="day">Mo</td>
              <td class="day">Di</td>
              <td class="day">Mi</td>
              <td class="day">Do</td>
              <td class="day">Fr</td>
              <td class="day">Sa</td>
              <td class="day">So</td>
            </tr>
            <tr v-for="(row, index) in calendarDays" :key="index">
              <td
                :class="{ notCalendarDay: !day.isCalendarDay }"
                v-for="day in row"
                :key="day.dayOfMonth"
                @click="openSlots(day.slot), setGlobalDay(day)"
              >
                <!-- {{day}}
                {{ day.dayOfMonth }}-->
                <div
                  class="m-0 w-100 border-radius-75-pixel day pt-1"
                  :class="
                    isSlotCircle(day.slot)
                      ? 'notSlotCircle'
                      : isSlotCircleClicked(day)
                      ? 'slotCircleClicked'
                      : 'slotCircle'
                  "
                >
                  {{ day.dayOfMonth }}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="mt-5 text-center" v-if="isSlotEmpty">
          <h5 class="font-weight-bold font-size-middle">
            {{ $t("bookingSelection.slotIsEmpty") }}
          </h5>
        </div>
        <div v-if="selectedSlots.length > 0 && !isSlotEmpty">
          <div class="d-flex flex-row mt-5">
            <img class="feathericons-clock p-3 mr-1 bg-success" />
            <div class="pl-1">
              <div>
                {{ `${$t("bookingSelection.freeSlotsFor")} `
                }}<strong>{{
                  moment(selectedSlots[0].date, "YYYY-MM-DD").format(
                    "DD.MM.YYYY"
                  )
                }}</strong>
              </div>
              <div>{{ $t("bookingSelection.selectTime") }}</div>
            </div>
          </div>
          <div>
            <b-row class="pt-3">
              <b-col
                class="col-12 col-md-6 col-lg-3"
                v-for="slot in mergedSlots"
                :key="slot._id"
                @click="selectSlot(slot)"
              >
                <CalendarSlotTile
                  :slotObject="slot"
                  :is-selected="isSlotSelected(slot)"
                  :can-be-clicked="true"
                />
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Calendar Modal -->
    <b-modal
      ref="submitBookingModal"
      size="lg"
      @hide="submitBookingModalOpen = false"
      :title="$t('bookingSelection.submitGoMBookingModalTitle')"
      hide-footer
    >
      <b-row>
        <b-col>
          <p>{{ $t("bookingSelection.submitGoMBookingModalDescription") }}</p>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col>
          <p>{{ $t("newBookings.yourStudent") }}:</p>
          <ul class="list-group list-group-media">
            <li class="list-group-item list-group-item-action">
              <div v-if="chosenStudent" class="media">
                <div class="mr-3">
                  <img
                    alt="avatar"
                    :src="chosenStudent.profile.picture"
                    class="img-fluid rounded-circle max-width-profile-picture-small"
                  />
                </div>
                <div class="media-body">
                  <h6 class="tx-inverse">
                    {{ chosenStudent.profile.name }}
                  </h6>
                  <p
                    v-if="chosenStudent.role === EUserRoles.COMPANION"
                    class="mg-b-0"
                  >
                    {{ $t("kingOfTheCastle.companions") }}
                  </p>
                  <p
                    v-else-if="chosenStudent.role === EUserRoles.STUDENT"
                    class="mg-b-0"
                  >
                    {{ $t("kingOfTheCastle.student") }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </b-col>
        <b-col>
          <div v-if="selectedSlot" class="pl-2">
            <p class="mt-3 mb-0">
              {{ `${$t("bookingSelection.yourChosenDate")} ` }}
            </p>
            <strong>{{
              `${moment(selectedSlot.date, "YYYY-MM-DD").format(
                "DD.MM.YYYY"
              )} ${$t("misc.at")} ${selectedSlot.time}`
            }}</strong>
          </div>
          <div v-if="connectedSlot" class="pl-2">
            <p class="mt-3 mb-0">
              {{ `${$t("bookingSelection.yourChosenFollowupDate")} ` }}
            </p>
            <strong>{{
              `${moment(connectedSlot.date, "YYYY-MM-DD").format(
                "DD.MM.YYYY"
              )} ${$t("misc.at")} ${connectedSlot.time}`
            }}</strong>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col>
          <h6>{{ $t("bookingSelection.europeanTime") }}</h6>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col>
          <b-form-checkbox
            id="allowRecordConversation-Checkbox"
            v-model="allowRecordConversation"
          >
            <span>{{ $t("bookingSelection.recordConversation") }}</span>
            <b-img
              id="tooltip-allow-record-conversation"
              class="feathericons-help-circle p-2 ml-1 bg-success"
            />
            <b-tooltip
              target="tooltip-allow-record-conversation"
              triggers="hover"
            >
              <p class="color-white">
                {{ $t("bookingSelection.toolTips.allowRecordAudio") }}
              </p>
              <p class="color-white">
                {{ $t("bookingSelection.toolTips.allowRecordAudio1") }}
              </p>
              <p class="color-white">
                {{ $t("bookingSelection.toolTips.allowRecordAudio2") }}
              </p>
              <p class="color-white">
                {{ $t("bookingSelection.toolTips.allowRecordAudio3") }}
              </p>
            </b-tooltip>
          </b-form-checkbox>
          <b-form-checkbox
            id="allowPrivacyPolicy-Checkbox"
            v-model="allowPrivacyPolicy"
          >
            {{ $t("bookingSelection.privacyPolicy1") }}
            <a href="https://clemenskuby.com/home/agbs/" target="_blank">
              {{ $t("bookingSelection.agb") }}</a
            >
            {{ $t("bookingSelection.privacyPolicy2") }}
            <a href="https://clemenskuby.com/datenschutz/" target="_blank">{{
              $t("bookingSelection.dataPrivacy")
            }}</a>
            {{ $t("bookingSelection.privacyPolicy3") }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col>
          <button
            @click="submitBooking"
            :disabled="!allowPrivacyPolicy || !allowRecordConversation || isBookingLoading"
            class="btn btn-primary"
          >
            {{ $t("misc.bookAppointment") }}
          </button>
          <button
            @click="closeSubmitBookingModal"
            class="btn btn-outline-primary ml-2"
          >
            {{ $t("misc.abort") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
  </b-col>
</template>

<script>
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/de";
import * as cmApi from "../../store/modules/calendarModule/calendarModule.api";
import * as userApi from "../../store/modules/users/users.api";
import * as moment from "moment";
import { addNotification } from "../../utils/notificationHandler";
import {mapActions, mapGetters} from "vuex";
import * as conversationApi from "../../store/modules/conversations/conversations.api";
import { EUserRoles } from "../../store/modules/users/EUserRoles";
import { convertToLocal } from "../../utils/timezoneHelper";
import { EConversationTypes } from "@/store/modules/conversations/EConversationTypes";
import { isNumber } from "lodash";

moment.locale("de");

export default {
  name: "BookingSelectionGoM",
  title: "Buchungsauswahl",
  components: {
    CalendarSlotTile: () =>
      import("../../components/calendarModule/common/CalendarSlotTile")
  },
  props: {
    chosenCompanion: Object,
    selectedCalendar: Object,
    changedSelection: { type: Function, required: false },
    minDate: { type: Object, default: null },
    maxDate: { type: Object, default: null },
    onlyExcluded: { type: Boolean, default: false },
    isExtraCapacity: { type: Boolean, default: false },
    slotFiltersTypes: { type: Array }
  },
  data() {
    return {
      chosenDayGlobal: -1,
      transaction: null,
      isSlotEmpty: false,
      formattedSlots: [],
      EUserRoles,
      duplicateSlots: [],
      reducedSelectedSlots: [],
      chosenStudent: null,
      chosenSlot: null,
      isStudentChosen: false,
      allowRecordConversation: false,
      allowPrivacyPolicy: false,
      charactersCountReason: 0,
      submitBookingComment: "",
      submitBookingModalOpen: false,
      currentUser: null,
      allUsers: [],
      allStudents: [],
      moment: moment,
      tmpSlots: [],
      slots: [],
      start: moment().startOf("month"),
      end: moment().endOf("month"),
      daySelected: false,
      selectedSlots: [],
      selectedSlot: null,
      connectedSlot: null,
      loading: false,
      loadingError: false,
      isBookingLoading: false,
    };
  },
  computed: {
    ...mapGetters("users", ["getCurrentUser"]),
    commentCharacterCount() {
      return this.submitBookingComment.length;
    },
    mergedSlots() {
      return this.selectedSlots.reduce((acc, current) => {
        const x = acc.find(
          item =>
            item._id !== current._id &&
            item.time === current.time &&
            item.endTime === current.endTime &&
            item.date === current.date &&
            item.endDate === current.endDate
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    },
    calendarDays: function() {
      const startCpy = this.start.clone();
      const rows = [];
      let days = [];

      let date = startCpy.clone().add(-(startCpy.clone().day() - 1), "day");
      if (this.start.day() === 0) {
        date = startCpy.clone().add(-6, "day");
      }

      const today = moment();
      while (date.isBefore(this.start)) {
        days.push({
          dayOfMonth: date.date(),
          isCalendarDay: true,
          isToday:
            date.date() === today.date() && date.month() === today.month(),
          slot: this.slots.filter(
            slot => slot.date === date.format("YYYY-MM-DD")
          )
        });
        date.add(1, "day");
      }

      while (startCpy.isBefore(this.end)) {
        days.push({
          dayOfMonth: startCpy.date(),
          isCalendarDay: true,
          isToday:
            startCpy.date() === today.date() &&
            startCpy.month() === today.month(),
          slot: this.slots.filter(
            slot => slot.date === startCpy.format("YYYY-MM-DD") //&&
            // this.selectedCalendar &&
            // slot.calendar === this.selectedCalendar._id
          )
        });

        if (startCpy.day() === 0) {
          rows.push(days);
          days = [];
        }
        startCpy.add(1, "day");
      }

      if (startCpy.day() !== 1 && startCpy.day() !== 0) {
        const countDays = 7 - startCpy.clone().day() + 1;
        for (let i = 0; i < countDays; i++) {
          days.push({
            dayOfMonth: startCpy.date(),
            isCalendarDay: true,
            isToday:
              startCpy.date() === today.date() &&
              startCpy.month() === today.month(),
            slot: this.slots.filter(
              slot => slot.date === startCpy.format("YYYY-MM-DD")
            )
          });
          startCpy.add(1, "day");
        }
      } else if (startCpy.day() === 0) {
        days.push({
          dayOfMonth: startCpy.date(),
          isCalendarDay: true,
          isToday:
            startCpy.date() === today.date() &&
            startCpy.month() === today.month(),
          slot: []
        });
      }

      rows.push(days);
      return rows;
    }
  },
  methods: {
    ...mapActions("users", ["setCurrentUser"]),
    isSlotCircleClicked(day) {
      if (
        day.isCalendarDay &&
        day.dayOfMonth === this.chosenDayGlobal.dayOfMonth &&
        day.slot.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    isSlotCircle(day) {
      let isSlotEmpty = true;
      const slots = day;
      if (slots) {
        if (slots.length) {
          const toDay = moment().format("YYYY-MM-DD");
          if (moment(slots[0].date).diff(moment(toDay)) >= 0) {
            if (moment(slots[0].date).diff(moment(toDay)) === 0) {
              const formattedSlots = this.formatSlotMinBookingHours(slots);
              if (formattedSlots.length === 0) {
                isSlotEmpty = true;
              } else {
                isSlotEmpty = false;
              }
            } else {
              isSlotEmpty = false;
            }
          }
        }
      }
      return isSlotEmpty;
    },
    getConversationTest() {
      conversationApi.getConversation();
    },
    openSubmitBookingModal() {
      const randomSlot = [];
      randomSlot.push(this.selectedSlot);
      if (this.duplicateSlots.length > 0) {
        this.duplicateSlots.forEach(dSlot => {
          if (
            dSlot.time === this.selectedSlot.time &&
            dSlot.student._id !== this.selectedSlot.student._id
          ) {
            randomSlot.push(dSlot);
            this.chosenSlot =
              randomSlot[Math.floor(Math.random() * randomSlot.length)];
            this.chosenStudent = this.chosenSlot.student;
          } else {
            this.chosenSlot = this.selectedSlot;
            this.chosenStudent = this.chosenSlot.student;
          }
        });
      } else if (this.selectedSlots) {
        this.chosenSlot = this.selectedSlot;
        this.chosenStudent = this.chosenSlot.student;
      }

      this.submitBookingModalOpen = true;
      this.$refs["submitBookingModal"].show();
    },
    closeSubmitBookingModal() {
      this.submitBookingModalOpen = false;
      this.chosenSlot = null;
      this.chosenStudent = null;
      this.$refs["submitBookingModal"].hide();
    },
    loadSlots(start, end) {
      this.slots = [];
      //   this.loading = true;
      let fromTime = "00:00";

      if (this.minDate && this.moment(this.minDate).isAfter(start)) {
        start = this.minDate;
      }
      if (this.maxDate && this.moment(this.maxDate).isBefore(end)) {
        end = this.maxDate;
      }
      if (moment().isAfter(start)) {
        start = moment();
        fromTime = moment().format("HH:mm");
      }

      this.loading = true;
      this.loadingError = false;
      cmApi.slot
        .getAllTimeSlots(this.chosenStudent._id)
        .then(res => {
          this.slots = res.data.data.filter(slot => {
            return (
              slot.metadata &&
              slot.metadata.type &&
              slot.metadata.type === EConversationTypes.GOM &&
              slot.metadata.connectedSlot &&
              !slot.metadata.isFollowupSlot
            );
          });
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    selectSlot(slot) {
      this.selectedSlot = slot;
      if (this.selectedSlot.metadata.connectedSlot) {
        cmApi.slot
          .getTimeSlot(slot.student._id, slot.metadata.connectedSlot)
          .then(res => {
            this.connectedSlot = res.data.data;
            this.openSubmitBookingModal();
            setTimeout(() => {
              window.scrollTo({
                top: 500,
                behavior: "smooth"
              });
            }, 50);
          });
      } else {
        this.openSubmitBookingModal();
        setTimeout(() => {
          window.scrollTo({
            top: 500,
            behavior: "smooth"
          });
        }, 50);
      }
    },
    sortSlots(slots) {
      const t = slots.sort();
      return t;
    },
    compare(a, b) {
      if (a.time < b.time) {
        return -1;
      }
      if (a.time > b.time) {
        return 1;
      }
      return 0;
    },
    reduceSlots(slots) {
      slots.sort(this.compare);
      const result = [];
      this.duplicateSlots = [];
      for (let i = 0; i < slots.length; i++) {
        for (let j = i; j < slots.length; j++) {
          if (
            slots[i].time === slots[j].time &&
            slots[i].student._id !== slots[j].student._id
          ) {
            result.push(slots[j]);
          }
        }
      }
      this.duplicateSlots = result;
    },
    formatSlotMinBookingHours(slots) {
      const formattedSlots = [];
      const today = moment();
      const todayTime = moment(today, "HH:mm");
      const todayDate = moment(today, "YYYY-MM-DD");
      let isSlotMinBookingHoursAbove = false;
      let isSlotMinBookingDaysAbove = false;
      slots.forEach(slot => {
        const slotMinBookingHours = 24;
        const todayDate = moment(slot.date).format("YYYY-MM-DD");
        const newDateTime = moment(slot.date + slot.time, "YYYY-MM-DD HH:mm");
        const subtractedDate = moment(newDateTime)
          .subtract(slotMinBookingHours, "hours")
          .format("YYYY-MM-DD");

        const subtractedTime = moment(slot.time, "HH:mm")
          .subtract(slotMinBookingHours, "hours")
          .format("HH:mm");
        //  const subtractedTime = slot.time;

        isSlotMinBookingDaysAbove =
          moment(slot.date, "YYYY-MM-DD").diff(
            moment(todayDate, "YYYY-MM-DD")
          ) >= 0;
        // const test = moment("13:00", "HH:mm").subtract(moment("02:00","HH:mm").format("HH:mm"),"hours").format("HH:mm");

        isSlotMinBookingHoursAbove =
          moment(subtractedTime, "HH:mm") >= moment(todayTime, "HH:mm") &&
          todayDate === subtractedDate;
        // (moment(todayTime, "HH:mm").subtract(moment(subtractedTime, "HH:mm").format("HH:mm"),"hours")) >= todayTime && (todayDate === subtractedDate);

        if (slot.student._id !== this.currentUser._id) {
          //  if (!isSlotMinBookingDaysAbove) {
          if (isSlotMinBookingHoursAbove) {
            formattedSlots.push(slot);
          }
          // } else {
          //   formattedSlots.push(slot);
          //  }
        }
      });
      this.formattedSlots = formattedSlots;
      return formattedSlots;
    },
    setGlobalDay(day) {
      this.chosenDayGlobal = day;
    },
    openSlots(slots) {
      this.selectedSlot = null;
      if (slots.length > 0) {
        const toDay = moment().format("YYYY-MM-DD");
        if (moment(slots[0].date).diff(moment(toDay)) >= 0) {
          if (moment(slots[0].date).diff(moment(toDay)) === 0) {
            const formattedSlots = this.formatSlotMinBookingHours(slots);
            this.reduceSlots(formattedSlots);
            if (formattedSlots.length === 0) {
              this.isSlotEmpty = true;
            } else {
              this.isSlotEmpty = false;
            }
            this.daySelected = true;
            this.selectedSlots = formattedSlots;
          } else {
            this.isSlotEmpty = false;
            this.reduceSlots(slots);
            this.daySelected = true;
            this.selectedSlots = slots;
          }
        } else {
          this.daySelected = false;
          this.selectedSlots = [];
          this.$bvToast.toast(this.$t("bookingSelection.noSlotsAvailable"), {
            title: this.$t("misc.attention"),
            variant: "warning",
            solid: true,
            autoHideDelay: 4000,
            appendToast: true,
            toaster: "b-toaster-top-right"
          });
        }
      } else {
        this.daySelected = false;
        this.selectedSlots = [];
        this.$bvToast.toast(this.$t("bookingSelection.noSlotsAvailable"), {
          title: this.$t("misc.attention"),
          variant: "warning",
          solid: true,
          autoHideDelay: 4000,
          appendToast: true,
          toaster: "b-toaster-top-right"
        });
      }
    },
    isSlotSelected(slot) {
      return this.selectedSlot && slot._id === this.selectedSlot._id;
    },
    changeMonth(type) {
      if (type === "pastMonth") {
        this.start.add(-1, "month").startOf("month");
        this.end.add(-1, "month").endOf("month");
      } else {
        this.start.add(1, "month").startOf("month");
        this.end.add(1, "month").endOf("month");
      }
      if (this.isStudentChosen) {
        this.loadSlots(this.start, this.end);
      } else {
        this.getAllTimeSlots();
      }
    },
    submitBooking() {
      this.isBookingLoading = true;
      if (!this.allowRecordConversation || !this.allowPrivacyPolicy) {
        this.isBookingLoading = false;
        return;
      }

      if (this.chosenSlot.free) {
        let payloadRandomSlot;
        if (this.submitBookingComment !== "") {
          payloadRandomSlot = {
            appointment: this.chosenSlot._id,
            host: this.chosenSlot.student._id,
            subject: this.submitBookingComment,
            type: EConversationTypes.GOM
          };
        } else {
          payloadRandomSlot = {
            appointment: this.chosenSlot._id,
            host: this.chosenSlot.student._id,
            type: EConversationTypes.GOM
          };
        }

        conversationApi
          .createConversation(payloadRandomSlot)
          .then(res => {
            this.setCurrentUser();
            const conversation = res.data.data;
            if (conversation.transaction) {
              this.transaction = conversation.transaction;
              window.open(this.transaction.buyUrl.url);
            }
            this.submitBookingModalOpen = false;
            this.getAllTimeSlots();
            this.selectedSlots = [];
            if (conversation.type === EConversationTypes.GOM) {
              this.$router.push("/conversations");
            } else this.$emit("conversationSavedSuccess");
            this.isBookingLoading = false;
          })
          .catch(err => {
            this.isBookingLoading = false;
            this.$emit("conversationSavedError");
          });
      } else {
        conversationApi
          .joinConversation(this.chosenSlot.metadata.conversation)
          .then(() => {
            this.setCurrentUser();
            this.submitBookingModalOpen = false;
            this.getAllTimeSlots();
            this.selectedSlots = [];
            this.isBookingLoading = false;
            this.$router.push("/conversations");
          })
          .catch(err => {
            this.isBookingLoading = false;
            this.$emit("conversationSavedError");
          });
      }
    },
    showError(message) {
      addNotification("warn", this.$t("misc.attention"), message);
    },
    getAllUsers() {
      this.loading = true;
      this.loadingError = false;
      userApi
        .getAllUsers({ params: { onlyBookable: true } })
        .then(res => {
          const users = res.data.data;
          users.forEach(user => {
            if (user.role === EUserRoles.STUDENT) {
              this.allStudents.push(user);
            }
          });
          this.getAllTimeSlots();
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = true;
          this.loadingError = false;
        });
    },
    getAllTimeSlots() {
      this.slots = [];
      this.loading = true;
      this.loadingError = false;
      this.allStudents.forEach(student => {
        cmApi.slot
          .getAllTimeSlots(student._id)
          .then(res => {
            const timeSlots = res.data.data.filter(slot => {
              return (
                slot.metadata &&
                slot.metadata.type &&
                slot.metadata.type === EConversationTypes.GOM &&
                slot.metadata.connectedSlot &&
                !slot.metadata.isFollowupSlot
              );
            });
            if (timeSlots.length > 0) {
              // Add time slots that are booked, but have not reached their attendee count yet
              const timeSlotsToAdd = [];
              timeSlots.forEach(slot => {
                if (slot.free) return;

                if (
                  isNumber(slot.metadata.attendeeCount) &&
                  isNumber(slot.metadata.maxAttendeeCount) &&
                  slot.metadata.attendeeCount < 3 //TODO: Move to config
                ) {
                  timeSlotsToAdd.push(slot);
                }
              });

              // Add time slots that are not booked yet if they don't collide with

              timeSlots.forEach(slot => {
                if (!slot.free) return;
                const nowMoment = moment(Date.now());
                const timeSlotLocal = convertToLocal(
                  slot.date,
                  slot.endDate,
                  slot.time,
                  slot.endTime
                );
                const timeSlotMoment = moment(
                  `${timeSlotLocal.date}T${timeSlotLocal.time}`
                );

                let hasCollision = false;
                if (
                  nowMoment.isBefore(timeSlotMoment) &&
                  nowMoment
                    .add(7, "days")
                    .endOf("day")
                    .isAfter(timeSlotMoment)
                ) {
                  timeSlotsToAdd.forEach(existingTimeSlot => {
                    if (existingTimeSlot.free || hasCollision) return;
                    const existingTimeSlotLocal = convertToLocal(
                      existingTimeSlot.date,
                      existingTimeSlot.endDate,
                      existingTimeSlot.time,
                      existingTimeSlot.endTime
                    );
                    const existingTimeSlotMoment = moment(
                      `${existingTimeSlotLocal.date}T${existingTimeSlotLocal.time}`
                    );

                    hasCollision =
                      timeSlotMoment.isBefore(existingTimeSlotMoment) &&
                      timeSlotMoment
                        .add(1, "days")
                        .endOf("day")
                        .isAfter(existingTimeSlotMoment);
                    if (!hasCollision) {
                      hasCollision =
                        timeSlotMoment.isAfter(existingTimeSlotMoment) &&
                        timeSlotMoment
                          .subtract(1, "days")
                          .startOf("day")
                          .isBefore(existingTimeSlotMoment);
                    }
                  });
                }

                if (!hasCollision) {
                  timeSlotsToAdd.push(slot);
                }
              });

              timeSlotsToAdd.forEach(slot => {
                const convertedUtc = convertToLocal(
                  slot.date,
                  slot.endDate,
                  slot.time,
                  slot.endTime
                );

                const formattedSlot = {
                  date: convertedUtc.date,
                  endDate: convertedUtc.endDate,
                  time: convertedUtc.time,
                  endTime: convertedUtc.endTime,
                  free: slot.free,
                  _id: slot._id,
                  student: student,
                  metadata: slot.metadata
                };

                this.slots.push(formattedSlot);
              });
            }
          });
      });
      this.loading = false;
      this.loadingError = false;
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    this.getAllUsers();
  }
};
</script>
