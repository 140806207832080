<template>
  <div v-if="conversation.status === 'scheduled'">
    <div v-if="conversation.zoomMeeting">
      <div v-if="isShowZoomMeetingTimeCorrect(conversation)">
        <button
          @click="joinZoomConversation(conversation)"
          class="btn btn-outline-info btn-width-100p ml-auto mr-auto"
          :class="isMobileScreenSize || isTabletScreenSize ? 'w-100':''"
        >
          {{ $t("conversations.tableButtons.joinAppointment") }}
        </button>
      </div>
      <div v-else>
        <button
          @click="openEditAppointmentModal(conversation)"
          class="btn btn-outline-primary btn-width-100p ml-auto mr-auto"
          :class="isMobileScreenSize || isTabletScreenSize ? 'w-100':''"
          v-if="conversation.type === 'one_on_one'"
        >
          {{ $t("conversations.tableButtons.rescheduleAppointment") }}
        </button>
        <button
            @click="openLeaveAppointmentModal(conversation)"
            class="btn btn-outline-danger btn-width-100p ml-auto mr-auto"
            :class="isMobileScreenSize || isTabletScreenSize ? 'w-100':''"
            v-else
        >
          {{ $t("conversations.tableButtons.leaveAppointment") }}
        </button>
      </div>
    </div>
    <div v-else>
      <button
        @click="openEditAppointmentModal(conversation)"
        class="btn btn-outline-primary btn-width-100p ml-auto mr-auto"
        :class="isMobileScreenSize || isTabletScreenSize ? 'w-100':''"
        v-if="conversation.type === 'one_on_one'"
      >
        {{ $t("conversations.tableButtons.rescheduleAppointment") }}
      </button>
      <button
          @click="openLeaveAppointmentModal(conversation)"
          class="btn btn-outline-danger btn-width-100p ml-auto mr-auto"
          :class="isMobileScreenSize || isTabletScreenSize ? 'w-100':''"
          v-else
      >
        {{ $t("conversations.tableButtons.leaveAppointment") }}
      </button>
      <button
          @click="openCancelAppointmentModal(conversation)"
          class="btn btn-outline-danger btn-width-100p ml-auto mr-auto mt-1"
          :class="isMobileScreenSize || isTabletScreenSize ? 'w-100':''"
          :disabled="!conversation.canCancel"
      >
        {{ $t("conversations.tableButtons.cancelAppointment") }}
      </button>
    </div>
  </div>
  <div v-else-if="conversation.status === 'pending'">
    <div v-if="conversation.hasOwnProperty('transaction')">
      <button
        @click.prevent="
          openTransactionMaybePaidModal(conversation.transaction.buyUrl.url)
        "
        class="btn btn-outline-danger btn-width-100p ml-auto mr-auto"
        :class="isMobileScreenSize || isTabletScreenSize ? 'w-100':''"
      >
        {{ $t("conversations.tableButtons.payScheduleFee") }}
      </button>
    </div>
  </div>
  <div v-else-if="conversation.status === 'unpaid'">
    <div v-if="conversation.hasOwnProperty('transaction')">
      <div v-if="conversation.transaction.hasOwnProperty('buyUrl')">
        <button
          @click.prevent="
            openTransactionMaybePaidModal(conversation.transaction.buyUrl.url)
          "
          class="btn btn-outline-danger btn-width-100p ml-auto mr-auto"
          :class="isMobileScreenSize || isTabletScreenSize ? 'w-100':''"
        >
          {{ $t("conversations.tableButtons.payAppointment") }}
        </button>
      </div>
      <div v-else class="color-danger">
        <span>{{ $t("conversations.tableButtons.billingOnDemandInfo") }}</span>
      </div>
    </div>
  </div>
  <div v-else-if="conversation.status === 'completed'">
    <button
      class="btn btn-outline-primary btn-width-100p ml-auto mr-auto"
      :class="isMobileScreenSize || isTabletScreenSize ? 'w-100':''"
      @click.prevent="openConversationDetailsModal(conversation)"
    >
      {{ $t("misc.showAppointment") }}
    </button>
  </div>
  <div v-else>
    <p class="color-danger">
      {{ $t("conversations.bookingFailed") }}
    </p>
  </div>
</template>

<script>
import screenSizeMixin from "@/mixins/screenSize";

export default {
  name: "ButtonsColumn",
  props: {
    conversation: Object,
    emitIsShowZoomMeetingTimeCorrect: Function,
    emitJoinZoomConversation: Function,
    emitOpenEditAppointmentModal: Function,
    emitOpenTransactionMaybePaidModal: Function,
    emitOpenConversationDetailsModal: Function,
    emitOpenLeaveAppointmentModal: Function,
    emitOpenCancelAppointmentModal: Function
  },
  mixins: [screenSizeMixin],
  methods:{
    isShowZoomMeetingTimeCorrect(conversation){
      this.emitIsShowZoomMeetingTimeCorrect(conversation);
    },
    joinZoomConversation(conversation){
      this.emitJoinZoomConversation(conversation);
    },
    openEditAppointmentModal(conversation){
      this.emitOpenEditAppointmentModal(conversation);
    },
    openLeaveAppointmentModal(conversation) {
      this.emitOpenLeaveAppointmentModal(conversation);
    },
    openCancelAppointmentModal(conversation) {
      this.emitOpenCancelAppointmentModal(conversation);
    },
    openTransactionMaybePaidModal(buyUrl){
      this.emitOpenTransactionMaybePaidModal(buyUrl);
    },
    openConversationDetailsModal(conversation){
      this.emitOpenConversationDetailsModal(conversation);
    }
  },
  mounted() {
    console.log("CONVO:");
    console.log(this.conversation);
  }
};
</script>

<style scoped></style>
