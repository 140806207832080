<template>
  <div class="sidebar-wrapper sidebar-theme theme-border-and-shadow">
    <nav id="sidebar">
      <ul
        class="navbar-nav theme-brand flex-row  text-center justify-content-center"
      >
        <li class="nav-item theme-logo">
          <a href="/">
            <b-img
              src="../../assets/img/logo-white.png"
              class="navbar-logo"
              alt="logo"
            />
          </a>
        </li>
        <!-- <li class="nav-item theme-text">
          <a @click="tokenDebugPrint" class="nav-link"> IAMDS </a>
        </li> -->
      </ul>
      <ul v-if="currentUser" class="list-unstyled menu-categories">
        <perfect-scrollbar>
          <li
            v-for="el in sidebarElements"
            :key="`${el.id}`"
            :class="{ active: selectedParentSidebarElement === el.to || $route.path.includes(el.to) || ($route.name === 'companions' && el.to === 'dashboard')}"
            class="menu"
          >
            <a
              @click="navigateTo(el.to)"
              rel="noopener noreferrer"
              class="dropdown-toggle"
              target="_blank"
              v-if="
                el &&
                  el.id !== 'conversations' &&
                  el.id !== 'soulLetters' &&
                  currentUser.role === EUserRoles.ADMINISTRATOR &&
                  !getUserId
              "
            >
              <b-img :class="el.icon" class="p-3 mr-3 bg-dark" />
              <span>{{ el.title }}</span>
            </a>
            <a
              @click="navigateTo(el.to)"
              rel="noopener noreferrer"
              class="dropdown-toggle"
              target="_blank"
              v-else-if="
                el &&
                  el.id !== 'kingOfTheCastle' &&
                  el.id !== 'loginAs' &&
                  el.id !== 'vouchers' &&
                  el.id !== 'unpaidConversations' &&
                  currentUser.role !== EUserRoles.ADMINISTRATOR
              "
            >
              <b-img :class="el.icon" class="p-3 mr-3 bg-dark" />
              <span>{{ el.title }}</span>
            </a>
          </li>
        </perfect-scrollbar>
      </ul>
    </nav>
  </div>
</template>

<script>
import sidebarElements from "./../../config/sidebar";
import { addNotification } from "@/utils/notificationHandler";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import { mapActions, mapGetters } from "vuex";
import screenSizeMixin from "../../mixins/screenSize";

export default {
  name: "Sidebar",
  mixins: [screenSizeMixin],
  props: {},
  components: {},
  data() {
    return {
      currentUser: null,
      EUserRoles: EUserRoles,
      sidebarElements,
      selectedParentSidebarElement: "",
      viewingParentSidebarElement: ""
    };
  },
  computed: {
    ...mapGetters("sideBar",["isSidebarOpen",]),
    ...mapGetters("users",[ "getCurrentUser",]),
    ...mapGetters("soulLetters",["getIsEditSoulLetter",]),
    ...mapGetters("loginAs",["getUserId"])
  },
  methods: {
    ...mapActions("soulLetters",["setIsEditSoulLetter"]),
    ...mapActions("sideBar",["toggleSidebar"]),
    ...mapActions("loginAs",["setUserId"]),
    navigateTo(destination) {
      if (this.$route.path.includes(destination)) {
        if (this.getIsEditSoulLetter) {
          let answer = null;
          answer = window.confirm(
            this.$t("soulLetter.leaveSoulLetterModal.descriptionWithoutSave")
          );
          if (answer) {
            this.$router.go();
            if (this.isMobileScreenSize) {
              this.toggleSidebar();
            }
          }
        } else {
          this.$router.go();
          this.setIsEditSoulLetter(false);
          if (this.isMobileScreenSize) {
            this.toggleSidebar();
          }
        }
      } else {
        this.setIsEditSoulLetter(false);
        this.$router.push(destination).catch(err => {
          return;
        });
        if (this.isMobileScreenSize) {
          this.toggleSidebar();
        }
      }
    },
    selectMenu() {
      const currentParentUrl = this.$route.path
        .split("/")
        .filter(x => x !== "")[1];

      if (currentParentUrl !== undefined || currentParentUrl !== null) {
        this.selectedParentMenu = currentParentUrl.toLowerCase();
      } else {
        this.selectedParentMenu = "dashboards";
      }

      // this.isCurrentMenuHasSubItem();
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        const toParentUrl = to.path.split("/")[1];
        if (toParentUrl !== undefined || toParentUrl !== null) {
          this.selectedParentSidebarElement = toParentUrl.toLowerCase();
        } else {
          this.selectedParentSidebarElement = "dashboard";
        }
        //this.selectMenu();
        // this.toggle();

        window.scrollTo(0, top);
      }
    }
  }
};
</script>

<style scoped></style>
