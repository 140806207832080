import apiClient from "../../../auth/apiClient";

export const getAllUsers = () => {
  return apiClient.get(`/users`);
};

export const getOwnUser = () => {
  return apiClient.get(`/user`);
};

export const getUser = userId => {
  return apiClient.get(`/users/${userId}`);
};

export const getUserHistory = userId => {
  return apiClient.get(`/users/${userId}/history`);
};

export const getUserProfile = userId => {
  return apiClient.get(`/users/${userId}/profile`);
};

export const updateUser = (userId, data) => {
  return apiClient.put(`/users/${userId}`, data);
};

export const setAvatar = (userId, data) => {
  return apiClient.post(`/users/${userId}/avatar`, data);
};

export const updateUserProfile = (userId, data) => {
  return apiClient.put(`/users/${userId}/profile`, data);
};
