<template>
  <div id="content" class="main-content">
    <div class="m-4">
      <div class="row layout-spacing layout-top-spacing" id="cancel-row">
        <div class="col-xl-12 col-sm-12 layout-spacing">
          <div class="statbox widget box box-shadow">
            <div class="widget-header">
              <b-row>
                <div class="col-12">
                  <h4>{{ $t("unpaidConversations.title") }}</h4>
                </div>
              </b-row>
            </div>

            <div class="widget-content widget-content-area">
              <p>{{ $t("unpaidConversations.description") }}</p>

              <div class="table-responsive mt-5">
                <perfect-scrollbar>
                  <table id="style-3" class="table style-3 table-hover">
                    <thead>
                      <tr>
                        <th class="text-center">
                          {{ $t("conversations.status.title") }}
                        </th>
                        <th class="text-center">
                          {{ $t("conversations.date") }}
                        </th>
                        <th class="text-center">
                          {{ $t("conversations.companion") }}
                        </th>
                        <th class="text-center">
                          {{ $t("conversations.client") }}
                        </th>
                        <th class="text-center">
                          {{ $t("unpaidConversations.actions") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(conversation, cIdx) in conversations"
                        :key="cIdx"
                      >
                        <td class="text-center">
                          <b-badge class="bg-danger font-size-2 shadow-none">
                            {{ $t("conversations.status.unpaidClient") }}
                          </b-badge>
                        </td>
                        <td class="text-center">
                          {{ getConversationDateStr(conversation) }}
                        </td>
                        <td class="text-center">
                          {{ conversation.host.profile.name }}
                        </td>
                        <td class="text-center">
                          {{ conversation.attendees[0].profile.name }}
                        </td>
                        <td class="text-center">
                          <button
                            class="btn btn-outline-primary"
                            @click="openShowUserCommentModal(conversation)"
                          >
                            {{ $t("unpaidConversations.doManualBilling") }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </perfect-scrollbar>
              </div>

              <b-modal
                ref="manualBillingModal"
                size="lg"
                :title="$t('modals.manualBillingModal.title')"
                hide-footer
                @hide="closeManualBillingModal()"
                :hide-header-close="true"
              >
                <div v-if="loading">
                  <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                    <div
                      class="spinner-border text-success align-self-center loader-lg"
                    />
                  </div>
                </div>
                <div v-else-if="loadingError" class="cm-loading-error">
                  {{ $t("calendarModule.errors.loadingError") }}
                </div>
                <div v-else>
                  <p>{{ $t("unpaidConversations.individialDescription") }}</p>
                  <table
                    id="style-2"
                    class="table style-3  table-hover mt-5 mb-5"
                    v-if="selectedConversation"
                  >
                    <thead>
                      <tr>
                        <th class="text-center">
                          {{ $t("unpaidConversations.field") }}
                        </th>
                        <th class="text-center">
                          {{ $t("unpaidConversations.value") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">
                          {{ $t("unpaidConversations.firstAndLastName") }}
                        </td>
                        <td class="text-center">
                          {{ selectedConversation.attendees[0].profile.name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          {{ $t("unpaidConversations.emailAddress") }}
                        </td>
                        <td class="text-center">
                          {{ selectedConversation.attendees[0].profile.email }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          {{ $t("unpaidConversations.companion") }}
                        </td>
                        <td class="text-center">
                          {{ selectedConversation.host.profile.name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          {{ $t("unpaidConversations.conversationDate") }}
                        </td>
                        <td class="text-center">
                          {{ getConversationDateStr(selectedConversation) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          {{ $t("unpaidConversations.conversationDuration") }}
                        </td>
                        <td class="text-center">
                          {{ selectedConversation.zoomMeeting.duration }}
                          Minuten
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          {{ $t("unpaidConversations.amountDue") }}
                        </td>
                        <td class="text-center">
                          {{ Number(selectedConversation.transaction.price).toFixed(2) }} €
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">
                          {{ $t("unpaidConversations.buyUrl") }}
                        </td>
                        <td class="text-center">
                          <button
                            v-clipboard:copy="
                              selectedConversation.transaction.buyUrl.url
                            "
                            class="btn btn-outline-secondary"
                          >
                            {{ $t("unpaidConversations.copyToClipboard") }}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-row class="mt-3">
                    <b-col>
                      <b-checkbox v-model="commitConfirmed">
                        {{ $t("unpaidConversations.commitConfirm") }}
                      </b-checkbox>
                    </b-col>
                  </b-row>
                  <b-row class="mt-3">
                    <b-col>
                      <button
                        @click="commitManualBilling"
                        class="btn btn-primary mb-2 mr-2"
                        :disabled="!commitConfirmed"
                      >
                        {{ $t("unpaidConversations.commit") }}
                      </button>
                      <button
                        @click="closeManualBillingModal"
                        class="btn btn-outline-primary mb-2 mr-2"
                      >
                        {{ $t("unpaidConversations.abort") }}
                      </button>
                    </b-col>
                  </b-row>
                </div>
              </b-modal>

              <div
                v-if="conversations.length"
                v-observe-visibility="handleScrolledToBottom"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import * as conversationsApi from "@/store/modules/conversations/conversations.api";
import { convertToLocal } from "@/utils/timezoneHelper";

export default {
  name: "UnpaidConversations",
  title: "Zahlungsverwaltung",
  data: () => {
    return {
      loading: true,
      loadingError: false,
      conversations: [],
      selectedConversation: null,
      commitConfirmed: false,
      pagination: {
        limit: 20,
        offset: 0,
        total: 0
      }
    };
  },
  computed: {},
  methods: {
    getBuyUrl() {
      if (!this.selectedConversation) return;
      this.$clipboard(this.selectedConversation.transaction.buyUrl.url);
    },
    commitManualBilling() {
      if (!this.selectedConversation) return;
      this.loading = true;
      conversationsApi
        .doConversationManualBilling(this.selectedConversation._id)
        .then(() => {
          this.loading = false;
          this.closeManualBillingModal();
          this.reloadConversations();
        })
        .catch(() => {
          this.loading = false;
          this.closeManualBillingModal();
          this.reloadConversations();
        });
    },
    openShowUserCommentModal(conversation) {
      this.loading = true;
      this.selectedConversation = conversation;
      this.commitConfirmed = false;
      this.loading = false;

      this.$refs["manualBillingModal"].show();
    },
    closeManualBillingModal() {
      this.$refs["manualBillingModal"].hide();

      this.selectedConversation = null;
      this.commitConfirmed = false;
      this.loading = false;
    },
    getConversationDateStr(conversation) {
      const date = convertToLocal(
        conversation.appointment.date,
        conversation.appointment.endDate,
        conversation.appointment.time,
        conversation.appointment.endTime
      );
      const dateMoment = moment(`${date.date} ${date.time}`);
      const endDateMoment = moment(`${date.endDate} ${date.endTime}`);

      return `${dateMoment.format("DD.MM.YYYY HH:mm")} - ${endDateMoment.format(
        "HH:mm"
      )}`;
    },
    handleScrolledToBottom(isVisible) {
      if (!isVisible) return;
      if (this.conversations.length >= this.pagination.total) return;

      setTimeout(() => {
        this.loadConversations();
      }, 0);
    },
    reloadConversations() {
      this.loading = true;
      this.pagination = {
        limit: 20,
        offset: 0,
        total: 0
      };
      this.conversations = [];
      this.loadConversations(true);
    },
    loadConversations(isInitialLoad = false) {
      if (!isInitialLoad) {
        this.pagination.offset += this.pagination.limit;
      }

      conversationsApi
        .listUnpaidConversations({
          params: {
            limit: this.pagination.limit,
            offset: this.pagination.offset,
            sortDir: "asc"
          }
        })
        .then(res => {
          this.pagination.total = res.data.pagination.total;
          this.conversations.push(...res.data.data);
          this.loadingError = false;
          this.loading = false;
        })
        .catch(err => {
          this.loadingError = true;
          this.loading = false;
        });
    }
  },
  mounted() {
    this.loadConversations(true);
  }
};
</script>

<style scoped></style>
