<template>
  <div id="content" class="main-content">
    <div class="m-4">
      <div class="row layout-spacing layout-top-spacing" id="cancel-row">
        <div class="col-xl-12 col-sm-12 layout-spacing">
          <div class="statbox widget box box-shadow">
            <div class="widget-header">
              <b-row>
                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                  <h4>{{ $t("soulLetters.altTitle") }}</h4>
                </div>
              </b-row>
            </div>
            <div
              v-if="!isSoulLetterOnly && !isSoulLetterWithoutComp"
              class="widget-content widget-content-area"
            >
              <div v-if="loading">
                <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                  <div
                    class="spinner-border text-success align-self-center loader-lg"
                  />
                </div>
              </div>
              <div v-else-if="loadingError" class="cm-loading-error m-4">
                {{ $t("calendarModule.errors.loadingError") }}
              </div>
              <div v-else>
                <div v-if="currentUser">
                  <p v-if="currentUser.role === EUserRoles.CLIENT" class="mb-4">
                    {{ $t("soulLetters.description") }}
                  </p>
                  <h6
                    v-if="currentUser.role === EUserRoles.CLIENT"
                    class="font-weight-bold mb-4 color-primary"
                  >
                    {{ $t("soulLetters.transactionInfo") }}
                  </h6>
                </div>
                <div class="dt-buttons mb-4">
                  <button
                    @click="openChooseCompanionModal()"
                    class="btn btn-primary mb-4"
                  >
                    {{ $t("soulLetters.createNewSoulLetter") }}
                    <img class="feathericons-plus-circle p-3 ml-1 bg-white" />
                  </button>
                  <button
                    @click="openStatusInformationModal()"
                    class="btn btn-outline-primary mb-4 ml-3"
                  >
                    {{ $t("soulLetters.status.titleInformation") }}
                  </button>
                </div>
                <div class="table-responsive mt-5">
                  <perfect-scrollbar>
                    <SoulLettersTable
                      v-if="currentUser && soulLetters"
                      :current-user="currentUser"
                      :soul-letters="soulLetters"
                      :is-mobile-screen-size="isMobileScreenSize"
                      :is-tablet-screen-size="isTabletScreenSize"
                      :is-modal-mobile-screen-size="isModalMobileScreenSize"
                      :is-sm-desktop-screen-size="isSmDesktopScreenSize"
                      :update-soul-letter="updateSoulLetter"
                    />
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
            <SoulLetter
              v-if="isSoulLetterOnly"
              :current-soul-letter="currentSoulLetter"
              :set-soul-letter-only="unsetSoulLetterOnly"
              :is-history-modal="false"
              :key="isSoulLetterRerender"
              @soulLetterSavedAndSent="soulLetterSavedAndSent()"
            />
            <SoulLetterWithoutRecipient
              v-if="isSoulLetterWithoutComp"
              :current-soul-letter="currentSoulLetter"
              :soul-letter-title="soulLetterTitle"
              @soulLetterSavedAndSent="soulLetterSavedAndSentWithoutCompanion()"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Choose Companion Modal -->
    <b-modal
      :title="$t('soulLetter.chooseCompanionModalTitel')"
      ref="chooseCompanionModal"
      hide-footer
      onclose="closeChooseCompanionModal()"
    >
      <b-form-row class="mb-4">
        <b-form-group class="text-center col-md-12">
          <label>{{ $t("soulLetter.chooseCompanionDescription") }}</label>
        </b-form-group>
      </b-form-row>
      <b-row>
        <div class="col-md-6 col-sm-12 text-center">
          <button
            @click="navigateTo('companions')"
            class="btn btn-primary mb-2 mr-2"
          >
            {{ $t("soulLetter.chooseComp") }}
          </button>
        </div>
        <b-col class="col-md-6 col-sm-12 text-center">
          <button
            @click="openCreateSoulLetterModal()"
            class="btn btn-primary mb-2 mr-2"
          >
            {{ $t("soulLetter.dontChoseComp") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Modal Delete SoulLetter-->
    <b-modal
      :title="$t('soulLetters.deleteSoulLetter')"
      ref="deleteSoulLetterModal"
      hide-footer
      onclose="closeDeleteSoulLetterModal()"
    >
      <b-form-row class="mb-4">
        <b-form-group class="col-md-12">
          <label>{{ $t("soulLetters.deleteSoulLetterDescription") }}</label>
        </b-form-group>
      </b-form-row>
      <button
        @click="submitDeleteSoulLetterModal"
        class="btn btn-success mb-2 mr-2"
      >
        {{ $t("soulLetters.deleteSoulLetter") }}
      </button>
      <button
        @click="closeDeleteSoulLetterModal"
        class="btn btn-outline-danger mb-2 mr-2"
      >
        {{ $t("soulLetter.abort") }}
      </button>
    </b-modal>
    <!-- Modal Create New SoulLetter-->
    <b-modal
      :title="$t('soulLetters.createNewSoulLetter')"
      ref="createSoulLetterModal"
      hide-footer
      onclose="closeCreateSoulLetterModal()"
    >
      <b-row class="mb-4">
        <b-form-group class="col-md-12">
          <label>{{ $t("soulLetter.choseSouLetterTitle") }}</label>
        </b-form-group>
        <b-form-group class="col-md-12">
          <label>{{ $t("soulLetter.project") }}</label>
          <b-input v-model="soulLetterTitle" type="text" class="form-control" />
        </b-form-group>
      </b-row>
      <button
        @click="startNewSoulLetterWithoutRecipient()"
        class="btn btn-success mb-2 mr-2"
      >
        {{ $t("soulLetters.submit") }}
      </button>
      <button
        @click="closeCreateSoulLetterModal"
        class="btn btn-outline-danger mb-2 mr-2"
      >
        {{ $t("soulLetter.abort") }}
      </button>
    </b-modal>
    <!-- Transaction Maybe Paid Modal -->
    <b-modal
      :title="$t('conversations.maybePaidModal.title')"
      ref="transactionMaybePaidModal"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      onclose="closeTransactionMaybePaidModal()"
    >
      <b-row class="mb-4">
        <b-col>
          <h6 class="color-primary">
            {{ $t("conversations.maybePaidModal.paid") }}
          </h6>
          <p class="font-weight-bold">
            {{ $t("conversations.maybePaidModal.descriptionPaid") }}
          </p>
          <h6 class="color-danger">
            {{ $t("conversations.maybePaidModal.notPaid") }}
          </h6>
          <p class="font-weight-bold">
            {{ $t("conversations.maybePaidModal.descriptionNotPaid") }}
          </p>
        </b-col>
      </b-row>
      <button
        @click="updateSoulLetter(souLetterForTransaction)"
        class="btn btn-success mb-2 mr-2"
      >
        {{ $t("misc.pay") }}
      </button>
      <button
        @click="closeTransactionMaybePaidModal()"
        class="btn btn-outline-success mb-2 mr-2"
      >
        {{ $t("misc.close") }}
      </button>
    </b-modal>
    <!-- Status Information Modal -->
    <b-modal
      :title="$t('soulLetters.status.titleInformation')"
      ref="statusInformationModal"
      hide-footer
      size="md"
      onclose="closeStatusInformationModal()"
    >
      <div v-if="currentUser">
        <table
          v-if="currentUser.role === EUserRoles.CLIENT"
          class="table-vertical-align"
        >
          <tbody>
            <tr>
              <td class="p-2">
                <b-badge class="bg-success font-size-2 shadow-none">
                  {{ $t("soulLetters.status.draft") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{ $t("soulLetters.status.draftExplanationClient") }}
                </span>
              </td>
            </tr>
            <tr class="border-top border-grey">
              <td class="p-2">
                <b-badge class="bg-warning shadow-none">
                  {{ $t("soulLetters.status.submitted") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{ $t("soulLetters.status.submittedExplanation") }}
                </span>
              </td>
            </tr>
            <tr class="border-top border-grey">
              <td class="p-2">
                <b-badge class="bg-success shadow-none">
                  {{ $t("soulLetters.status.reviewed") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{ $t("soulLetters.status.reviewedExplanationClient") }}
                </span>
              </td>
            </tr>
            <tr class="border-top border-grey">
              <td class="p-2">
                <b-badge class="bg-success shadow-none">
                  {{ $t("soulLetters.status.inProgress") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{ $t("soulLetters.status.inProgressExplanation") }}
                </span>
              </td>
            </tr>
            <tr class="border-top border-grey">
              <td class="p-2">
                <b-badge class="bg-danger font-size-2 shadow-none">
                  {{ $t("soulLetters.status.notYetPaid") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{ $t("soulLetters.status.notYetPaidExplanation") }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-else class="table-vertical-align">
          <tbody>
            <tr>
              <td class="p-2">
                <b-badge class="bg-success font-size-2 shadow-none">
                  {{ $t("soulLetters.status.draft") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{ $t("soulLetters.status.draftExplanationCompanion") }}
                </span>
              </td>
            </tr>
            <tr class="border-top border-grey">
              <td class="p-2">
                <b-badge class="bg-warning shadow-none">
                  {{ $t("soulLetters.status.delivered") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{ $t("soulLetters.status.deliveredExplanation") }}
                </span>
              </td>
            </tr>
            <tr class="border-top border-grey">
              <td class="p-2">
                <b-badge class="bg-success shadow-none">
                  {{ $t("soulLetters.status.reviewed") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{ $t("soulLetters.status.reviewedExplanationCompanion") }}
                </span>
              </td>
            </tr>
            <tr class="border-top border-grey">
              <td class="p-2">
                <b-badge class="bg-success shadow-none">
                  {{ $t("soulLetters.status.clientWrites") }}
                </b-badge>
              </td>
              <td class="p-2">
                <span>
                  {{ $t("soulLetters.status.clientWritesExplanation") }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button
        @click="closeStatusInformationModal()"
        class="btn btn-outline-success mb-2 mr-2 mt-4"
      >
        {{ $t("misc.close") }}
      </button>
    </b-modal>
    <div v-if="soulLetters">
      <div v-if="soulLetters.length" v-observe-visibility="handleScrolledToBottom"></div>
    </div>
  </div>
</template>

<script>
import * as ESoulLetterStatus from "../../store/modules/soulLetters/ESoulLetterStatus.ts";
import * as soulLettersApi from "@/store/modules/soulLetters/soulLetters.api";
import * as moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import screenSizeMixin from "../../mixins/screenSize";
import { displayUsernameToEmail } from "../../utils/userHelper";
import SoulLettersTable from "@/components/soulLetters/SoulLettersTable";

export default {
  name: "SoulLetters",
  title: "Seelenschreiben",
  mixins: [screenSizeMixin],
  components: {
    SoulLettersTable,
    SoulLetter: () =>
        import("@/views/soulLetters/SoulLetter"),
    SoulLetterWithoutRecipient: () =>
      import("@/views/soulLetters/SoulLetterWithoutRecipient")
  },
  data() {
    return {
      offset: 0,
      limit: 20,
      totalAvailableSoulLetters: 0,
      displayUsernameToEmail,
      souLetterForTransaction: null,
      unpaidInterval: 120000, // 5000 = 5sec / 120000 = 2min
      isUnpaidDisableButton: false,
      isNewSoulLetterStarted: false,
      isSoulLetterRerender: false,
      isSoulLetterWithoutComp: false,
      loading: false,
      loadingError: false,
      EUserRoles,
      ESoulLetterStatus,
      moment,
      currentUser: null,
      allUsers: [],
      allRecipients: [],
      soulLetterTitle: "",
      isSoulLetterOnly: false,
      currentSoulLetter: "",
      soulLetters: [],
      soulLettersFinal: [],
      revisions: [],
      sender: {},
      chosenCompanion: null,
      chosenRecipient: {
        label: "",
        code: ""
      },
      textCharacters: 0
    };
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"]),
    ...mapGetters("soulLetters",["getIsEditSoulLetter"]),
    recipientsOptions() {
      const options = this.allUsers.map(el => {
        if (el._id !== this.currentUser._id) {
          return {
            label: el.givenName + " " + el.familyName,
            code: el._id
          };
        }
      });
      return options;
    }
  },
  methods: {
    ...mapActions("soulLetters",["setIsEditSoulLetter"]),
    handleScrolledToBottom(isVisible, entry){
      if(!isVisible || this.isSoulLetterOnly){
        return;
      }
      if(this.soulLetters.length >= this.totalAvailableSoulLetters || this.isSoulLetterOnly){
        return;
      } else {
        setTimeout(()=>{
          this.getAllSoulLetters(true);
        }, 0);
      }
      //this.offset++;
    },
    closeStatusInformationModal() {
      this.$refs["statusInformationModal"].hide();
    },
    openStatusInformationModal() {
      this.$refs["statusInformationModal"].show();
    },
    startNewSoulLetterWithoutRecipient() {
      this.loading = true;
      this.loadingError = false;
      soulLettersApi
        .createSoulLetter({
          title: this.soulLetterTitle
        })
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          const soulLetter = res.data.data;
          if (soulLetter) {
            this.currentSoulLetter = soulLetter;
            this.isSoulLetterWithoutComp = true;
            this.setIsEditSoulLetter(true);
            this.$refs["createSoulLetterModal"].hide();
            this.$refs["chooseCompanionModal"].hide();
          }
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    startNewSoulLetter() {
      this.loading = true;
      this.loadingError = false;
      soulLettersApi
        .createSoulLetter({
          title: this.soulLetterTitle
        })
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          const soulLetter = res.data.data;
          if (soulLetter) {
            this.currentSoulLetter = soulLetter;
            this.isSoulLetterOnly = true;
            this.setIsEditSoulLetter(true);
            this.$refs["createSoulLetterModal"].hide();
            this.closeChooseCompanionModal();
          }
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    soulLetterSavedAndSentWithoutCompanion() {
      this.isSoulLetterWithoutComp = false;
      if (!this.isSoulLetterWithoutComp) {
        this.chosenCompanion = null;
        this.getAllSoulLetters();
      }
    },
    soulLetterSavedAndSent() {
      this.initUnpaidButtonTimeout();
      this.unsetSoulLetterOnly();
    },
    navigateTo(destination, isTransactionBuyUrl) {
      if (isTransactionBuyUrl) {
        window.open(destination, "_blank").focus();
      }
      if (this.$route.path.includes(destination)) {
        this.$router.go();
      } else {
        this.$router.push(destination).catch(err => {
          return;
        });
      }
    },
    openCreateSoulLetterModal() {
      this.$refs["createSoulLetterModal"].show();
    },
    closeCreateSoulLetterModal() {
      this.allUsers = [];
      this.allRecipients = [];
      this.$refs["createSoulLetterModal"].hide();
    },
    submitDeleteSoulLetterModal() {
      this.deleteSoulLetter();
      this.$refs["deleteSoulLetterModal"].hide();
    },
    openDeleteSoulLetterModal(currentSoulLetter) {
      this.currentSoulLetter = currentSoulLetter;
      this.$refs["deleteSoulLetterModal"].show();
    },
    closeDeleteSoulLetterModal() {
      this.$refs["deleteSoulLetterModal"].hide();
    },
    closeChooseCompanionModal() {
      this.$refs["chooseCompanionModal"].hide();
    },
    openChooseCompanionModal() {
      this.$refs["chooseCompanionModal"].show();
    },
    unsetSoulLetterOnly() {
      this.isSoulLetterOnly = false;
      this.soulLettersFinal = [];
      this.soulLetters = [];
      this.offset = 0;
      this.totalAvailableSoulLetters = 0;
      if (!this.isSoulLetterOnly) {
        this.setIsEditSoulLetter(false);
        this.chosenCompanion = null;
        this.getAllSoulLetters();
      }
    },
    setSoulLetterOnly() {
      this.isSoulLetterOnly = true;
      if (this.isSoulLetterOnly) {
        this.setIsEditSoulLetter(true);
      }
      this.soulLettersFinal = [];
      this.soulLetters = [];
    },
    getAllSoulLetters(isPagination) {
      if(!isPagination){
        this.soulLetters = [];
        this.offset = 0;
      }else{
        this.offset = this.offset + this.limit;
      }
      this.soulLettersFinal = [];
      soulLettersApi
        .getAllSoulLetters({
          params: {
            populateUsers: true,
            limit: this.limit,
            offset: this.offset,
            sortField: "updateTimeStamp",
            sortDir: "desc"
          }
        })
        .then(res => {
          const soulLetters = res.data.data;
          this.totalAvailableSoulLetters = res.data.pagination.total;
          this.soulLetters.push(...(soulLetters)); // = res.data;
          const tempSoulLetters = this.soulLetters.sort(
            (a, b) =>
              parseInt(
                moment(
                  b.latestRevision.updateTimeStamp,
                  "YYYY-MM-DD HH:mm"
                ).format("YYYYMMDDHHmm")
              ) -
              parseInt(
                moment(
                  a.latestRevision.updateTimeStamp,
                  "YYYY-MM-DD HH:mm"
                ).format("YYYYMMDDHHmm")
              )
          );

          this.soulLetters = JSON.parse(JSON.stringify(tempSoulLetters));
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    updateSoulLetter(soulLetter, isUnpaidStatus) {
      if (isUnpaidStatus && !this.isUnpaidButtonTimeout(soulLetter)) {
        this.openTransactionMaybePaidModal(soulLetter);
        return;
      }
      if (!isUnpaidStatus) {
        this.$refs["transactionMaybePaidModal"].hide();
      }
      this.currentSoulLetter = soulLetter;
      if (this.currentSoulLetter.recipient) {
        this.chosenCompanion = soulLetter.recipient;
        this.isSoulLetterOnly = true;
        this.setIsEditSoulLetter(true);
      } else {
        this.isSoulLetterWithoutComp = true;
        this.setIsEditSoulLetter(true);
      }
    },
    deleteSoulLetter() {
      this.loading = true;
      this.loadingError = false;
      soulLettersApi
        .deleteSoulLetter(this.currentSoulLetter._id)
        .then(res => {
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    closeTransactionMaybePaidModal() {
      this.souLetterForTransaction = null;
      this.getAllSoulLetters();
      this.$refs["transactionMaybePaidModal"].hide();
    },
    openTransactionMaybePaidModal(soulLetter) {
      this.souLetterForTransaction = soulLetter;
      this.$refs["transactionMaybePaidModal"].show();
    },
    isUnpaidButtonTimeout(soulLetter) {
      const timeStampDiff = moment(soulLetter.updateTimeStamp).diff(moment());
      if (timeStampDiff > -this.unpaidInterval) {
        // 2min
        return true;
      } else {
        return false;
      }
    },
    initUnpaidButtonTimeout() {
      this.isUnpaidDisableButton = true;
      setTimeout(() => {
        this.isUnpaidDisableButton = false;
        if(!this.isSoulLetterOnly){
          this.getAllSoulLetters();
        }
      }, this.unpaidInterval);
    }
  },
  mounted() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
      this.getAllSoulLetters();
      this.initUnpaidButtonTimeout();
    }
  },
  beforeRouteLeave(to, from, next) {
    let answer = null;
    if (this.isSoulLetterWithoutComp || this.isSoulLetterOnly) {
      answer = window.confirm(
        this.$t("soulLetter.leaveSoulLetterModal.descriptionWithoutSave")
      );
      if (answer) {
        next();
      } else {
        next(false);
        this.setIsEditSoulLetter(true);
      }
    } else {
      next();
    }
  }
};
</script>

<style scoped></style>
