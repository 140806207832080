
import Vue from "vue";

export default Vue.extend({
  name: "EnvironmentBadge",
  props: {
    showProduction: {
      type: Boolean,
      default: false
    },
    showStaging: {
      type: Boolean,
      default: true
    },
    showDevelopment: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    environment() {
      return process.env.NODE_ENV;
    },
    environmentColor() {
      switch (this.environment) {
        case "development":
          return "#eb8f34";
        case "staging":
          return "#347deb";
        default:
          return "#a142f5";
      }
    },
    environmentVisible() {
      switch (this.environment) {
        case "development":
          return this.showDevelopment;
        case "staging":
          return this.showStaging;
        default:
          return this.showProduction;
      }
    }
  },
  methods: {
    tokenDebugPrint() {
      if (process.env.NODE_ENV !== "production") {
        this.$auth.getTokenSilently().then(token => {
          console.log(token);
          console.log("(DEBUG) Token above copied to clipboard!");
          this.$clipboard(token);
        });
      }
    }
  }
});
