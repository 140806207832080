import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authGuard } from "@/auth/authGuard";
import AppLayout from "@/views/AppLayout.vue";
import Dashboard from "@/views/placeholders/Dashboard.vue";
import SoulLetters from "@/views/soulLetters/SoulLetters.vue";
import SoulLetter from "@/views/soulLetters/SoulLetter.vue";
import Conversations from "@/views/conversations/Conversations.vue";
import BookingSelectionCalendar from "@/views/conversations/BookingSelectionCalendar.vue";
import MyProfile from "@/views/profile/MyProfile.vue";
import MyAccountSettings from "@/views/profile/MyAccountSettings.vue";
import Companions from "@/views/conversations/Companions.vue";
import BookingSelectionCompanion from "@/views/conversations/BookingSelectionCompanion.vue";
import KingOfTheCastle from "@/views/kingOfTheCastle/KingOfTheCastle.vue";
import LoginAs from "@/views/admin/LoginAs.vue";
import Vouchers from "@/views/admin/Vouchers.vue";
import UnpaidConversations from "@/views/admin/UnpaidConversations.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        name: "dashboard",
        path: "dashboard",
        component: Dashboard
      },
      {
        name: "soulLetters",
        path: "soulLetters",
        component: SoulLetters
      },
      {
        name: "soulLetter",
        path: "soulLetter",
        component: SoulLetter
      },
      {
        name: "conversations",
        path: "conversations",
        component: Conversations,
      },
      {
        name: "profile",
        path: "profile",
        component: MyProfile
      },
      {
        name: "accountSettings",
        path: "accountSettings",
        component: MyAccountSettings
      },
      {
        name: "companions",
        path: "companions",
        component: Companions
      },
      {
        name: "bookingSelectionCompanion",
        path: "bookingSelectionCompanion/:companionId",
        component: BookingSelectionCompanion,
        props: true,
        children:[
          {
            name: "bookingSelectionCalendar",
            path: "bookingSelectionCalendar",
            component: BookingSelectionCalendar,
            props: true
          },
        ]
      },
      {
        name: "kingOfTheCastle",
        path: "kingOfTheCastle",
        component: KingOfTheCastle,
      },
      {
        name: "loginAs",
        path: "loginAs",
        component: LoginAs,
      },
      {
        name: "vouchers",
        path: "vouchers",
        component: Vouchers,
      },
      {
        name: "unpaidConversations",
        path: "unpaidConversations",
        component: UnpaidConversations
      },
      {
        path: "*",
        redirect: "/companions"
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(authGuard);

export default router;
