export default {
  namespaced: true,
  state: {
    companion: null,
    loadingError: false,
  },
  getters: {
    companion: state => state.companion,
    loadingError: state => state.loadingError
  },
  mutations: {
    setCompanion(state, payload) {
      state.companion = payload;
    },
    setLoadingError(state, payload) {
      state.loadingError = payload;
    }
  },
};
