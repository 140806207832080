export default {
  namespaced: true,
  state: {
    isSidebarOpen: window.innerWidth < 769 ? false : true
  },
  mutations: {
    setSidebarOpen(state, payload) {
      state.isSidebarOpen = payload === true;
    }
  },
  getters: {
    isSidebarOpen(state) {
      return state.isSidebarOpen;
    }
  },
  actions: {
    toggleSidebar({ commit, dispatch, state }, payload) {
      commit("setSidebarOpen", !state.isSidebarOpen);
    }
  }
};
