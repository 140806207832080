export function displayUsernameToEmail(profile) {
  if (profile.name) {
    if (profile.name !== "") {
      return profile.name;
    } else {
      return profile.email;
    }
  } else {
    return profile.email;
  }
}
