<template>
  <div>
    <b-row v-if="historySoulLetters.length" class="text-center">
      <b-col>
        <h5>{{ $t("soulLetter.soulLetters") }}</h5>
      </b-col>
    </b-row>
    <b-row v-if="historySoulLetters.length" class="mt-1 text-center">
      <b-col>
        <p>{{ $t("soulLetter.amount") }}: {{ historySoulLetters.length }}</p>
      </b-col>
    </b-row>
    <b-row v-if="historySoulLetters.length">
      <b-col>
        <div class="table-responsive mt-5">
          <perfect-scrollbar>
            <table
              v-if="!isMobileScreenSize || !isModalMobileScreenSize"
              id="style-3"
              class="table style-3  table-hover"
            >
              <thead>
                <tr>
                  <th>
                    {{ $t("soulLetter.tableSlDate") }}
                  </th>
                  <th>
                    {{ $t("soulLetter.tableSlCompanion") }}
                  </th>
                  <th>
                    {{ $t("soulLetter.tableSlProject") }}
                  </th>
                  <th>
                    {{ $t("soulLetter.tableSlLink") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(letter, lIdx) in historySoulLetters" :key="lIdx">
                  <td>
                    <span
                      >{{
                        moment(letter.latestRevision.createTimeStamp).format(
                          "DD.MM.YYYY"
                        )
                      }}
                      - {{ moment(letter.time).format("HH:mm") }}</span
                    >
                  </td>
                  <td>
                    <span v-if="letter.recipient"
                      >{{ letter.recipient.profile.givenName }}
                      {{ letter.recipient.profile.familyName }}</span
                    >
                  </td>
                  <td>
                    <p
                      class="break-word"
                      :class="
                        isTabletScreenSize && letter.title.length > 70
                          ? 'restrict-paragraph-two-lines'
                          : ''
                      "
                    >
                      {{ letter.title }}
                    </p>
                    <div
                      v-if="isTabletScreenSize && letter.title.length > 70"
                      class="text-center"
                    >
                      <img
                        class="feathericons-plus-circle bg-primary p-3 cursor-pointer"
                        @click.prevent="openShowUserCommentModal(letter.title)"
                      />
                      <span
                        class="color-primary cursor-pointer"
                        @click.prevent="openShowUserCommentModal(letter.title)"
                      >
                        {{ $t("actions.showUserComment") }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span
                      class="btn btn-outline-primary"
                      @click="openSoulLetterHistoryLatestRevisionModal(letter)"
                    >
                      {{ $t("soulLetter.show") }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <table v-else id="style-2" class="table style-3  table-hover">
              <tbody>
                <tr
                  class="tr-border-top-grey"
                  v-for="(letter, lIdx) in historySoulLetters"
                  :key="lIdx"
                >
                  <table style="width: 100%;">
                    <tr>
                      <td
                        class="border-0 font-weight-bold td-table-mobile td-first-col-mobile"
                      >
                        <span>{{ $t("soulLetter.tableSlDate") }}: </span>
                      </td>
                      <td class="border-0 td-table-mobile td-first-col-mobile">
                        <span
                          >{{
                            moment(
                              letter.latestRevision.createTimeStamp
                            ).format("DD.MM.YYYY")
                          }}
                          - {{ moment(letter.time).format("HH:mm") }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="border-0 font-weight-bold td-table-mobile">
                        <span>{{ $t("soulLetter.tableSlCompanion") }}: </span>
                      </td>
                      <td class="border-0 td-table-mobile">
                        <span v-if="letter.recipient"
                          >{{ letter.recipient.profile.givenName }}
                          {{ letter.recipient.profile.familyName }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="border-0 font-weight-bold td-table-mobile">
                        <span>{{ $t("soulLetter.tableSlProject") }}: </span>
                      </td>
                      <td class="border-0 td-table-mobile break-word">
                        <p class="restrict-paragraph-two-lines">
                          {{ letter.title }}
                        </p>
                        <div v-if="letter.title.length > 70">
                          <img
                            class="feathericons-plus-circle bg-primary p-3 cursor-pointer"
                            @click.prevent="
                              openShowUserCommentModal(letter.title)
                            "
                          />
                          <span
                            class="color-primary cursor-pointer"
                            @click.prevent="
                              openShowUserCommentModal(letter.title)
                            "
                          >
                            {{ $t("actions.showUserComment") }}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </table>

                  <div
                    class="text-center ml-auto mr-auto pb-4 pt-4 pl-3 pr-3"
                    style="width: 100%;"
                  >
                    <button
                      class="btn btn-outline-primary btn-width-100p"
                      @click="openSoulLetterHistoryLatestRevisionModal(letter)"
                    >
                      {{ $t("soulLetter.show") }}
                    </button>
                  </div>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
      </b-col>
    </b-row>
    <!-- SoulLetter History Latest Revision Modal -->
    <b-modal
      :title="$t('soulLetter.historyTitle')"
      ref="soulLetterHistoryLatestRevisionModal"
      size="xl"
      hide-footer
      onclose="closeSoulLetterHistoryLatestRevisionModal()"
    >
      <div>
        <SoulLetter
          :current-soul-letter="chosenHistorySoulLetter"
          :is-history-modal="true"
          :current-client="chosenClient"
          @userNotAllowedToEditSoulLetter="
            isChosenCompanionAllowedToSeeSoulLetter = false
          "
          @userAllowedToEditSoulLetter="
            isChosenCompanionAllowedToSeeSoulLetter = true
          "
        />
        <p
          v-if="
            historySoulLetters.length > 0 &&
              !isChosenCompanionAllowedToSeeSoulLetter
          "
          class="ml-4 font-weight-bold"
        >
          {{ $t("soulLetter.allowedToSeeOnlyOwnSoulLetters") }}.
        </p>
      </div>
      <b-row v-if="isChosenCompanionAllowedToSeeSoulLetter" class="mt-3">
        <b-col>
          <button
            @click="closeSoulLetterHistoryLatestRevisionModal"
            class="btn btn-primary mb-2 mr-2"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Show Comment Modal -->
    <b-modal
      ref="showUserCommentModal"
      size="lg"
      :title="$t('modals.showUserCommentModal.title')"
      hide-footer
      @hide="closeShowUserCommentModal()"
    >
      <b-row>
        <b-col>
          <p v-if="userComment" class="break-word">
            {{ userComment }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <button
            class="btn btn-outline-primary"
            @click.prevent="closeShowUserCommentModal()"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import * as moment from "moment";

export default {
  name: "SoulLetterHistoryTable",
  components: {
    SoulLetter: () => import("@/views/soulLetters/SoulLetter")
  },
  props: {
    historySoulLetters: Array,
    chosenClient: Object,
    isMobileScreenSize: Boolean,
    isTabletScreenSize: Boolean,
    isSmDesktopScreenSize: Boolean,
    isModalMobileScreenSize: Boolean
  },
  data() {
    return {
      moment,
      chosenHistorySoulLetter: null,
      isChosenCompanionAllowedToSeeSoulLetter: true,
      userComment: null
    };
  },
  methods: {
    openShowUserCommentModal(userComment) {
      this.userComment = userComment;
      this.$refs["showUserCommentModal"].show();
    },
    closeShowUserCommentModal() {
      this.userComment = null;
      this.$refs["showUserCommentModal"].hide();
    },
    closeSoulLetterHistoryLatestRevisionModal() {
      this.chosenHistorySoulLetter = null;
      this.$refs["soulLetterHistoryLatestRevisionModal"].hide();
    },
    openSoulLetterHistoryLatestRevisionModal(soulLetter) {
      this.chosenHistorySoulLetter = soulLetter;
      this.$refs["soulLetterHistoryLatestRevisionModal"].show();
    }
  }
};
</script>

<style scoped></style>
