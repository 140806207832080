<template>
  <div id="content" class="main-content">
    <div v-if="!isNewBookingStarted && !isNewSoulLetterStarted" class="m-4">
      <b-row class="layout-top-spacing">
        <!-- Normal Companion and Student Booking Selection -->
        <div id="tabsLine" class="col-lg-12 col-12 layout-spacing">
          <div class="statbox widget box box-shadow">
            <div class="widget-content widget-content-area underline-content">
              <div v-if="loading">
                <div
                  class="d-flex justify-content-center text-center mx-5 mt-3 mb-5"
                >
                  <div
                    class="spinner-border text-success align-self-center loader-lg"
                  />
                </div>
              </div>
              <div v-else-if="currentUser">
                <b-tabs content-class="mt-3" v-model="tabIndex">
                  <b-tab
                    v-if="currentUser.role !== EUserRoles.STUDENT"
                    :active="companionIsActive"
                    @click="navigateToCompanions"
                  >
                    <template #title>
                      <img class="feathericons-user bg-dark p-3 " />
                      {{ $t("kingOfTheCastle.companions") }}
                    </template>
                    <!-- Companions -->
                    <b-row class="d-flex">
                      <div
                        v-for="(companion, cIdx) in allCompanions"
                        :key="cIdx"
                        class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
                      >
                        <b-form-group v-if="companion" class="col-12">
                          <div class="text-center user-info">
                            <b-img
                              class="border-radius-50-percent max-width-profile-picture"
                              :src="companion.profile.picture"
                              alt="avatar"
                            />
                            <!-- break-word -->
                            <div
                              class="m-3 mb-5 font-size-big font-weight-bold overflow-hidden"
                            >
                              <span>{{ companion.profile.name }}</span>
                              <p
                                v-if="
                                  companion.companionStudentSettings
                                    .spokenLanguages.length > 0
                                "
                              >
                                <span
                                  v-for="(language, lIdx) in companion
                                    .companionStudentSettings.spokenLanguages"
                                  :key="lIdx"
                                >
                                  <country-flag
                                    v-if="parseInt(lIdx, 10) === 0"
                                    :country="language"
                                    size="normal"
                                    class="mb-n3 mr-1"
                                  />
                                  <country-flag
                                    v-else-if="parseInt(lIdx, 10) > 0"
                                    :country="language"
                                    size="normal"
                                    class="mb-n3 ml-n5"
                                  />
                                </span>
                              </p>
                            </div>
                            <span
                              class="bg-primary p-3 border-radius-5px font-size-middle font-weight-bold"
                            >
                              {{
                                companion.companionStudentSettings.prices
                                  .conversation
                              }}€/Min.
                            </span>
                          </div>
                          <b-row
                            v-if="currentUser"
                            class="flex-column m-3 mt-4"
                          >
                            <button
                              @click="startNewBooking(companion)"
                              :disabled="currentUser._id === companion._id"
                              class="btn btn-success m-1 shadow-none"
                            >
                              <b-img
                                class="feathericons-monitor p-3 bg-white"
                              />
                              <span class="ml-2"
                                >{{ $t("companions.bookConversation") }}
                              </span>
                            </button>
                            <button
                              @click="openCreateSoulLetterModal(companion)"
                              :disabled="currentUser._id === companion._id"
                              class="btn btn-success m-1 shadow-none"
                            >
                              <b-img class="feathericons-edit-2 p-3 bg-white" />
                              <span class="ml-2">{{
                                $t("companions.bookSoulLetter")
                              }}</span>
                            </button>
                            <button
                              @click="openVitaModalCompanion(companion)"
                              class="btn btn-success m-1 shadow-none"
                            >
                              <b-img class="feathericons-user p-3 bg-white" />
                              <span class="ml-2">{{
                                $t("companions.vita")
                              }}</span>
                            </button>
                            <button
                              @click="openVideoIntroductionModal(companion)"
                              class="btn btn-success m-1 shadow-none"
                            >
                              <!-- :disabled="companion.companionStudentSettings.introductionVideo" -->
                              <b-img
                                class="feathericons-youtube p-3 bg-white"
                              />
                              <span class="ml-2"
                                >{{ $t("companions.videoIntroduction") }}
                              </span>
                            </button>
                          </b-row>
                        </b-form-group>
                      </div>
                    </b-row>
                  </b-tab>
                  <b-tab :active="studentIsActive" @click="navigateToStudents">
                    <template #title>
                      <img class="feathericons-user bg-dark p-3 " />
                      {{ $t("kingOfTheCastle.students") }}
                    </template>
                    <b-row class="mb-5">
                      <b-col>
                        <p
                          class="text-center font-weight-bold m-auto"
                          style="max-width: 830px"
                          v-if="!studentTab"
                        >
                          {{ $t("conversations.studentPackageDescription") }}
                        </p>
                        <p
                          class="text-center font-weight-bold m-auto"
                          style="max-width: 830px"
                          v-if="studentTab"
                        >
                          {{ $t("conversations.studentBookingDescription1") }}
                        </p>
                      </b-col>
                    </b-row>
                    <b-row v-if="!clientHasStudent">
                      <BookingSelectionStudent
                        :key="isReloadBookingSelection"
                        @conversationSavedSuccess="conversationSavedSuccess"
                        @conversationSavedError="conversationSavedError"
                      />
                    </b-row>
                    <b-row v-else-if="clientHasStudent">
                      <b-col>
                        <b-form-group
                          v-if="bookedStudentOfClient"
                          class="col-12"
                        >
                          <div class="text-center user-info">
                            <b-img
                              class="border-radius-50-percent max-width-profile-picture"
                              :src="bookedStudentOfClient.profile.picture"
                              alt="avatar"
                            />
                            <p class="m-3 font-size-big font-weight-bold">
                              {{ bookedStudentOfClient.profile.name }}
                            </p>
                          </div>
                          <b-row
                            class="flex-column m-3  ml-auto mr-auto"
                            style="max-width: 500px;"
                          >
                            <button
                              @click="
                                openCreateSoulLetterModal(bookedStudentOfClient)
                              "
                              :disabled="
                                currentUser._id === bookedStudentOfClient._id
                              "
                              class="btn btn-success m-1 shadow-none"
                            >
                              <b-img class="feathericons-edit-2 p-3 bg-white" />
                              <span class="ml-2">{{
                                $t("companions.bookSoulLetter")
                              }}</span>
                            </button>
                            <button
                              @click="
                                openVitaModalStudent(bookedStudentOfClient)
                              "
                              class="btn btn-success m-1 shadow-none"
                            >
                              <b-img class="feathericons-user p-3 bg-white" />
                              <span class="ml-2">{{
                                $t("companions.vita")
                              }}</span>
                            </button>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                  <b-tab
                    v-if="
                      currentUser.role === EUserRoles.CLIENT &&
                        currentUser.gomAvailable
                    "
                    :active="gomIsActive"
                    @click="navigateToGOM"
                  >
                    <!-- TODO: GOM CHECK HERE -->
                    <template #title>
                      <img class="feathericons-users bg-dark p-3 " />
                      {{ $t("kingOfTheCastle.gom") }}
                    </template>

                    <b-row class="mb-5">
                      <b-col>
                        <p
                          class="text-center font-weight-bold m-auto"
                          style="max-width: 830px"
                        >
                          {{ $t("conversations.gomDescription") }}
                        </p>
                      </b-col>
                    </b-row>
                    <b-row class="mb-5">
                      <b-col>
                        <p class="text-center m-auto">
                          {{ $t("conversations.gomDescription2Line1") }}
                        </p>
                        <p class="text-center font-weight-bold m-auto">
                          {{ $t("conversations.gomDescription2Line2") }}
                        </p>
                        <p class="text-center m-auto">
                          {{ $t("conversations.gomDescription2Line3") }}
                        </p>
                        <p class="text-center m-auto">
                          {{ $t("conversations.gomDescription2Line4") }}
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <BookingSelectionGoM
                        :key="isReloadBookingSelection"
                        @conversationSavedSuccess="conversationSavedSuccess"
                        @conversationSavedError="conversationSavedError"
                      />
                    </b-row>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
      </b-row>
      <!-- All Students Section below Student Booking Selection -->
      <b-row v-if="currentUser && !isNewSoulLetterStarted">
        <div
          v-if="
            ((tabIndex === 1 && currentUser.role !== EUserRoles.STUDENT) ||
              ((tabIndex === 0 || tabIndex === 1) &&
                currentUser.role === EUserRoles.STUDENT)) &&
              allStudents.length > 0
          "
          class="vw-100"
        >
          <div class="col-xl-12 col-sm-12 layout-spacing">
            <div class="statbox widget box box-shadow">
              <div class="widget-header">
                <b-row>
                  <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>{{ $t("conversations.allStudents") }}</h4>
                  </div>
                </b-row>
              </div>
              <div class="widget-content widget-content-area underline-content">
                <!-- Students -->
                <b-row class="d-flex mt-5">
                  <div
                    v-for="(student, cIdx) in allStudents"
                    :key="cIdx"
                    class="col-xl-4 col-lg-4 col-md-4 col-sm-6"
                  >
                    <b-form-group v-if="student" class="col-12">
                      <div class="text-center user-info">
                        <b-img
                          class="border-radius-50-percent max-width-profile-picture"
                          :src="student.profile.picture"
                          alt="avatar"
                        />
                        <p class="m-3 font-size-big font-weight-bold">
                          {{ student.profile.name }}
                        </p>
                        <p
                          v-if="
                            student.companionStudentSettings.spokenLanguages
                              .length > 0
                          "
                        >
                          <span
                            v-for="(language, lIdx) in student
                              .companionStudentSettings.spokenLanguages"
                            :key="lIdx"
                          >
                            <country-flag
                              v-if="parseInt(lIdx, 10) === 0"
                              :country="language"
                              size="normal"
                              class="mb-n3"
                            />
                            <country-flag
                              v-else-if="parseInt(lIdx, 10) > 0"
                              :country="language"
                              size="normal"
                              class="mb-n3"
                            />
                          </span>
                        </p>
                      </div>
                      <b-row class="flex-column m-3">
                        <button
                          @click="openVitaModalStudent(student)"
                          class="btn btn-success m-1 shadow-none"
                        >
                          <b-img class="feathericons-user p-3 bg-white" />
                          <span class="ml-2">{{ $t("companions.vita") }}</span>
                        </button>
                      </b-row>
                    </b-form-group>
                  </div>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </div>
    <div v-if="isNewSoulLetterStarted" class="m-4">
      <div class="row layout-spacing layout-top-spacing" id="cancel-row">
        <div class="col-xl-12 col-sm-12 layout-spacing">
          <div class="statbox widget box box-shadow">
            <div class="widget-header">
              <b-row>
                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                  <h4>{{ $t("soulLetters.altTitle") }}</h4>
                </div>
              </b-row>
            </div>
            <SoulLetter
              v-if="isNewSoulLetterStarted"
              :current-soul-letter="currentSoulLetter"
              :is-history-modal="false"
              :is-companion-modal="true"
              @soulLetterSavedAndSent="soulLetterSavedAndSent()"
            />
          </div>
        </div>
      </div>
    </div>
    <BookingSelection
      v-if="isNewBookingStarted"
      :chosen-companion="chosenCompanion"
      :cancel-new-booking="cancelNewBooking"
    />
    <!-- VitaModal -->
    <b-modal
      :title="$t('companions.vita')"
      ref="vitaModal"
      size="lg"
      hide-footer
      onclose="closeVitaModal()"
    >
      <div v-if="chosenStudent">
        <p style="white-space: pre-wrap;">
          {{ chosenStudent.companionStudentSettings.vita }}
        </p>
      </div>
      <div v-else-if="chosenCompanion">
        <p style="white-space: pre-wrap;">
          {{ chosenCompanion.companionStudentSettings.vita }}
        </p>
      </div>
    </b-modal>
    <!-- VideoIntroductionModal -->
    <b-modal
      :title="$t('companions.myVideoIntroduction')"
      ref="videoIntroductionModal"
      size="lg"
      hide-footer
      onclose="closeVideoIntroductionModal()"
    >
      <div v-if="chosenCompanion" class="text-center">
        <iframe
          width="560"
          height="315"
          :src="chosenCompanion.companionStudentSettings.introductionVideo"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
      </div>
    </b-modal>
    <!-- Modal Create New SoulLetter-->
    <b-modal
      :title="$t('soulLetters.createNewSoulLetter')"
      ref="createSoulLetterModal"
      hide-footer
      onclose="closeCreateSoulLetterModal()"
    >
      <b-row class="mb-4">
        <b-form-group class="col-md-12">
          <label>{{
            studentIsActive
              ? $t("soulLetters.createNewSoulLetterDescriptionStudent")
              : $t("soulLetters.createNewSoulLetterDescription")
          }}</label>
        </b-form-group>
        <b-form-group class="col-md-12">
          <label>{{ $t("soulLetter.project") }}</label>
          <b-input v-model="soulLetterTitle" type="text" class="form-control" />
        </b-form-group>
      </b-row>
      <button @click="startNewSoulLetter()" class="btn btn-success mb-2 mr-2">
        {{ $t("soulLetters.submit") }}
      </button>
      <button
        @click="closeCreateSoulLetterModal"
        class="btn btn-outline-danger mb-2 mr-2"
      >
        {{ $t("soulLetter.abort") }}
      </button>
    </b-modal>
    <notifications classes="top-right-notification" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as userApi from "@/store/modules/users/users.api";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import * as soulLettersApi from "@/store/modules/soulLetters/soulLetters.api";
import { addNotification } from "@/utils/notificationHandler";
import BookingSelectionGoM from "@/views/conversations/BookingSelectionGoM";
export default {
  name: "Companions",
  title: "Begleiter",
  components: {
    BookingSelectionGoM,
    SoulLetter: () => import("@/views/soulLetters/SoulLetter"),
    /* NewBooking: () => import("@/views/conversations/NewBooking"),*/
    BookingSelection: () => import("@/views/conversations/BookingSelection2"),
    BookingSelectionStudent: () =>
      import("@/views/conversations/BookingSelectionStudent")
  },
  data() {
    return {
      bookedStudentOfClient: null,
      EUserRoles: EUserRoles,
      loading: false,
      loadingError: false,
      isReloadBookingSelection: false,
      clientHasStudent: false,
      soulLetterTitle: null,
      currentSoulLetter: null,
      tabIndex: 0,
      allUsers: [],
      allCompanions: [],
      allStudents: [],
      chosenCompanion: null,
      chosenStudent: null,
      currentUser: null,
      isHovering: false,
      isNewBookingStarted: false,
      isNewSoulLetterStarted: false,
      studentTab: this.$route.query.tab === "student",
      gomTab: this.$route.query.tab === "gom",
      companionIsActive: true,
      studentIsActive: false,
      gomIsActive: false
    };
  },
  computed: {
    ...mapGetters("users", ["getCurrentUser"])
  },
  methods: {
    ...mapActions("soulLetters", ["setIsEditSoulLetter"]),
    soulLetterSavedAndSent() {
      this.$router.push("soulLetters").catch(err => {
        return;
      });
    },
    conversationSaved() {
      this.getStudentPackageUser();
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
    },
    conversationSavedSuccess() {
      this.getStudentPackageUser();
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
      addNotification(
        "success",
        this.$t("misc.success"),
        this.$t("bookingSelection.success.createConversationSuccess")
      );
    },
    conversationSavedError() {
      this.getStudentPackageUser();
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
      addNotification(
        "error",
        this.$t("misc.error"),
        this.$t("bookingSelection.error.createConversationError")
      );
    },
    openCreateSoulLetterModal(companion) {
      this.chosenCompanion = companion;
      this.$refs["createSoulLetterModal"].show();
    },
    closeCreateSoulLetterModal() {
      this.chosenCompanion = null;
      this.$refs["createSoulLetterModal"].hide();
    },
    openVitaModalCompanion(companion) {
      this.chosenCompanion = companion;
      this.$refs["vitaModal"].show();
    },
    openVitaModalStudent(student) {
      this.chosenStudent = student;
      this.$refs["vitaModal"].show();
    },
    closeVitaModal() {
      this.$refs["vitaModal"].hide();
    },
    closeVideoIntroductionModal() {
      this.$refs["videoIntroductionModal"].hide();
    },
    openVideoIntroductionModal(companion) {
      this.chosenCompanion = companion;
      if (this.chosenCompanion.companionStudentSettings.introductionVideo) {
        this.$refs["videoIntroductionModal"].show();
      }
    },
    navigateToCompanions() {
      this.studentIsActive = false;
      this.gomIsActive = false;
      this.companionIsActive = true;
    },
    navigateToStudents() {
      this.companionIsActive = false;
      this.gomIsActive = false;
      this.studentIsActive = true;
    },
    navigateToGOM() {
      this.companionIsActive = false;
      this.studentIsActive = false;
      this.gomIsActive = true;
    },
    startNewSoulLetter() {
      this.loading = true;
      this.loadingError = false;
      soulLettersApi
        .createSoulLetter({
          recipient: this.chosenCompanion._id,
          title: this.soulLetterTitle
        })
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          const soulLetter = res.data.data;
          if (soulLetter) {
            this.currentSoulLetter = soulLetter;
            this.isNewSoulLetterStarted = true;
            //this.setIsEditSoulLetter(true);
            this.$refs["createSoulLetterModal"].hide();
          }
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    cancelNewSoulLetter() {
      this.isNewSoulLetterStarted = false;
    },
    startNewBooking(companion) {
      this.chosenCompanion = companion;
      this.$router.push({
        name: "bookingSelectionCalendar",
        params: {
          companionId: this.chosenCompanion._id
        }
      });
      // this.isNewBookingStarted = true;
    },
    cancelNewBooking() {
      this.isNewBookingStarted = false;
    },
    getStudentPackageUser() {
      if (this.currentUser.clientSettings) {
        if (this.currentUser.clientSettings.studentPackageUser) {
          userApi
            .getUser(this.currentUser.clientSettings.studentPackageUser)
            .then(res => {
              const user = res.data.data;
              this.bookedStudentOfClient = user;
              this.clientHasStudent = true;
            });
        } else {
          this.clientHasStudent = false;
        }
      } else {
        this.clientHasStudent = false;
      }
    },
    getAllUsers() {
      this.loading = true;
      this.loadingError = false;
      userApi
        .getAllUsers()
        .then(res => {
          const users = res.data.data;
          this.allUsers = users;
          this.allUsers.forEach(user => {
            if (user.role === EUserRoles.COMPANION) {
              this.allCompanions.push(user);
            } else if (user.role === EUserRoles.STUDENT) {
              this.allStudents.push(user);
            }
          });
          this.allCompanions.sort((a, b) => {
            if (a.companionStudentSettings && b.companionStudentSettings) {
              const indexA =
                a.companionStudentSettings?.sortIndex !== undefined
                  ? a.companionStudentSettings?.sortIndex
                  : 99;
              const indexB =
                b.companionStudentSettings?.sortIndex !== undefined
                  ? b.companionStudentSettings?.sortIndex
                  : 99;
              return indexA > indexB;
            }
            return 0;
          });
          this.loading = false;
          this.loadingError = true;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = false;
        });
    }
  },
  mounted() {
    if (this.getCurrentUser) {
      this.loading = true;
      this.currentUser = this.getCurrentUser;
      this.getStudentPackageUser();
      this.loading = false;
    }
    this.getAllUsers();
    if (this.studentTab) {
      this.navigateToStudents();
    }
    if (this.gomTab) {
      this.navigateToGOM();
    }
  }
};
</script>

<style scoped></style>
