<template>
  <div v-if="soulLetter.status === 'draft' && currentUser">
    <span class="shadow-none badge badge-info">
      {{ $t("soulLetters.status.draft") }}
    </span>
  </div>
  <div v-else-if="soulLetter.status === 'unpaid' && currentUser">
    <div
      v-if="
        soulLetter.hasOwnProperty('recipient') &&
          soulLetter.hasOwnProperty('sender')
      "
    >
      <span
        v-if="
          currentUser._id === soulLetter.recipient._id ||
            currentUser.role === EUserRoles.ADMINISTRATOR
        "
        class="shadow-none badge badge-info"
      >
        {{ $t("soulLetters.status.draft") }}
      </span>
      <span
        v-else-if="currentUser._id === soulLetter.sender._id"
        class="shadow-none badge badge-danger"
      >
        {{ $t("soulLetters.status.notYetPaid") }}
      </span>
    </div>
  </div>
  <div v-else-if="soulLetter.status === 'submitted' && currentUser">
    <div
      v-if="
        soulLetter.hasOwnProperty('recipient') &&
          soulLetter.hasOwnProperty('sender')
      "
    >
      <span
        v-if="
          currentUser._id === soulLetter.recipient._id ||
            currentUser.role === EUserRoles.ADMINISTRATOR
        "
        class="shadow-none badge badge-warning"
      >
        {{ $t("soulLetters.status.delivered") }}
      </span>
      <span
        v-else-if="currentUser._id === soulLetter.sender._id"
        class="shadow-none badge badge-warning"
      >
        {{ $t("soulLetters.status.submitted") }}
      </span>
    </div>
  </div>
  <div v-else-if="soulLetter.status === 'reviewed' && currentUser">
    <div
      v-if="
        soulLetter.hasOwnProperty('recipient') &&
          soulLetter.hasOwnProperty('sender')
      "
    >
      <div
        v-if="
          currentUser._id === soulLetter.recipient._id ||
            currentUser.role === EUserRoles.ADMINISTRATOR
        "
      >
        <span
          v-if="
            soulLetter.latestRevision.createTimeStamp ===
              soulLetter.latestRevision.updateTimeStamp
          "
          class="shadow-none badge badge-success"
        >
          {{ $t("soulLetters.status.reviewed") }}
        </span>
        <span
          v-else-if="
            soulLetter.latestRevision.createTimeStamp !==
              soulLetter.latestRevision.updateTimeStamp
          "
          class="shadow-none badge badge-success"
        >
          {{ $t("soulLetters.status.clientWrites") }}
        </span>
      </div>
      <div v-else-if="currentUser._id === soulLetter.sender._id">
        <span
          v-if="
            soulLetter.latestRevision.createTimeStamp ===
              soulLetter.latestRevision.updateTimeStamp
          "
          class="shadow-none badge badge-success"
        >
          {{ $t("soulLetters.status.reviewed") }}
        </span>
        <span
          v-else-if="
            soulLetter.latestRevision.createTimeStamp !==
              soulLetter.latestRevision.updateTimeStamp
          "
          class="shadow-none badge badge-success"
        >
          {{ $t("soulLetters.status.inProgress") }}
        </span>
      </div>
    </div>
  </div>
  <span
    v-else-if="soulLetter.status === 'completed'"
    class="shadow-none badge badge-primary"
  >
    {{ $t("soulLetters.status.completed") }}
  </span>
</template>

<script>
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import * as ESoulLetterStatus from "@/store/modules/soulLetters/ESoulLetterStatus";

export default {
  name: "StatusColumn",
  props:{
    currentUser: Object,
    soulLetter: Object
  },
  data(){
    return{
      EUserRoles,
      ESoulLetterStatus,
    }
  }
};
</script>

<style scoped></style>
