import Vue from "vue";
import VueI18n from "vue-i18n";
import de from "@/config/locales/de.json";
import en from "@/config/locales/en.json";

Vue.use(VueI18n);

const messages = { en: en, de: de };
const locale =
  localStorage.getItem("currentLanguage") &&
  ["en", "de"].includes(localStorage.getItem("currentLanguage"))
    ? localStorage.getItem("currentLanguage")
    : "de";

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "de",
  messages
});

export default i18n;
