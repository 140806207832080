<template>
  <div v-if="soulLetter.status === 'draft' && currentUser">
    <div
      v-if="
        soulLetter.hasOwnProperty('recipient') &&
          soulLetter.hasOwnProperty('sender')
      "
    >
      <button
        v-if="
          currentUser._id === soulLetter.recipient._id ||
            currentUser.role === EUserRoles.ADMINISTRATOR
        "
        @click="updateSoulLetter(soulLetter)"
        class="btn btn-outline-primary btn-width-100p ml-auto mr-auto"
        :class="isTabletScreenSize || isMobileScreenSize ?'w-100':''"
      >
        {{ $t("soulLetters.statusButtons.waitForEntry") }}
      </button>
      <button
        v-else-if="currentUser._id === soulLetter.sender._id"
        @click="updateSoulLetter(soulLetter)"
        class="btn btn-outline-primary btn-width-100p ml-auto mr-auto"
        :class="isTabletScreenSize || isMobileScreenSize ?'w-100':''"
      >
        {{ $t("soulLetters.statusButtons.continueWriting") }}
      </button>
    </div>
    <div v-else>
      <button
        v-if="currentUser._id === soulLetter.sender._id"
        @click="updateSoulLetter(soulLetter)"
        class="btn btn-outline-primary btn-width-100p ml-auto mr-auto"
        :class="isTabletScreenSize || isMobileScreenSize ?'w-100':''"
      >
        {{ $t("soulLetters.statusButtons.continueWriting") }}
      </button>
    </div>
  </div>
  <div v-else-if="soulLetter.status === 'unpaid' && currentUser">
    <div
      v-if="
        soulLetter.hasOwnProperty('recipient') &&
          soulLetter.hasOwnProperty('sender')
      "
    >
      <button
        v-if="
          currentUser._id === soulLetter.recipient._id ||
            currentUser.role === EUserRoles.ADMINISTRATOR
        "
        @click.prevent="updateSoulLetter(soulLetter)"
        class="btn btn-outline-success btn-width-100p ml-auto mr-auto"
        :class="isTabletScreenSize || isMobileScreenSize ?'w-100':''"
      >
        {{ $t("soulLetters.statusButtons.waitForEntry") }}
      </button>
      <div v-else-if="currentUser._id === soulLetter.sender._id">
        <div v-if="soulLetter.latestRevision.hasOwnProperty('transaction')">
          <button
            @click.prevent="updateSoulLetter(soulLetter, true)"
            class="btn btn-outline-danger btn-width-100p ml-auto mr-auto"
            :class="isTabletScreenSize || isMobileScreenSize ?'w-100':''"
            :disabled="isUnpaidButtonTimeout(soulLetter)"
          >
            {{ $t("soulLetters.statusButtons.payOrWriteMore") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="soulLetter.status === 'submitted' && currentUser">
    <div
      v-if="
        soulLetter.hasOwnProperty('recipient') &&
          soulLetter.hasOwnProperty('sender')
      "
    >
      <button
        v-if="
          currentUser._id === soulLetter.recipient._id ||
            currentUser.role === EUserRoles.ADMINISTRATOR
        "
        @click="updateSoulLetter(soulLetter)"
        class="btn btn-outline-warning btn-width-100p ml-auto mr-auto"
        :class="isTabletScreenSize || isMobileScreenSize ?'w-100':''"
      >
        {{ $t("soulLetters.statusButtons.toCorrect") }}
      </button>
      <button
        v-if="currentUser._id === soulLetter.sender._id"
        @click="updateSoulLetter(soulLetter)"
        class="btn btn-outline-warning btn-width-100p ml-auto mr-auto"
        :class="isTabletScreenSize || isMobileScreenSize ?'w-100':''"
      >
        {{ $t("soulLetters.statusButtons.waitForFeedback") }}
      </button>
    </div>
  </div>
  <div v-else-if="soulLetter.status === 'reviewed' && currentUser">
    <div
      v-if="
        soulLetter.hasOwnProperty('recipient') &&
          soulLetter.hasOwnProperty('sender')
      "
    >
      <button
        v-if="
          currentUser._id === soulLetter.recipient._id ||
            currentUser.role === EUserRoles.ADMINISTRATOR
        "
        @click="updateSoulLetter(soulLetter)"
        class="btn btn-outline-success btn-width-100p ml-auto mr-auto"
        :class="isTabletScreenSize || isMobileScreenSize ?'w-100':''"
      >
        {{ $t("soulLetters.statusButtons.waitForFeedback") }}
      </button>
      <button
        v-if="currentUser._id === soulLetter.sender._id"
        @click="updateSoulLetter(soulLetter)"
        class="btn btn-outline-success btn-width-100p ml-auto mr-auto"
        :class="isTabletScreenSize || isMobileScreenSize ?'w-100':''"
      >
        {{ $t("soulLetters.statusButtons.continueWriting") }}
      </button>
    </div>
  </div>
  <div v-else-if="soulLetter.status === 'completed' && currentUser">
    <div
      v-if="
        soulLetter.hasOwnProperty('recipient') &&
          soulLetter.hasOwnProperty('sender')
      "
    >
      <button
        v-if="
          currentUser._id === soulLetter.recipient._id ||
            currentUser.role === EUserRoles.ADMINISTRATOR
        "
        @click="updateSoulLetter(soulLetter)"
        class="btn btn-outline-success btn-width-100p ml-auto mr-auto"
        :class="isTabletScreenSize || isMobileScreenSize ?'w-100':''"
      >
        {{ $t("soulLetters.statusButtons.waitForFeedback") }}
      </button>
      <button
        v-if="currentUser._id === soulLetter.sender._id"
        @click="updateSoulLetter(soulLetter)"
        class="btn btn-outline-success btn-width-100p ml-auto mr-auto"
        :class="isTabletScreenSize || isMobileScreenSize ?'w-100':''"
      >
        {{ $t("soulLetters.statusButtons.continueWriting") }}
      </button>
    </div>
  </div>
</template>

<script>
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import * as ESoulLetterStatus from "@/store/modules/soulLetters/ESoulLetterStatus";
import * as moment from "moment";
import screenSizeMixin from "@/mixins/screenSize";

export default {
  name: "ButtonsColumn",
  props:{
    currentUser: Object,
    soulLetter: Object,
    emitUpdateSoulLetter: Function
  },
  mixins: [screenSizeMixin],
  data(){
    return{
      EUserRoles,
      ESoulLetterStatus,
      unpaidInterval: 120000 // 5000 = 5sec / 120000 = 2min
    }
  },
  methods:{
    updateSoulLetter(soulLetter, isUnpaidStatus) {
      this.emitUpdateSoulLetter(soulLetter, isUnpaidStatus);
    },
    isUnpaidButtonTimeout(soulLetter) {
      const timeStampDiff = moment(soulLetter.updateTimeStamp).diff(moment());
      if (timeStampDiff > -this.unpaidInterval) {
        // 2min
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped></style>
