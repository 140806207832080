import Vue from "vue";
import Vuex from "vuex";

import users from "@/store/modules/users/users";
import soulLetters from "@/store/modules/soulLetters/soulLetters";
import calendar from "@/store/modules/calendarModule/calendar";
import loginAs from "@/store/modules/admins/loginAs";
import bookingSelection from "@/store/modules/bookingSelection/bookingSelection";
import sideBar from "@/store/modules/sideBar/sideBar";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    users,
    soulLetters,
    calendar,
    loginAs,
    bookingSelection,
    sideBar
  }
});
