import apiClient from "../../../../auth/apiClient";

export const getAllRevisions = letter => {
  return apiClient.get(`/letters/${letter}/revisions`);
};

export const getRevision = (letter, revision) => {
  return apiClient.get(`/letters/${letter}/revisions/${revision}`);
};

export const updateRevision = (letter, revision, data) => {
  return apiClient.put(`/letters/${letter}/revisions/${revision}`, data);
};
