import apiClient from "../../../../auth/apiClient";

export const add = payload => {
  return apiClient.post("/private/calendarModule/permissions", payload);
};

export const get = _id => {
  return apiClient.get(`/private/calendarModule/permissions/${_id}`);
};

export const update = (_id, payload) => {
  delete payload._id;
  delete payload.creationTimeStamp;
  delete payload.updateTimeStamp;
  delete payload.__v;
  return apiClient.put(`/private/calendarModule/permissions/${_id}`, payload);
};

export const remove = _id => {
  return apiClient.delete(`/private/calendarModule/permissions/${_id}`);
};
