import { AppConfig } from "@/config/config";

const config: AppConfig = {
  auth0: {
    domain: "account.clemenskuby.com",
    clientId: "CO2taKtRWOq45Dq4EHFZaRdqqvUsrMI9",
    audience: "https://api.clemenskuby.com/"
  },
  sentry: {
    enabled: true,
    dsn: ""
  },
  logoutReturnUrl: "https://clemenskuby.com/",
  backendUrl: "http://localhost:9801/",
  frontendUrl: "http://localhost:9802/",
  calendarModuleTimeZone: "Europe/Berlin",
  gomDuration: 240,
  attendeeCancelPeriod: 86400
};

export default config;