<template>
  <div
    v-if="
      (soulLetter.status === 'draft' || soulLetter.status === 'unpaid') &&
        currentUser
    "
  >
    <span>
      {{
        moment(soulLetter.latestRevision.updateTimeStamp).format("DD.MM.YYYY")
      }}
      -
      {{ moment(soulLetter.latestRevision.updateTimeStamp).format("HH:mm") }}
    </span>
  </div>
  <div v-else-if="soulLetter.status === 'submitted' && currentUser">
    <div
      v-if="
        soulLetter.hasOwnProperty('recipient') &&
          soulLetter.hasOwnProperty('sender')
      "
    >
      <span>
        {{
          moment(soulLetter.latestRevision.createTimeStamp).format("DD.MM.YYYY")
        }}
        -
        {{ moment(soulLetter.latestRevision.createTimeStamp).format("HH:mm") }}
      </span>
    </div>
  </div>
  <div v-else-if="soulLetter.status === 'reviewed' && currentUser">
    <div
      v-if="
        soulLetter.hasOwnProperty('recipient') &&
          soulLetter.hasOwnProperty('sender')
      "
    >
      <span
        v-if="
          soulLetter.latestRevision.createTimeStamp ===
            soulLetter.latestRevision.updateTimeStamp
        "
      >
        {{
          moment(soulLetter.latestRevision.createTimeStamp).format("DD.MM.YYYY")
        }}
        -
        {{ moment(soulLetter.latestRevision.createTimeStamp).format("HH:mm") }}
      </span>
      <span
        v-else-if="
          soulLetter.latestRevision.createTimeStamp !==
            soulLetter.latestRevision.updateTimeStamp
        "
      >
        {{
          moment(soulLetter.latestRevision.updateTimeStamp).format("DD.MM.YYYY")
        }}
        -
        {{ moment(soulLetter.latestRevision.updateTimeStamp).format("HH:mm") }}
      </span>
    </div>
  </div>
  <div v-else-if="soulLetter.status === 'completed'">
    <span>
      {{
        moment(soulLetter.latestRevision.createTimeStamp).format("DD.MM.YYYY")
      }}
      -
      {{ moment(soulLetter.latestRevision.createTimeStamp).format("HH:mm") }}
    </span>
  </div>
</template>

<script>
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import * as ESoulLetterStatus from "@/store/modules/soulLetters/ESoulLetterStatus";
import * as moment from "moment";

export default {
  name: "DateColumn",
  props:{
    currentUser: Object,
    soulLetter: Object
  },
  data(){
    return{
      moment,
      EUserRoles,
      ESoulLetterStatus,
    }
  }
};
</script>

<style scoped></style>
