<template>
  <div class="widget-content widget-content-area">
    <div>
      <b-row
        v-if="
          currentUser &&
            chosenClient &&
            currentSoulLetter &&
            !canCurrentViewerEdit()
        "
        class="mb-5"
      >
        <span class="ml-4 font-weight-bold">{{
          $t("soulLetter.status.currentViewerCantEdit")
        }}</span>
      </b-row>
      <div
        v-if="
          currentUser &&
            chosenClient &&
            currentSoulLetter &&
            !isMobileScreenSize
        "
      >
        <b-row class="mb-1">
          <!-- Project Name -->
          <b-col class="col-5">
            <label
              >{{ $t("soulLetter.project") }} {{ $t("soulLetter.createdAt") }}
              {{
                moment(currentSoulLetter.createTimeStamp).format("DD.MM.YYYY")
              }}</label
            >
            <b-input
              v-model="currentSoulLetter.title"
              type="text"
              class="form-control"
              :disabled="!canCurrentViewerEdit()"
              :debounce="1000"
            />
          </b-col>
          <!-- Revision Dropdown -->
          <b-col v-if="currentUser.role !== EUserRoles.CLIENT" class="col-3">
            <label>{{ $t("soulLetter.revisions") }}</label>
            <v-select
              v-model="revisionOption"
              :options="revisionOptions"
              class="rounded-0"
              :reduce="o => o.code"
              @input="reloadChosenRevision()"
            />
          </b-col>
          <b-col v-else class="col-3" />
          <!-- Sender === Companion -->
          <b-col class="col-4 d-flex flex-row justify-flex-end">
            <div
              v-if="
                currentUser &&
                  chosenCompanion &&
                  chosenClient &&
                  currentSoulLetter
              "
              class="mr-2 mt-2 d-flex flex-column justify-content-center"
            >
              <div
                v-if="
                  currentUser._id === chosenClient._id ||
                    currentUser.role === EUserRoles.ADMINISTRATOR
                "
                class="text-center mt-n6"
              >
                <p class="mt-3">{{ $t("soulLetter.yourCompanion") }}</p>
                <h6>{{ chosenCompanion.name }}</h6>
                <button
                  v-if="currentSoulLetter.status === 'draft'"
                  class="btn btn-outline-primary mt-3"
                  @click.prevent="openChooseCompanionModal()"
                >
                  {{ $t("soulLetter.changeCompanion") }}
                </button>
              </div>
              <div
                v-else-if="currentUser._id === chosenCompanion._id"
                class="text-center mt-n5"
              >
                <p>{{ $t("soulLetter.yourClient") }}</p>
                <h6>{{ chosenClient.name }}</h6>
                <button
                  v-if="
                    !isHistoryModal && chosenClient.role === EUserRoles.CLIENT
                  "
                  @click="openSoulLetterHistoryModal"
                  class="btn btn-outline-primary mt-2"
                >
                  {{ $t("soulLetter.showSoulLetterHistory") }}
                </button>
              </div>
            </div>
            <!-- Profile Image -->
            <div
              v-if="currentUser && chosenCompanion && chosenClient"
              class="ml-2 mt-n5"
            >
              <div
                v-if="
                  currentUser._id === chosenClient._id ||
                    currentUser.role === EUserRoles.ADMINISTRATOR
                "
                class="avatar avatar-xl"
              >
                <b-img
                  alt="avatar"
                  :src="chosenCompanion.profileImage"
                  class="rounded-circle max-width-profile-picture"
                />
              </div>
              <div
                v-else-if="currentUser._id === chosenCompanion._id"
                class="avatar avatar-xl"
              >
                <b-img
                  alt="avatar"
                  :src="chosenClient.profileImage"
                  class="rounded-circle max-width-profile-picture"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="
            currentUser &&
              chosenClient &&
              (!isHistoryModal || currentUser.role === EUserRoles.ADMINISTRATOR)
          "
          class="mb-5 pb-4"
        >
          <b-col
            v-if="
              currentSoulLetter.comment !== '' ||
                currentUser.role !== EUserRoles.CLIENT
            "
            class="col-5"
          >
            <label>{{ $t("soulLetter.soulLetterComment") }}</label>
            <quill-editor
              class="editor quill-comment-section mb-4"
              :class="
                currentUser._id === chosenClient._id ||
                isHistoryModal ||
                !canCurrentViewerEdit()
                  ? 'quill-disabled'
                  : ''
              "
              :value="soulLetterComment"
              @change="
                inputEvent => quillDebounce('soulLetterComment', inputEvent)
              "
              ref="revisionSubjectQuillEditor"
              :options="editorOption"
              :disabled="
                currentUser._id === chosenClient._id || !canCurrentViewerEdit()
              "
            />
          </b-col>
        </b-row>
      </div>
      <div v-else-if="currentUser && chosenClient && currentSoulLetter">
        <b-row class="mb-1">
          <b-col class="col-2">
            <!-- Profile Image -->
            <div v-if="currentUser && chosenCompanion && chosenClient">
              <div
                v-if="
                  currentUser._id === chosenClient._id ||
                    currentUser.role === EUserRoles.ADMINISTRATOR
                "
                class="avatar avatar-xl"
              >
                <b-img
                  alt="avatar"
                  :src="chosenCompanion.profileImage"
                  class="rounded-circle max-width-profile-picture-small"
                />
              </div>
              <div
                v-else-if="currentUser._id === chosenCompanion._id"
                class="avatar avatar-xl"
              >
                <b-img
                  alt="avatar"
                  :src="chosenClient.profileImage"
                  class="rounded-circle max-width-profile-picture-small"
                />
              </div>
            </div>
          </b-col>
          <b-col class="col-9 ml-4">
            <div
              v-if="
                currentUser &&
                  chosenCompanion &&
                  chosenClient &&
                  currentSoulLetter
              "
            >
              <div
                v-if="
                  currentUser._id === chosenClient._id ||
                    currentUser.role === EUserRoles.ADMINISTRATOR
                "
              >
                <p class="mt-3">{{ $t("soulLetter.yourCompanion") }}</p>
                <h6>{{ chosenCompanion.name }}</h6>
                <button
                  v-if="currentSoulLetter.status === 'draft'"
                  class="btn btn-outline-primary mt-3"
                  @click.prevent="openChooseCompanionModal()"
                >
                  {{ $t("soulLetter.changeCompanion") }}
                </button>
              </div>
              <div v-else-if="currentUser._id === chosenCompanion._id">
                <p>{{ $t("soulLetter.yourClient") }}</p>
                <h6>{{ chosenClient.name }}</h6>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="!isHistoryModal && chosenClient.role === EUserRoles.CLIENT"
        >
          <b-col>
            <button
              @click="openSoulLetterHistoryModal"
              class="btn btn-outline-primary mt-2 w-100"
            >
              {{ $t("soulLetter.showSoulLetterHistory") }}
            </button>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <!-- Project Name -->
          <b-col class="col-12">
            <label
              >{{ $t("soulLetter.project") }} {{ $t("soulLetter.createdAt") }}
              {{
                moment(currentSoulLetter.createTimeStamp).format("DD.MM.YYYY")
              }}</label
            >
            <b-input
              v-model="currentSoulLetter.title"
              type="text"
              class="form-control"
              :disabled="!canCurrentViewerEdit()"
              :debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <!-- Revision Dropdown -->
          <b-col v-if="currentUser.role !== EUserRoles.CLIENT" class="col-12">
            <label>{{ $t("soulLetter.revisions") }}</label>
            <v-select
              v-model="revisionOption"
              :options="revisionOptions"
              class="rounded-0"
              :reduce="o => o.code"
              @input="reloadChosenRevision()"
            />
          </b-col>
        </b-row>
        <b-row
          v-if="
            currentUser &&
              chosenClient &&
              (!isHistoryModal || currentUser.role === EUserRoles.ADMINISTRATOR)
          "
          class="mt-4"
        >
          <b-col
            v-if="
              currentSoulLetter.comment !== '' ||
                currentUser.role !== EUserRoles.CLIENT
            "
            class="col-12"
          >
            <label>{{ $t("soulLetter.soulLetterComment") }}</label>
            <quill-editor
              class="editor quill-comment-section mb-4"
              :class="
                currentUser._id === chosenClient._id ||
                isHistoryModal ||
                !canCurrentViewerEdit()
                  ? 'quill-disabled'
                  : ''
              "
              :value="soulLetterComment"
              @change="
                inputEvent => quillDebounce('soulLetterComment', inputEvent)
              "
              ref="revisionSubjectQuillEditor"
              :options="editorOption"
              :disabled="
                currentUser._id === chosenClient._id || !canCurrentViewerEdit()
              "
            />
          </b-col>
        </b-row>
      </div>
      <b-form v-if="!isMobileScreenSize">
        <div v-if="loadingMainContent">
          <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
            <div
              class="spinner-border text-success align-self-center loader-lg"
            />
          </div>
        </div>
        <div v-else-if="loadingErrorMainContent" class="cm-loading-error m-4">
          {{ $t("calendarModule.errors.loadingError") }}
        </div>
        <div v-else>
          <b-form-row>
            <b-form-group class="col-md-1" />
            <b-form-group class="col-md-2" />
            <b-form-group class="col-md-6" />
            <b-form-group class="col-md-3">
              <label>{{ $t("soulLetter.comment") }}</label>
            </b-form-group>
          </b-form-row>
          <b-form-row class="mb-4">
            <b-form-group class="col-md-1">
              <label>{{ $t("soulLetter.reason") }}</label>
              <b-img
                id="tooltip-reason"
                class="feathericons-help-circle p-2 ml-1 bg-success"
              />
              <b-tooltip target="tooltip-reason" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.reasonDescription") }}</span>
              </b-tooltip>
            </b-form-group>
            <b-form-group class="col-md-2">
              <b-input
                type="text"
                class="form-control"
                :placeholder="$t('soulLetter.reason')"
                disabled
                :debounce="1000"
              />
            </b-form-group>
            <b-form-group class="col-md-6">
              <quill-editor
                class="editor"
                :class="
                  isHistoryModal || !canCurrentViewerEdit()
                    ? 'quill-disabled'
                    : ''
                "
                :value="$v.revisionSubjectText.$model"
                @change="
                  inputEvent =>
                    quillDebounce('revisionSubjectText', inputEvent, -1)
                "
                ref="revisionSubjectQuillEditor"
                :key="isRerenderList"
                :options="editorOption"
                :disabled="
                  isHistoryModal || !canCurrentViewerEdit() || quillDisabled
                "
                @focus="setFocus($event)"
              />
              <p
                :class="
                  isHistoryModal || !canCurrentViewerEdit()
                    ? 'quill-disabled'
                    : ''
                "
                class="text-center border-soul-letters-characters"
              >
                {{ revisionSubjectTextTotalLineCount.length }} / {{ maxChars }}
                {{ $t("soulLetter.characters") }}
                <!-- {{ charactersCountReason }} {{ $t("soulLetter.characters") }}-->
              </p>
              <span
                class="error"
                v-if="!$v.revisionSubjectTextCharsCount.maxLength"
              >
                {{ $t("soulLetter.error.charactersToMany") }}
              </span>
              <div
                v-if="
                  currentUser &&
                    currentSoulLetter &&
                    revisionOption &&
                    revisions &&
                    revisions.length > 0
                "
              >
                <div
                  v-if="
                    currentSoulLetter.recipient &&
                      revisionOption.code !== revisions[0]._id
                  "
                >
                  <div
                    class="border-soul-letters-differences"
                    v-if="currentUser._id === currentSoulLetter.recipient._id"
                    v-html="subjectDifferencesHtml[0]"
                  />
                </div>
              </div>
            </b-form-group>
            <b-form-group
              v-if="currentUser && chosenClient && revisions.length > 0"
              class="col-md-3"
            >
              <quill-editor
                class="editor quill-comment-section"
                :class="
                  currentUser._id === chosenClient._id ||
                  isHistoryModal ||
                  !canCurrentViewerEdit()
                    ? 'quill-disabled'
                    : ''
                "
                :value="revisions[revisions.length - 1].subject.comment"
                @change="
                  inputEvent =>
                    quillDebounce('revisionSubjectComment', inputEvent)
                "
                ref="linesQuillEditorSubjectComment"
                :options="editorOption"
                :disabled="
                  currentUser._id === chosenClient._id ||
                    isHistoryModal ||
                    !canCurrentViewerEdit()
                "
              />
              <!--<b-textarea
                v-if="currentUser._id === chosenClient._id"
                v-model="revisions[revisions.length - 1].subject.comment"
                class="form-control"
                rows="3"
                :disabled="currentUser._id === chosenClient._id"
              ></b-textarea>-->
            </b-form-group>
          </b-form-row>
          <!-- HIER SPALTENÜBERSCHRIFTEN -->
          <b-form-row class="mb-4">
            <b-form-group class="col-md-1">
              <label>{{ $t("soulLetter.row") }}</label>
              <b-img
                id="tooltip-row"
                class="feathericons-help-circle p-2 ml-1 bg-success"
              />
              <b-tooltip target="tooltip-row" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.row") }}</span>
              </b-tooltip>
            </b-form-group>
            <b-form-group class="col-md-2">
              <label>{{ $t("soulLetter.person") }}</label>
              <b-img
                id="tooltip-person"
                class="feathericons-help-circle p-2 ml-1 bg-success"
              />
              <b-tooltip target="tooltip-person" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.person") }}</span>
              </b-tooltip>
            </b-form-group>
            <b-form-group class="col-md-6">
              <label>{{ $t("soulLetter.scene") }}</label>
              <b-img
                id="tooltip-scene"
                class="feathericons-help-circle p-2 ml-1 bg-success"
              />
              <b-tooltip target="tooltip-scene" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.scene") }}</span>
              </b-tooltip>
            </b-form-group>
            <b-form-group class="col-md-3"> </b-form-group>
          </b-form-row>
          <!-- HIER BEGINN DER DYNAMISCHEN ZEILEN -->
          <div
            v-for="(line, lineIdx) in $v.lineList.$each.$iter"
            :key="lineIdx"
            :class="{ active: parseInt(lineIdx, 10) === activeItem }"
          >
            <b-form-row class="mb-4 mt-n5">
              <b-form-group class="col-md-1">
                <p :id="'line' + lineIdx">{{ lineIdx }}</p>
                <b-img
                  v-if="!isHistoryModal && canCurrentViewerEdit()"
                  ref="soulLetterDeleteRow"
                  class="feathericons-trash p-2 bg-danger btn"
                  @click="openDeleteRowModal(lineIdx)"
                />
              </b-form-group>
              <b-form-group class="col-md-2">
                <b-input
                  type="text"
                  class="form-control"
                  :disabled="isHistoryModal || !canCurrentViewerEdit()"
                  v-model="line.actor.$model"
                  :debounce="1000"
                />
              </b-form-group>
              <b-form-group class="col-md-6">
                <quill-editor
                  class="editor"
                  :class="
                    isHistoryModal || !canCurrentViewerEdit()
                      ? 'quill-disabled'
                      : ''
                  "
                  :value="line.text.$model"
                  :key="isRerenderList"
                  ref="linesQuillEditorLineText"
                  :options="editorOption"
                  :disabled="
                    isHistoryModal || !canCurrentViewerEdit() || quillDisabled
                  "
                  @change="
                    inputEvent => quillDebounce('line', inputEvent, lineIdx)
                  "
                  @focus="setFocus($event)"
                />
                <p
                  :class="
                    isHistoryModal || !canCurrentViewerEdit()
                      ? 'quill-disabled'
                      : ''
                  "
                  class="text-center border-soul-letters-characters"
                >
                  <!-- {{
                    $v.lineListCharsCount.$each[parseInt(lineIdx, 10)].text.$model
                      .length
                  }}-->
                  <span v-if="lineListTotalCharsCount"
                    >{{
                      lineListTotalCharsCount[parseInt(lineIdx, 10)].text.length
                    }}
                    / {{ maxChars }} {{ $t("soulLetter.characters") }}</span
                  >
                </p>
                <span
                  class="error"
                  v-if="
                    !$v.lineListCharsCount.$each[parseInt(lineIdx, 10)].text
                      .maxLength
                  "
                >
                  {{ $t("soulLetter.error.charactersToMany") }}
                </span>
                <div
                  v-if="
                    currentUser &&
                      currentSoulLetter &&
                      revisionOption &&
                      revisions &&
                      revisions.length > 0
                  "
                >
                  <div
                    v-if="
                      currentSoulLetter.recipient &&
                        revisionOption.code !== revisions[0]._id
                    "
                  >
                    <div
                      class="border-soul-letters-differences"
                      v-if="currentUser._id === currentSoulLetter.recipient._id"
                      v-html="lineListDifferencesHtml[lineIdx] || ''"
                    />
                    <!--lineListDifferencesHtml -->
                    <!-- v-html="lineListDifferencesHtml[lineIdx] || ''" -->
                    <!-- v-html="getLineDifferences(lineIdx)" -->
                    <!--:id="'line' + parseInt(lineIdx, 10)"" -->
                  </div>
                </div>
              </b-form-group>
              <b-form-group v-if="currentUser && chosenClient" class="col-md-3">
                <quill-editor
                  class="editor quill-comment-section"
                  :class="
                    currentUser._id === chosenClient._id ||
                    isHistoryModal ||
                    !canCurrentViewerEdit()
                      ? 'quill-disabled'
                      : ''
                  "
                  :value="line.comment.$model"
                  @change="
                    inputEvent =>
                      quillDebounce('lineComment', inputEvent, lineIdx)
                  "
                  ref="linesQuillEditorLineComment"
                  :options="editorOption"
                  :disabled="
                    currentUser._id === chosenClient._id ||
                      isHistoryModal ||
                      !canCurrentViewerEdit()
                  "
                />
              </b-form-group>
            </b-form-row>
            <b-form-row class="mb-4 mt-n5 ml-auto mr-auto">
              <b-form-group class="col-md-12 mt-4 text-center">
                <button
                  :id="'lineButton' + lineIdx"
                  v-if="!isHistoryModal && canCurrentViewerEdit()"
                  @click.prevent="rerenderLineList(lineIdx)"
                  class="btn btn-outline-success mb-2 text-center"
                >
                  {{ $t("soulLetter.addNewLine") }}
                </button>
              </b-form-group>
            </b-form-row>
          </div>
          <b-form-row v-if="!isHistoryModal && currentSoulLetter" class="mb-4">
            <b-form-group class="col-md-12 soulLetter-bottom-buttons">
              <div class="text-left">
                <button
                  @click.prevent="openLeaveSoulLetterModal()"
                  class="btn btn-danger mb-2 mr-2"
                >
                  <b-img class="feathericons-x-square p-2 bg-white" />
                  {{ $t("soulLetter.close") }}
                </button>
                <button
                  @click.prevent="
                    updateRevision(revisions.length - 1, false, false)
                  "
                  :disabled="
                    $v.lineListCharsCount.$invalid || !canCurrentViewerEdit()
                  "
                  class="btn btn-warning mb-2 mr-2"
                >
                  <b-img class="feathericons-save p-2 bg-white" />
                  {{ $t("soulLetter.save") }}
                </button>
                <button
                  v-if="
                    currentSoulLetter.status !== 'unpaid' ||
                      isNotPaidYetWriteMore
                  "
                  @click.prevent="openSendSoulLetterModal"
                  :disabled="
                    $v.lineListCharsCount.$invalid ||
                      !canCurrentViewerEdit() ||
                      hasReachedMaxRevisions
                  "
                  class="btn btn-success mb-2 mr-2"
                >
                  <b-img class="feathericons-send p-2 bg-white" />
                  {{ $t("soulLetter.send") }}
                </button>
                <button
                  v-else-if="currentSoulLetter.status === 'unpaid'"
                  @click.prevent="openSendSoulLetterModal"
                  :disabled="
                    $v.lineListCharsCount.$invalid ||
                      !canCurrentViewerEdit() ||
                      isNotPaidYetWriteMore ||
                      hasReachedMaxRevisions
                  "
                  class="btn btn-success mb-2 mr-2"
                >
                  <b-img class="feathericons-send p-2 bg-white" />
                  {{ $t("soulLetter.send") }}
                </button>
              </div>
              <div class="col-12 text-center ml-0 pl-0">
                <div
                  class="sl-new-chars-alert col-10 pl-0 mt-2"
                  v-if="currentUser && currentSoulLetter"
                >
                  <div
                    v-if="currentUser._id === currentSoulLetter.sender._id"
                    class="alert alert-light-info border-0 mb-4 text-center"
                    role="alert"
                  >
                    {{
                      $t("soulLetter.addedAmountOfLinesInRevision").replace(
                        "%count",
                        calculatedTotalChars
                      )
                    }}
                  </div>
                </div>
              </div>
            </b-form-group>
          </b-form-row>
        </div>
      </b-form>
      <b-form v-else>
        <div v-if="loadingMainContent">
          <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
            <div
              class="spinner-border text-success align-self-center loader-lg"
            />
          </div>
        </div>
        <div v-else-if="loadingErrorMainContent" class="cm-loading-error m-4">
          {{ $t("calendarModule.errors.loadingError") }}
        </div>
        <div v-else>
          <b-form-row>
            <b-form-group class="col-md-1" />
            <b-form-group class="col-md-2" />
            <b-form-group class="col-md-6" />
          </b-form-row>
          <b-form-row class="mb-4">
            <b-form-group class="col-md-1">
              <label>{{ $t("soulLetter.reason") }}</label>
              <b-img
                id="tooltip-reason-mobile"
                class="feathericons-help-circle p-2 ml-1 bg-success"
              />
              <b-tooltip target="tooltip-reason" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.reasonDescription") }}</span>
              </b-tooltip>
              <b-tooltip target="tooltip-reason-mobile" triggers="hover">
                <span>{{ $t("soulLetter.toolTips.reasonDescription") }}</span>
              </b-tooltip>
            </b-form-group>
            <b-form-group class="col-md-2">
              <b-input
                type="text"
                class="form-control"
                :placeholder="$t('soulLetter.reason')"
                disabled
                :debounce="1000"
              />
            </b-form-group>
            <b-form-group class="col-md-6">
              <quill-editor
                class="editor"
                :class="
                  isHistoryModal || !canCurrentViewerEdit()
                    ? 'quill-disabled'
                    : ''
                "
                :value="$v.revisionSubjectText.$model"
                @change="
                  inputEvent =>
                    quillDebounce('revisionSubjectText', inputEvent, -1)
                "
                ref="revisionSubjectQuillEditor"
                :key="isRerenderList"
                :options="editorOption"
                :disabled="
                  isHistoryModal || !canCurrentViewerEdit() || quillDisabled
                "
                @focus="setFocus($event)"
              />
              <p
                :class="
                  isHistoryModal || !canCurrentViewerEdit()
                    ? 'quill-disabled'
                    : ''
                "
                class="text-center border-soul-letters-characters"
              >
                {{ revisionSubjectTextTotalLineCount.length }} / {{ maxChars }}
                {{ $t("soulLetter.characters") }}
                <!-- {{ charactersCountReason }} {{ $t("soulLetter.characters") }}-->
              </p>
              <span
                class="error"
                v-if="!$v.revisionSubjectTextCharsCount.maxLength"
              >
                {{ $t("soulLetter.error.charactersToMany") }}
              </span>
              <div
                v-if="
                  currentUser &&
                    currentSoulLetter &&
                    revisionOption &&
                    revisions &&
                    revisions.length > 0
                "
              >
                <div
                  v-if="
                    currentSoulLetter.recipient &&
                      revisionOption.code !== revisions[0]._id
                  "
                >
                  <div
                    class="border-soul-letters-differences"
                    v-if="currentUser._id === currentSoulLetter.recipient._id"
                    v-html="subjectDifferencesHtml"
                  />
                </div>
              </div>
            </b-form-group>
            <b-form-group class="col-md-3">
              <label>{{ $t("soulLetter.comment") }}</label>
            </b-form-group>
            <b-form-group
              v-if="currentUser && chosenClient && revisions.length > 0"
              class="col-md-3"
            >
              <quill-editor
                class="editor quill-comment-section"
                :class="
                  currentUser._id === chosenClient._id ||
                  isHistoryModal ||
                  !canCurrentViewerEdit()
                    ? 'quill-disabled'
                    : ''
                "
                :value="revisions[revisions.length - 1].subject.comment"
                @change="
                  inputEvent =>
                    quillDebounce('revisionSubjectComment', inputEvent)
                "
                ref="linesQuillEditorSubjectComment"
                :options="editorOption"
                :disabled="
                  currentUser._id === chosenClient._id ||
                    isHistoryModal ||
                    !canCurrentViewerEdit()
                "
              />
            </b-form-group>
          </b-form-row>
          <!-- HIER SPALTENÜBERSCHRIFTEN -->
          <b-form-row class="mb-4">
            <b-form-group class="col-md-3"> </b-form-group>
          </b-form-row>
          <!-- HIER BEGINN DER DYNAMISCHEN ZEILEN -->
          <div
            v-for="(line, lineIdx) in $v.lineList.$each.$iter"
            :key="lineIdx"
            :class="{ active: parseInt(lineIdx, 10) === activeItem }"
          >
            <b-form-row class="mt-1">
              <b-form-group class="col-12 mb-0">
                <label>{{ $t("soulLetter.row") }}</label>
                <b-img
                  id="tooltip-row-mobile"
                  class="feathericons-help-circle p-2 ml-1 bg-success"
                />
                <b-tooltip target="tooltip-row" triggers="hover">
                  <span>{{ $t("soulLetter.toolTips.row") }}</span>
                </b-tooltip>
                <b-tooltip target="tooltip-row-mobile" triggers="hover">
                  <span>{{ $t("soulLetter.toolTips.row") }}</span>
                </b-tooltip>
              </b-form-group>
              <b-form-group class="col-12">
                <span class="mr-3" :id="'line' + lineIdx">{{ lineIdx }}</span>
                <b-img
                  v-if="!isHistoryModal && canCurrentViewerEdit()"
                  ref="soulLetterDeleteRow"
                  class="feathericons-trash p-2 bg-danger btn"
                  @click="openDeleteRowModal(lineIdx)"
                />
              </b-form-group>
            </b-form-row>
            <b-form-row class="mt-1">
              <b-form-group class="col-md-12 mb-0">
                <label>{{ $t("soulLetter.person") }}</label>
                <b-img
                  id="tooltip-person-mobile"
                  class="feathericons-help-circle p-2 ml-1 bg-success"
                />
                <b-tooltip target="tooltip-person" triggers="hover">
                  <span>{{ $t("soulLetter.toolTips.person") }}</span>
                </b-tooltip>
                <b-tooltip target="tooltip-person-mobile" triggers="hover">
                  <span>{{ $t("soulLetter.toolTips.person") }}</span>
                </b-tooltip>
              </b-form-group>
              <b-form-group class="col-md-12">
                <b-input
                  type="text"
                  class="form-control"
                  :disabled="isHistoryModal || !canCurrentViewerEdit()"
                  v-model="line.actor.$model"
                  :debounce="1000"
                />
              </b-form-group>
            </b-form-row>
            <b-form-row class="mt-1">
              <b-form-group class="col-md-12 mb-0">
                <label>{{ $t("soulLetter.scene") }}</label>
                <b-img
                  id="tooltip-scene-mobile"
                  class="feathericons-help-circle p-2 ml-1 bg-success"
                />
                <b-tooltip target="tooltip-scene" triggers="hover">
                  <span>{{ $t("soulLetter.toolTips.scene") }}</span>
                </b-tooltip>
                <b-tooltip target="tooltip-scene-mobile" triggers="hover">
                  <span>{{ $t("soulLetter.toolTips.scene") }}</span>
                </b-tooltip>
              </b-form-group>
              <b-form-group class="col-md-12">
                <quill-editor
                  class="editor"
                  :class="
                    isHistoryModal || !canCurrentViewerEdit()
                      ? 'quill-disabled'
                      : ''
                  "
                  :value="line.text.$model"
                  @change="
                    inputEvent => quillDebounce('line', inputEvent, lineIdx)
                  "
                  :key="isRerenderList"
                  ref="linesQuillEditorLineText"
                  :options="editorOption"
                  :disabled="
                    isHistoryModal || !canCurrentViewerEdit() || quillDisabled
                  "
                  @focus="setFocus($event)"
                />
                <p
                  :class="
                    isHistoryModal || !canCurrentViewerEdit()
                      ? 'quill-disabled'
                      : ''
                  "
                  class="text-center border-soul-letters-characters"
                >
                  <!-- {{
                    $v.lineListCharsCount.$each[parseInt(lineIdx, 10)].text.$model
                      .length
                  }}-->
                  <span v-if="lineListTotalCharsCount"
                    >{{
                      lineListTotalCharsCount[parseInt(lineIdx, 10)].text.length
                    }}
                    / {{ maxChars }} {{ $t("soulLetter.characters") }}</span
                  >
                </p>
                <span
                  class="error"
                  v-if="
                    !$v.lineListCharsCount.$each[parseInt(lineIdx, 10)].text
                      .maxLength
                  "
                >
                  {{ $t("soulLetter.error.charactersToMany") }}
                </span>
                <div
                  v-if="
                    currentUser &&
                      currentSoulLetter &&
                      revisionOption &&
                      revisions &&
                      revisions.length > 0
                  "
                >
                  <div
                    v-if="
                      currentSoulLetter.recipient &&
                        revisionOption.code !== revisions[0]._id
                    "
                  >
                    <div
                      class="border-soul-letters-differences"
                      v-html="lineListDifferencesHtml[lineIdx] || ''"
                      v-if="currentUser._id === currentSoulLetter.recipient._id"
                    />
                    <!-- v-html="getLineDifferences(lineIdx)"   v-html="getLineDifferences(lineIdx)"-->
                  </div>
                </div>
              </b-form-group>
            </b-form-row>
            <b-form-row class="mb-4 mt-1">
              <b-form-group class="col-md-3 mb-0">
                <label>{{ $t("soulLetter.comment") }}</label>
              </b-form-group>
              <b-form-group v-if="currentUser && chosenClient" class="col-md-3">
                <quill-editor
                  class="editor quill-comment-section"
                  :class="
                    currentUser._id === chosenClient._id ||
                    isHistoryModal ||
                    !canCurrentViewerEdit()
                      ? 'quill-disabled'
                      : ''
                  "
                  :value="line.comment.$model"
                  @change="
                    inputEvent =>
                      quillDebounce('lineComment', inputEvent, lineIdx)
                  "
                  ref="linesQuillEditorLineComment"
                  :options="editorOption"
                  :disabled="
                    currentUser._id === chosenClient._id ||
                      isHistoryModal ||
                      !canCurrentViewerEdit()
                  "
                />
              </b-form-group>
            </b-form-row>
            <b-form-row class="mb-4 ml-auto mr-auto">
              <b-form-group class="col-md-12 mt-4 text-center">
                <button
                  :id="'lineButton' + lineIdx"
                  v-if="!isHistoryModal && canCurrentViewerEdit()"
                  @click.prevent="rerenderLineList(lineIdx)"
                  class="btn btn-outline-success mb-2 text-center"
                >
                  {{ $t("soulLetter.addNewLine") }}
                </button>
              </b-form-group>
            </b-form-row>
          </div>
          <b-form-row v-if="!isHistoryModal && currentSoulLetter" class="mb-4">
            <b-form-group class="col-md-12 soulLetter-bottom-buttons">
              <div class="text-left">
                <button
                  @click.prevent="openLeaveSoulLetterModal()"
                  class="btn btn-danger mb-2 mr-2"
                >
                  <b-img class="feathericons-x-square p-2 bg-white" />
                  {{ $t("soulLetter.close") }}
                </button>
                <button
                  @click.prevent="
                    updateRevision(revisions.length - 1, false, false)
                  "
                  :disabled="
                    $v.lineListCharsCount.$invalid || !canCurrentViewerEdit()
                  "
                  class="btn btn-warning mb-2 mr-2"
                >
                  <b-img class="feathericons-save p-2 bg-white" />
                  {{ $t("soulLetter.save") }}
                </button>
                <button
                  v-if="
                    currentSoulLetter.status !== 'unpaid' ||
                      isNotPaidYetWriteMore
                  "
                  @click.prevent="openSendSoulLetterModal"
                  :disabled="
                    $v.lineListCharsCount.$invalid ||
                      !canCurrentViewerEdit() ||
                      hasReachedMaxRevisions
                  "
                  class="btn btn-success mb-2 mr-2"
                >
                  <b-img class="feathericons-send p-2 bg-white" />
                  {{ $t("soulLetter.send") }}
                </button>
                <button
                  v-else-if="currentSoulLetter.status === 'unpaid'"
                  @click.prevent="openSendSoulLetterModal"
                  :disabled="
                    $v.lineListCharsCount.$invalid ||
                      !canCurrentViewerEdit() ||
                      isNotPaidYetWriteMore ||
                      hasReachedMaxRevisions
                  "
                  class="btn btn-success mb-2 mr-2"
                >
                  <b-img class="feathericons-send p-2 bg-white" />
                  {{ $t("soulLetter.send") }}
                </button>
              </div>
              <div class="col-12 text-center ml-0 pl-0">
                <div
                  class="sl-new-chars-alert col-10 pl-0 mt-2"
                  v-if="currentUser && currentSoulLetter"
                >
                  <div
                    v-if="currentUser._id === currentSoulLetter.sender._id"
                    class="alert alert-light-info border-0 mb-4 text-center"
                    role="alert"
                  >
                    {{
                      $t("soulLetter.addedAmountOfLinesInRevision").replace(
                        "%count",
                        calculatedTotalChars
                      )
                    }}
                  </div>
                </div>
              </div>
            </b-form-group>
          </b-form-row>
        </div>
      </b-form>
      <!-- Choose Companion Modal -->
      <b-modal
        :title="$t('soulLetter.chooseCompanion')"
        ref="chooseCompanionModal"
        size="xl"
        hide-footer
        onclose="closeChooseCompanionModal()"
      >
        <div v-if="currentSoulLetter" class="mb-5">
          <b-row class="mb-5">
            <b-col>
              <h5 class="font-weight-bold text-center">
                {{ $t("soulLetter.sendSoulLetterDescWithoutComp") }}
              </h5>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col class="col-12">
              <CompanionSelection
                :set-chosen-companion="val => setChosenCompanion(val)"
                :is-from-soul-letter-without-comp="true"
              />
            </b-col>
          </b-row>
        </div>
      </b-modal>
      <!-- History Modal -->
      <b-modal
        :title="$t('soulLetter.historyTitle')"
        ref="soulLetterHistoryModal"
        size="xl"
        hide-footer
        onclose="closeSoulLetterHistoryModal()"
      >
        <div v-if="loading">
          <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
            <div
              class="spinner-border text-success align-self-center loader-lg"
            />
          </div>
        </div>
        <div v-else-if="loadingError" class="cm-loading-error">
          {{ $t("calendarModule.errors.loadingError") }}
        </div>
        <div v-else>
          <ConversationHistoryTable
            :history-conversations="historyConversations"
            :is-mobile-screen-size="isMobileScreenSize"
            :is-modal-mobile-screen-size="isModalMobileScreenSize"
            :is-sm-desktop-screen-size="isSmDesktopScreenSize"
            :is-tablet-screen-size="isTabletScreenSize"
          />
          <div class="mb-1" />
          <SoulLetterHistoryTable
            :chosen-client="chosenClient"
            :history-soul-letters="historySoulLetters"
            :is-mobile-screen-size="isMobileScreenSize"
            :is-modal-mobile-screen-size="isModalMobileScreenSize"
            :is-sm-desktop-screen-size="isSmDesktopScreenSize"
            :is-tablet-screen-size="isTabletScreenSize"
          />
          <b-row class="mt-3">
            <b-col>
              <button
                @click="closeSoulLetterHistoryModal"
                class="btn btn-primary mb-2 mr-2"
              >
                {{ $t("misc.close") }}
              </button>
            </b-col>
          </b-row>
        </div>
      </b-modal>
      <!-- Delete Row Modal -->
      <b-modal
        :title="$t('soulLetter.deleteRow')"
        ref="deleteRowModal"
        hide-footer
        onclose="closeDeleteRowModal()"
      >
        <p>{{ $t("soulLetter.confirmDeleteRow") }}</p>
        <button
          @click="removeElementFromLineList"
          class="btn btn-success mb-2 mr-2"
        >
          {{ $t("soulLetter.deleteRow") }}
        </button>
        <button
          @click="closeDeleteRowModal"
          class="btn btn-outline-danger mb-2 mr-2"
        >
          {{ $t("misc.abort") }}
        </button>
      </b-modal>
      <!-- Send SoulLetter Modal -->
      <b-modal
        :title="$t('soulLetter.sendSoulLetter')"
        ref="sendSoulLetterModal"
        size="lg"
        hide-footer
        onclose="closeSendSoulLetterModal()"
      >
        <div v-if="currentSoulLetter && currentUser" class="mb-5">
          <div
            v-if="
              currentUser._id === currentSoulLetter.sender._id &&
                !isStudentPackage
            "
          >
            <b-row class="mb-3">
              <b-col>
                <p>{{ $t("soulLetter.sendSoulLetterDescription") }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-5">
                <h6 class="font-weight-bold">
                  {{ $t("soulLetter.countedCharacterAmount") }} :
                </h6>
              </b-col>
              <b-col>
                {{ totalCharacters }}
                <!--{{ currentSoulLetter.latestRevision.billableCharacterCount }}-->
              </b-col>
            </b-row>
            <b-row v-if="revisions.length > 0">
              <b-col class="col-5">
                <h6 class="font-weight-bold">
                  {{ $t("soulLetter.estimatedPrice") }}:
                </h6>
              </b-col>
              <b-col>
                {{ revisions[revisions.length - 1].predictedPrice.price }}
                {{ revisions[revisions.length - 1].predictedPrice.currency }}
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <h6 class="font-weight-bold">
              {{ $t("soulLetter.sendSoulLetterConfirmation") }}
            </h6>
          </div>
        </div>
        <button
          @click.prevent="updateSoulLetter()"
          :disabled="$v.lineListCharsCount.$invalid || hasReachedMaxRevisions"
          class="btn btn-success mb-2 mr-2"
        >
          <b-img class="feathericons-send p-2 bg-white" />
          {{ $t("soulLetter.send") }}
        </button>
        <button
          @click="closeSendSoulLetterModal"
          class="btn btn-outline-danger mb-2 mr-2"
        >
          {{ $t("misc.abort") }}
        </button>
      </b-modal>
      <!-- Leave SoulLetter Modal -->
      <b-modal
        :title="$t('soulLetter.leaveSoulLetterModal.title')"
        ref="leaveSoulLetterModal"
        size="md"
        hide-footer
        onclose="closeLeaveSoulLetterModal()"
      >
        <b-row class="mb-5">
          <b-col>
            <h6>{{ $t("soulLetter.leaveSoulLetterModal.description") }}</h6>
          </b-col>
        </b-row>
        <button
          @click.prevent="closeSoulLetter()"
          class="btn btn-success mb-2 mr-2"
        >
          {{ $t("misc.confirm") }}
        </button>
        <button
          @click="closeLeaveSoulLetterModal()"
          class="btn btn-outline-danger mb-2 mr-2"
        >
          {{ $t("misc.abort") }}
        </button>
      </b-modal>
      <!-- Unpaid Status Modal -->
      <b-modal
        :title="$t('soulLetter.unpaidStatusModal.title')"
        ref="unpaidStatusModal"
        hide-footer
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        @close="closeUnpaidStatusModal()"
      >
        <p>{{ $t("soulLetter.unpaidStatusModal.description") }}</p>
        <button
          @click.prevent="payForSoulLetter()"
          class="btn btn-success mb-2 mr-2"
        >
          {{ $t("misc.pay") }}
        </button>
        <button
          @click.prevent="closeUnpaidStatusModal()"
          class="btn btn-outline-success mb-2 mr-2"
        >
          {{ $t("soulLetter.unpaidStatusModal.writeMore") }}
        </button>
      </b-modal>
      <notifications classes="top-right-notification" />
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import * as ESoulLetterStatus from "../../store/modules/soulLetters/ESoulLetterStatus";
import * as revisionsApi from "@/store/modules/soulLetters/revisions/revisions.api";
import * as soulLettersApi from "@/store/modules/soulLetters/soulLetters.api";
import { maxLength } from "vuelidate/lib/validators";
import { addNotification } from "@/utils/notificationHandler";
import { mapGetters } from "vuex";
import * as userApi from "@/store/modules/users/users.api";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import * as moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { convertToLocal } from "@/utils/timezoneHelper";
import SoulLetterHistoryTable from "@/components/soulLetters/SoulLetterHistoryTable";
import screenSizeMixin from "@/mixins/screenSize";
import { Quill } from "vue-quill-editor/dist/ssr";
import undoIcon from "quill/assets/icons/undo.svg";
import redoIcon from "quill/assets/icons/redo.svg";

import * as Diff from "text-diff";
import _ from "lodash";

export default {
  name: "SoulLetter",
  title: "SoulLetters",
  components: {
    SoulLetterHistoryTable,
    CompanionSelection: () =>
      import("@/components/soulLetters/CompanionSelection"),
    ConversationHistoryTable: () =>
      import("@/components/conversations/ConversationHistoryTable"),
    quillEditor,
    vSelect
  },
  mixins: [screenSizeMixin],
  props: {
    isWithoutComp: Boolean,
    soulLetterTitle: String,
    currentSoulLetter: Object,
    setSoulLetterOnly: Function,
    isHistoryModal: Boolean,
    isCompanionModal: Boolean,
    currentClient: Object
  },
  data() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return {
      tmpQuillEditor: null,
      loadingMainContent: false,
      loadingErrorMainContent: false,
      debounceTimeouts: {},
      ignoreNextDebounce: false,
      lineListDifferencesHtml: [],
      subjectDifferencesHtml: [],
      calculatedTotalChars: 0,
      isNotPaidYet: false,
      isNotPaidYetWriteMore: false,
      isRerenderList: false,
      quillDisabled: false,
      activeItem: null,
      totalCharacters: 0,
      totalConversationDuration: 0,
      loading: false,
      loadingError: false,
      EUserRoles,
      isChosenCompanionAllowedToSeeSoulLetter: true,
      chosenHistorySoulLetter: null,
      chosenHistoryConversation: null,
      chosenHistoryConversationPrice: null,
      moment,
      revisionOption: {
        label: "",
        code: ""
      },
      historySoulLetters: [],
      historyConversations: [],
      historyGuidedConversations: 0,
      historyTotalConversationDuration: 0,
      historySoulLettersAmount: 0,
      chosenCompanion: null,
      chosenClient: null,
      currentUser: null,
      currentRevision: null,
      isStudentPackage: false,
      hasReachedMaxRevisions: false,
      timerForRevisionUpdateIntervalCron: null,
      soulLetterComment: "",
      ESoulLetterStatus,
      charactersCountReason: 0,
      charactersCountScene: 0,
      globalLineIdx: null,
      lineListCharsCount: [],
      lineListTotalCharsCount: [],
      lineList: [],
      isHovering: false,
      revisionSubjectText: "",
      revisionSubjectTextCharsCount: "",
      revisionSubjectTextTotalLineCount: "",
      maxChars: 1000,
      revisions: [],
      isUndoClicked: false,
      isRedoClicked: false,
      editorOption: {
        placeholder: "",
        theme: "snow",
        modules: {
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true
          },
          toolbar: {
            container: [
              ["bold", "strike", "italic"],
              [{ color: [] }, { background: [] }],
              ["clean"],
              [{ undo: undoIcon }, { redo: redoIcon }]
            ],
            handlers: {
              redo() {
                self.redoQuillChange();
              },
              undo() {
                self.undoQuillChange();
              }
            }
          }
        }
      }
    };
  },
  validations: {
    lineList: {
      $each: {
        actor: {
          maxLength: maxLength(100)
        },
        text: {
          maxLength: maxLength(1000)
        },
        comment: {
          maxLength: maxLength(1000)
        }
      }
    },
    lineListCharsCount: {
      $each: {
        text: {
          maxLength: maxLength(1000)
        }
      }
    },
    revisionSubjectTextCharsCount: {
      maxLength: maxLength(1000)
    },
    revisionSubjectText: {
      maxLength: maxLength(1000)
    }
  },
  computed: {
    ...mapGetters("users", ["getCurrentUser"]),
    editor() {
      return this.tmpQuillEditor;
    },
    lineListLength() {
      return this.lineList.length;
    },
    revisionOptions() {
      const tmpRevisions = [];
      for (let i = 0; i < this.revisions.length - 1; i++) {
        tmpRevisions.push(JSON.parse(JSON.stringify(this.revisions[i])));
      }
      const options = tmpRevisions.map(el => {
        return {
          label: moment(el.updateTimeStamp).format("DD.MM.YYYY - HH:mm"),
          code: el._id
        };
      });
      return options;
    }
  },
  methods: {
    undoQuillChange() {
      if (this.editor) {
        this.editor.history.undo();
      }
    },
    redoQuillChange() {
      if (this.editor) {
        this.editor.history.redo();
      }
    },
    quillDebounce(variable, inputEvent, lineIdx = null) {
      if (this.ignoreNextDebounce) {
        this.ignoreNextDebounce = false;
        return;
      }

      const debounceTimeoutIndex = lineIdx ? `${variable}${lineIdx}` : variable;
      if (this.debounceTimeouts[debounceTimeoutIndex] !== null) {
        clearTimeout(this.debounceTimeouts[debounceTimeoutIndex]);
      }

      this.debounceTimeouts[debounceTimeoutIndex] = setTimeout(() => {
        if (variable === "line") {
          this.lineList[lineIdx].text = inputEvent.html;
        } else if (variable === "lineComment") {
          this.lineList[lineIdx].comment = inputEvent.html;
        } else if (variable === "soulLetterComment") {
          this.soulLetterComment = inputEvent.html;
        } else if (variable === "revisionSubjectText") {
          this.$v.revisionSubjectText.$model = inputEvent.html;
        } else if (variable === "revisionSubjectComment") {
          this.revisions[this.revisions.length - 1].subject.comment =
            inputEvent.html;
        }
        if (lineIdx !== null) {
          if (lineIdx === -1) {
            this.countCharacters();
          } else {
            this.countCharactersForLines(lineIdx);
          }
          this.limitChars(inputEvent, lineIdx);
        }
      }, 1000);
    },
    setCalculatedTotalChars() {
      this.calculatedTotalChars = 0;

      this.calculatedTotalChars =
        this.calculatedTotalChars + this.revisionSubjectTextCharsCount.length; //revisionSubjectTextWithoutExtraChars.length;
      this.lineListCharsCount.forEach(line => {
        const lineWithoutExtraChars = this.removeHTMLFromString(line.text);
        this.calculatedTotalChars =
          this.calculatedTotalChars + lineWithoutExtraChars.length;
      });
    },
    setChosenCompanion(val) {
      const companionPayload = {
        familyName: val.profile.familyName,
        givenName: val.profile.givenName,
        name: val.profile.name,
        profileImage: val.profile.picture,
        role: val.role,
        _id: val._id
      };
      this.chosenCompanion = companionPayload;
      this.updateRevision(this.revisions.length - 1, false, false);
      this.closeChooseCompanionModal();
    },
    closeChooseCompanionModal() {
      this.$refs["chooseCompanionModal"].hide();
    },
    openChooseCompanionModal() {
      this.$refs["chooseCompanionModal"].show();
    },
    payForSoulLetter() {
      if (!this.isNotPaidYetWriteMore) {
        if (this.currentSoulLetter.latestRevision.transaction) {
          const buyUrl = this.currentSoulLetter.latestRevision.transaction
            .buyUrl.url;
          if (buyUrl) {
            window.location.replace(buyUrl);
          }
        }
      }
    },
    closeUnpaidStatusModal() {
      this.loading = true;
      this.loadingError = false;
      soulLettersApi
        .cancelSoulLetterTransaction(this.currentSoulLetter._id)
        .then(res => {
          this.isNotPaidYetWriteMore = true;
          this.$refs["unpaidStatusModal"].hide();
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    openUnpaidStatusModal() {
      this.isNotPaidYetWriteMore = false;
      this.$refs["unpaidStatusModal"].show();
    },
    getPreviousRevision() {
      for (let i = 1; i < this.revisions.length; i++) {
        if (this.revisions[i]._id === this.currentRevision._id)
          return this.revisions[i - 2];
      }
      return null;
    },
    getDiffPatches(original, followup) {
      const diff = new Diff();
      return diff.main(
        original.replaceAll(/(<([^>]+)>)/gi, ""),
        followup.replaceAll(/(<([^>]+)>)/gi, "")
      );
    },
    getSummaryDifferences() {
      const previousRevision = this.getPreviousRevision();
      if (previousRevision) {
        const patches = this.getDiffPatches(
          previousRevision.subject.text,
          this.revisionSubjectText
        );
        let result = "";
        for (const patch of patches) {
          result += `<span class="${
            patch[0] === 1
              ? "revdiff-add"
              : patch[0] === -1
              ? "revdiff-remove"
              : ""
          }">${patch[1]}</span>`;
        }
        return result;
      } else {
        return `<span class="revdiff-add">${this.revisionSubjectText}</span>`;
      }
    },
    getLineDifferences(lineId) {
      const line = this.lineList[lineId];
      const previousRevision = this.getPreviousRevision();

      let previousLine = null;
      if (previousRevision) {
        previousLine = previousRevision.lines.find(
          prevLine => line._id === prevLine._id
        );
      }

      if (previousRevision && previousLine) {
        const patches = this.getDiffPatches(previousLine.text, line.text);
        let result = "";
        for (const patch of patches) {
          result += `<span class="${
            patch[0] === 1
              ? "revdiff-add"
              : patch[0] === -1
              ? "revdiff-remove"
              : ""
          }">${patch[1]}</span>`;
        }
        return result;
      } else {
        return `<span class="revdiff-add">${line.text}</span>`;
      }
    },
    closeSoulLetter() {
      if (this.canCurrentViewerEdit()) {
        this.updateRevision(this.revisions.length - 1, true, false);
      } else {
        clearInterval(this.timerForRevisionUpdateIntervalCron);
        this.$emit("soulLetterSavedAndSent");
      }
    },
    closeLeaveSoulLetterModal() {
      this.$refs["leaveSoulLetterModal"].hide();
    },
    openLeaveSoulLetterModal() {
      this.$refs["leaveSoulLetterModal"].show();
    },
    openSoulLetterHistoryModal() {
      this.loading = true;
      this.loadingError = false;
      userApi
        .getUserHistory(this.chosenClient._id)
        .then(res => {
          const userHistory = res.data.data;
          this.historyConversations = [];
          this.historySoulLetters = [];
          this.historySoulLettersAmount = userHistory.letters.length;
          this.historySoulLetters = userHistory.letters;

          userHistory.conversations.forEach(conversation => {
            const convertedUtc = convertToLocal(
              conversation.appointment.date,
              conversation.appointment.endDate,
              conversation.appointment.time,
              conversation.appointment.endTime
            );
            const formattedAppointment = {
              date: convertedUtc.date,
              endDate: convertedUtc.endDate,
              time: convertedUtc.time,
              endTime: convertedUtc.endTime,
              _id: conversation.appointment._id
            };
            const toDay = moment();
            if (
              moment(formattedAppointment.date, "YYYY-MM-DD").diff(
                moment(toDay, "YYYY-MM-DD")
              ) <= 0
            ) {
              let formattedConversation;
              if (conversation.zoomMeeting) {
                formattedConversation = {
                  appointment: formattedAppointment,
                  attendees: conversation.attendees,
                  host: conversation.host,
                  subject: conversation.subject,
                  zoomMeeting: conversation.zoomMeeting,
                  _id: conversation._id
                };
              } else {
                formattedConversation = {
                  appointment: formattedAppointment,
                  attendees: conversation.attendees,
                  host: conversation.host,
                  subject: conversation.subject,
                  _id: conversation._id
                };
              }

              this.historyConversations.push(formattedConversation);
            }
          });
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
      this.$refs["soulLetterHistoryModal"].show();
    },
    closeSoulLetterHistoryModal() {
      this.$refs["soulLetterHistoryModal"].hide();
    },
    reloadChosenRevision() {
      this.loading = true;
      this.loadingError = false;
      let tmpRevisionOption = null;
      if (
        this.revisions[this.revisions.length - 2]._id === this.revisionOption
      ) {
        tmpRevisionOption = JSON.parse(
          JSON.stringify(this.revisions[this.revisions.length - 1]._id)
        );
      } else {
        tmpRevisionOption = JSON.parse(JSON.stringify(this.revisionOption));
      }
      revisionsApi
        .getRevision(this.currentSoulLetter._id, tmpRevisionOption)
        .then(res => {
          const revision = res.data.data;
          this.currentRevision = revision;
          this.updateLineList(this.currentRevision);
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    removeHTMLFromString(str, cullSpaces = false) {
      return _.unescape(
        str
          .replaceAll(/(<([^>]+)>)/gi, "")
          .replaceAll(" ", "")
          .replaceAll("&nbsp;", "")
      );
      /* const safeString = str.replace(/(<([^>]+)>)/gi, "");
      const withOutWhiteSpaceStr = safeString.replace(/\s/g, "");
      return withOutWhiteSpaceStr;*/
    },
    countCharactersForLines(lIdx) {
      const line = this.lineList[lIdx];
      const previousRevision = this.getPreviousRevision();

      let previousLine = null;
      if (previousRevision) {
        previousLine = previousRevision.lines.find(
          prevLine => line._id === prevLine._id
        );
      }

      if (previousRevision && previousLine) {
        const patches = this.getDiffPatches(previousLine.text, line.text);
        let result = "";
        for (const patch of patches) {
          if (patch[0] !== 1) continue;
          result += this.htmlToTextWithoutWs(patch[1]);
        }
        this.lineListCharsCount[lIdx].text = result;
      } else {
        this.lineListCharsCount[lIdx].text = this.htmlToTextWithoutWs(
          line.text
        );
      }
      this.lineListTotalCharsCount[lIdx].text = this.htmlToTextWithoutWs(
        JSON.parse(JSON.stringify(line.text))
      );
    },
    htmlToTextWithoutWs(str) {
      return _.unescape(
        str
          .replaceAll(/(<([^>]+)>)/gi, "")
          .replaceAll(" ", "")
          .replaceAll("&nbsp;", "")
      );
    },
    countCharacters() {
      const previousRevision = this.getPreviousRevision();
      if (previousRevision) {
        const patches = this.getDiffPatches(
          previousRevision.subject.text,
          this.revisionSubjectText
        );
        // let count = 0;
        let result = "";
        for (const patch of patches) {
          if (patch[0] !== 1) continue;
          result += this.htmlToTextWithoutWs(patch[1]);
        }
        //    this.charactersCountReason = count;
        this.revisionSubjectTextCharsCount = result;
      } else {
        this.revisionSubjectTextCharsCount = this.htmlToTextWithoutWs(
          this.revisionSubjectText
        );
      }

      this.revisionSubjectTextTotalLineCount = this.htmlToTextWithoutWs(
        this.revisionSubjectText
      );
    },
    limitChars({ quill, html, text }, lineIndex) {
      const lIdx = parseInt(lineIndex, 10);
      let doCalculation = false;
      if (lIdx > -1 && this.lineListTotalCharsCount[lIdx]) {
        if (this.lineListTotalCharsCount[lIdx].text.length > 1000) {
          doCalculation = true;
        }
      } else if (lIdx === -1) {
        if (this.revisionSubjectTextTotalLineCount.length > 1000) {
          doCalculation = true;
        }
      }

      if (doCalculation) {
        let charCounter = 0;
        let positionOfChar1000 = null;
        for (let i = 0; i < text.length; i++) {
          if (
            text.charAt(i) !== " " &&
            text.charAt(i).charCodeAt(0) !== 160 &&
            text.charAt(i) !== "\n" &&
            text.charAt(i) !== "\r"
          ) {
            const c = text.charAt(i);
            charCounter++;
            if (charCounter === 1000) {
              positionOfChar1000 = i;
              break;
            }
          }
        }
        if (positionOfChar1000) {
          quill.deleteText(positionOfChar1000 + 1, quill.getLength());
          this.ignoreNextDebounce = true;
          alert(this.$t("soulLetter.error.charactersToMany"));
        }
      }
    },
    removeElementFromLineList() {
      this.lineList.splice(this.globalLineIdx, 1);
      this.lineListCharsCount.splice(this.globalLineIdx, 1);
      this.lineListTotalCharsCount.splice(this.globalLineIdx, 1);
      this.$refs["deleteRowModal"].hide();
    },
    setFocus(event) {
      this.tmpQuillEditor = event;
      this.$nextTick(() => {
        this.quillDisabled = false;
      });
      setTimeout(() => {
        event.focus();
      });
    },
    rerenderLineList(lineIdx) {
      this.$nextTick(() => {
        this.quillDisabled = true;
      });
      this.isRerenderList = !this.isRerenderList;
      this.addElementToLineList(lineIdx);
    },
    addElementToLineList(lineIdx) {
      this.$nextTick(() => {
        this.quillDisabled = true;
      });
      const lineIdxNumber = parseInt(lineIdx, 10);
      if (lineIdxNumber === this.lineList.length - 1) {
        const payload = {
          _id: uuidv4(),
          actor: "",
          comment: "",
          text: ""
        };
        const payloadCharsCount = JSON.parse(JSON.stringify(payload));
        const payloadTotalCharsCount = JSON.parse(JSON.stringify(payload));
        this.lineList.push(payload);
        const length = this.lineListCharsCount.push(payloadCharsCount);
        this.lineListTotalCharsCount.push(payloadTotalCharsCount);
        this.countCharactersForLines(length - 1);
      } else {
        const payload = {
          _id: uuidv4(),
          actor: "",
          comment: "",
          text: ""
        };
        const payloadCharsCount = JSON.parse(JSON.stringify(payload));
        const payloadTotalCharsCount = JSON.parse(JSON.stringify(payload));
        this.lineList.splice(lineIdxNumber + 1, 0, payload);
        this.lineListCharsCount.splice(lineIdxNumber + 1, 0, payloadCharsCount);
        this.lineListTotalCharsCount.splice(
          lineIdxNumber + 1,
          0,
          payloadTotalCharsCount
        );
        this.countCharactersForLines(lineIdxNumber + 1);
      }
    },
    setRevisionVariables() {
      this.revisionSubjectText = this.revisions[
        this.revisions.length - 1
      ].subject.text;
    },
    updateLineList(currentRevision) {
      this.lineList = [];
      this.lineListCharsCount = [];
      this.lineListTotalCharsCount = [];
      this.revisionSubjectText = currentRevision.subject.text;
      for (let j = 0; j < currentRevision.lines.length; j++) {
        const line = {
          _id: currentRevision.lines[j]._id,
          actor: currentRevision.lines[j].actor,
          text: currentRevision.lines[j].text,
          comment: currentRevision.lines[j].comment
        };
        const lineForCharactersCount = JSON.parse(JSON.stringify(line));
        lineForCharactersCount.text = this.removeHTMLFromString(
          lineForCharactersCount.text
        );
        const lineForTotalCharactersCount = JSON.parse(JSON.stringify(line));
        lineForTotalCharactersCount.text = this.removeHTMLFromString(
          lineForTotalCharactersCount.text
        );
        this.lineList.push(line);
        const length = this.lineListCharsCount.push(lineForCharactersCount);
        this.lineListTotalCharsCount.push(lineForTotalCharactersCount);
        this.countCharactersForLines(length - 1);
      }
      if (this.lineList.length < 1) {
        this.addElementToLineList(0);
      }
    },
    constructLineList(isSendSoulLetterModal, isFirstMount) {
      this.lineList = [];
      this.lineListCharsCount = [];
      this.lineListTotalCharsCount = [];
      const latestRevisionCount = this.revisions.length - 1;
      for (
        let j = 0;
        j < this.revisions[latestRevisionCount].lines.length;
        j++
      ) {
        const line = {
          _id: this.revisions[latestRevisionCount].lines[j]._id,
          actor: this.revisions[latestRevisionCount].lines[j].actor,
          text: this.revisions[latestRevisionCount].lines[j].text,
          comment: this.revisions[latestRevisionCount].lines[j].comment
        };
        const lineForCharactersCount = JSON.parse(JSON.stringify(line));
        lineForCharactersCount.text = this.removeHTMLFromString(
          lineForCharactersCount.text
        );
        const lineForTotalCharactersCount = JSON.parse(JSON.stringify(line));
        lineForTotalCharactersCount.text = this.removeHTMLFromString(
          lineForTotalCharactersCount.text
        );
        this.lineList.push(line);
        const length = this.lineListCharsCount.push(lineForCharactersCount);
        this.lineListTotalCharsCount.push(lineForTotalCharactersCount);
        this.countCharactersForLines(length - 1);
        if (isFirstMount) {
          this.lineListDifferencesHtml.push(this.getLineDifferences(j));
          this.subjectDifferencesHtml.push(this.getSummaryDifferences(j));
        }
      }
      if (this.lineList.length < 1) {
        this.addElementToLineList(0);
      }
    },
    openDeleteRowModal(lineIdx) {
      this.globalLineIdx = lineIdx;
      this.$refs["deleteRowModal"].show();
    },
    closeDeleteRowModal() {
      this.globalLineIdx = null;
      this.$refs["deleteRowModal"].hide();
    },
    closeSendSoulLetterModal() {
      this.$refs["sendSoulLetterModal"].hide();
    },
    calculateTotalCharacters() {
      this.totalCharacters = 0;

      const revisionSubjectTextWithoutExtraChars = this.removeHTMLFromString(
        this.revisionSubjectText
      );
      this.totalCharacters =
        this.totalCharacters + this.revisionSubjectTextCharsCount.length; //revisionSubjectTextWithoutExtraChars.length;
      this.lineListCharsCount.forEach(line => {
        const lineWithoutExtraChars = this.removeHTMLFromString(line.text);
        this.totalCharacters =
          this.totalCharacters + lineWithoutExtraChars.length;
      });
    },
    openSendSoulLetterModal() {
      this.calculateTotalCharacters();
      this.updateRevision(this.revisions.length - 1, false, false, true);
    },
    updateRevision(
      revisionIdxString,
      isCloseSoulLetter,
      isAutomaticUpdate,
      isSendSoulLetterModal
    ) {
      this.setCalculatedTotalChars();
      const revisionIdx = parseInt(revisionIdxString, 10);
      this.loading = true;
      this.loadingError = false;

      revisionsApi
        .updateRevision(
          this.currentSoulLetter._id,
          this.revisions[revisionIdx]._id,
          {
            subject: {
              text: this.revisionSubjectText,
              comment: this.revisions[this.revisions.length - 1].subject.comment
            },
            lines: this.lineList
          }
        )
        .then(res => {
          if (
            this.currentSoulLetter.recipient._id !== this.chosenCompanion._id
          ) {
            this.currentSoulLetter.recipient._id = this.chosenCompanion._id;
          }
          const payload = {
            recipient: this.currentSoulLetter.recipient._id,
            title: this.currentSoulLetter.title,
            comment: this.soulLetterComment
          };

          soulLettersApi
            .updateSoulLetter(this.currentSoulLetter._id, payload)
            .then(res => {
              this.loading = false;
              this.loadingError = false;
              // this.getAllRevisions();
              if (!isAutomaticUpdate) {
                addNotification(
                  "success",
                  this.$t("misc.success"),
                  this.$t("soulLetter.success")
                );
              }
              if (isCloseSoulLetter) {
                clearInterval(this.timerForRevisionUpdateIntervalCron);
                this.$refs["leaveSoulLetterModal"].hide();
                this.$emit("soulLetterSavedAndSent");
              }
              if (isSendSoulLetterModal) {
                this.getAllRevisions(isSendSoulLetterModal);
              }
            })
            .catch(err => {
              this.loadingError = true;
              this.loading = false;
              if (!isAutomaticUpdate) {
                addNotification(
                  "error",
                  this.$t("misc.error"),
                  this.$t("soulLetter.error.somethingWentWrong")
                );
              }
            });
        })
        .catch(err => {
          this.loadingError = true;
          this.loading = false;
          if (!isAutomaticUpdate) {
            addNotification(
              "error",
              this.$t("misc.error"),
              this.$t("soulLetter.error.somethingWentWrong")
            );
          }
        });
    },
    updateSoulLetter() {
      // Need for automatic 30 sec update
      //this.updateRevision(this.revisions.length - 1, false, false);
      this.loading = true;
      this.loadingError = false;
      revisionsApi
        .updateRevision(
          this.currentSoulLetter._id,
          this.revisions[this.revisions.length - 1]._id,
          {
            subject: {
              text: this.revisionSubjectText,
              comment: this.revisions[this.revisions.length - 1].subject.comment
            },
            lines: this.lineList
          }
        )
        .then(res => {
          // Need for send soulLetter Modal
          const payload = {
            recipient: this.currentSoulLetter.recipient._id,
            title: this.currentSoulLetter.title,
            comment: this.soulLetterComment
          };

          soulLettersApi
            .updateSoulLetter(this.currentSoulLetter._id, payload)
            .then(res => {
              this.loading = false;
              this.loadingError = false;
              soulLettersApi
                .submitSoulLetter(this.currentSoulLetter._id)
                .then(res => {
                  const soulLetter = res.data.data;
                  addNotification(
                    "success",
                    this.$t("misc.success"),
                    this.$t("soulLetter.success")
                  );
                  this.lineList = [];
                  clearInterval(this.timerForRevisionUpdateIntervalCron);
                  this.$emit("soulLetterSavedAndSent");
                  if (
                    soulLetter.latestRevision &&
                    soulLetter.latestRevision.transaction &&
                    soulLetter.latestRevision.transaction.buyUrl
                  ) {
                    window.location.replace(
                      soulLetter.latestRevision.transaction.buyUrl.url
                    );
                  }

                  //  this.setSoulLetterOnly(false);
                })
                .catch(err => {
                  if (err && err.error && err.error === "ReachedMaxRevisions") {
                    addNotification(
                      "error",
                      this.$t("misc.error"),
                      this.$t("soulLetter.error.reachedMaxRevisions")
                    );
                  } else {
                    addNotification(
                      "error",
                      this.$t("misc.error"),
                      this.$t("soulLetter.error.somethingWentWrong")
                    );
                  }
                });
            })
            .catch(err => {
              addNotification(
                "error",
                this.$t("misc.error"),
                this.$t("soulLetter.error.somethingWentWrong")
              );
            });
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    setChosenClientAndCompanion() {
      this.chosenCompanion = {
        _id: this.currentSoulLetter.recipient._id,
        givenName: this.currentSoulLetter.recipient.profile.givenName,
        familyName: this.currentSoulLetter.recipient.profile.familyName,
        name: this.currentSoulLetter.recipient.profile.name,
        profileImage: this.currentSoulLetter.recipient.profile.picture,
        role: this.currentSoulLetter.recipient.role
      };
      this.chosenClient = {
        _id: this.currentSoulLetter.sender._id,
        givenName: this.currentSoulLetter.sender.profile.givenName,
        familyName: this.currentSoulLetter.sender.profile.familyName,
        name: this.currentSoulLetter.sender.profile.name,
        profileImage: this.currentSoulLetter.sender.profile.picture,
        role: this.currentSoulLetter.sender.role
      };
    },
    canCurrentViewerEdit() {
      let returnValue;
      if (this.currentUser && (this.chosenCompanion || this.chosenClient)) {
        if (this.currentRevision && this.revisions.length > 0) {
          if (
            this.currentRevision._id !==
            this.revisions[this.revisions.length - 1]._id
          ) {
            returnValue = false;
            return;
          }
        }

        if (
          this.currentUser._id === this.chosenCompanion._id &&
          (this.currentSoulLetter.status === "draft" ||
            this.currentSoulLetter.status === "reviewed")
        ) {
          returnValue = false;
        } else if (
          this.currentUser._id === this.chosenCompanion._id &&
          this.currentSoulLetter.status === "submitted"
        ) {
          returnValue = true;
        } else if (
          this.currentUser._id === this.chosenClient._id &&
          (this.currentSoulLetter.status === "draft" ||
            this.currentSoulLetter.status === "reviewed")
        ) {
          returnValue = true;
        } else if (
          this.currentUser._id === this.chosenClient._id &&
          this.currentSoulLetter.status === "submitted"
        ) {
          returnValue = false;
        } else if (
          this.currentUser._id === this.chosenClient._id &&
          this.currentSoulLetter.status === "unpaid"
        ) {
          if (this.isNotPaidYetWriteMore) {
            returnValue = true;
          } else {
            returnValue = false;
          }
        }
      } else {
        returnValue = false;
      }

      return returnValue;
    },
    async getAllRevisions(isSendSoulLetterModal, isFirstMount) {
      this.loading = true;
      this.loadingMainContent = true;
      this.loadingErrorMainContent = false;
      this.loadingError = false;
      await revisionsApi
        .getAllRevisions(this.currentSoulLetter._id)
        .then(res => {
          const revisions = res.data.data;
          this.revisions = revisions;
          this.setRevisionVariables();
          this.constructLineList(isSendSoulLetterModal, isFirstMount);
          this.countCharacters();

          this.revisionOption = this.revisionOptions[
            this.revisionOptions.length - 1
          ];

          if (isSendSoulLetterModal) {
            this.$refs["sendSoulLetterModal"].show();
          }
          this.$emit("userAllowedToEditSoulLetter");
          this.loading = false;
          this.loadingMainContent = false;
          this.loadingError = false;
          this.loadingErrorMainContent = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingMainContent = false;
          this.loadingErrorMainContent = true;
          this.loadingError = true;
          this.$emit("userNotAllowedToEditSoulLetter");
        });
    },
    setQuillUndoRedoIcons() {
      const icons = Quill.import("ui/icons");
      icons["undo"] = `<svg viewbox="0 0 18 18">
        <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
        <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
      </svg>`;
      icons["redo"] = `<svg viewbox="0 0 18 18">
        <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
        <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
      </svg>`;
    }
  },
  created() {
    this.$nextTick(() => {
      this.quillDisabled = false;
    });
    this.setQuillUndoRedoIcons();
  },
  async mounted() {
    if (this.getCurrentUser) {
      this.loading = true;
      this.currentUser = this.getCurrentUser;
      this.loading = false;
    }
    if (this.currentSoulLetter) {
      this.setChosenClientAndCompanion();
      this.soulLetterComment = this.currentSoulLetter.comment;
      this.currentRevision = this.currentSoulLetter.latestRevision;
      await this.getAllRevisions(false, true);
      this.isStudentPackage =
        this.chosenClient.role === EUserRoles.CLIENT &&
        this.chosenCompanion.role === EUserRoles.STUDENT;
      this.hasReachedMaxRevisions =
        this.isStudentPackage &&
        this.revisions.length > 4 &&
        this.currentUser.role === EUserRoles.CLIENT;
    }

    if (this.isHistoryModal && this.currentSoulLetter) {
      this.loading = true;
      this.loadingError = false;
      this.soulLetterComment = this.currentSoulLetter.comment;
      revisionsApi
        .getRevision(
          this.currentSoulLetter._id,
          this.currentSoulLetter.latestRevision._id
        )
        .then(res => {
          const revision = res.data.data;
          this.currentRevision = revision;
          this.chosenCompanion = {
            _id: this.currentSoulLetter.recipient._id,
            givenName: this.currentSoulLetter.recipient.profile.givenName,
            familyName: this.currentSoulLetter.recipient.profile.familyName,
            name: this.currentSoulLetter.recipient.profile.name,
            profileImage: this.currentSoulLetter.recipient.profile.picture
          };
          this.chosenClient = this.currentClient;
          this.updateLineList(this.currentRevision);
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    }

    if (this.canCurrentViewerEdit()) {
      const intervalCron = 30000; // 30 sec
      this.timerForRevisionUpdateIntervalCron = setInterval(() => {
        // this.setCalculatedTotalChars();
        this.updateRevision(this.revisions.length - 1, false, true);
      }, intervalCron);
    }
    if (this.currentUser && this.currentSoulLetter) {
      if (
        this.currentSoulLetter.status === "unpaid" &&
        this.currentUser._id === this.chosenClient._id
      ) {
        this.openUnpaidStatusModal();
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timerForRevisionUpdateIntervalCron);
  }
};
</script>

<style scoped></style>
