import apiClient from "../../../../auth/apiClient";

export const getAllDefaultTimeSlots = userId => {
  return apiClient.get(`/users/${userId}/defaultTimeSlots`);
};

export const createDefaultTimeSlot = (userId, data) => {
  return apiClient.post(`/users/${userId}/defaultTimeSlots`, data);
};

export const getDefaultTimeSlot = (userId, defaultTimeSlotId) => {
  return apiClient.get(
    `/users/${userId}/defaultTimeSlots/${defaultTimeSlotId}`
  );
};

export const updateDefaultTimeSlot = (userId, defaultTimeSlotId, data) => {
  return apiClient.put(
    `/users/${userId}/defaultTimeSlots/${defaultTimeSlotId}`, data
  );
};

export const deleteDefaultTimeSlot = (userId, defaultTimeSlotId) => {
  return apiClient.delete(
    `/users/${userId}/defaultTimeSlots/${defaultTimeSlotId}`
  );
};
