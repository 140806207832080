var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.soulLetter.status === 'draft' || _vm.soulLetter.status === 'unpaid') &&
      _vm.currentUser
  )?_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.moment(_vm.soulLetter.latestRevision.updateTimeStamp).format("DD.MM.YYYY"))+" - "+_vm._s(_vm.moment(_vm.soulLetter.latestRevision.updateTimeStamp).format("HH:mm"))+" ")])]):(_vm.soulLetter.status === 'submitted' && _vm.currentUser)?_c('div',[(
      _vm.soulLetter.hasOwnProperty('recipient') &&
        _vm.soulLetter.hasOwnProperty('sender')
    )?_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.moment(_vm.soulLetter.latestRevision.createTimeStamp).format("DD.MM.YYYY"))+" - "+_vm._s(_vm.moment(_vm.soulLetter.latestRevision.createTimeStamp).format("HH:mm"))+" ")])]):_vm._e()]):(_vm.soulLetter.status === 'reviewed' && _vm.currentUser)?_c('div',[(
      _vm.soulLetter.hasOwnProperty('recipient') &&
        _vm.soulLetter.hasOwnProperty('sender')
    )?_c('div',[(
        _vm.soulLetter.latestRevision.createTimeStamp ===
          _vm.soulLetter.latestRevision.updateTimeStamp
      )?_c('span',[_vm._v(" "+_vm._s(_vm.moment(_vm.soulLetter.latestRevision.createTimeStamp).format("DD.MM.YYYY"))+" - "+_vm._s(_vm.moment(_vm.soulLetter.latestRevision.createTimeStamp).format("HH:mm"))+" ")]):(
        _vm.soulLetter.latestRevision.createTimeStamp !==
          _vm.soulLetter.latestRevision.updateTimeStamp
      )?_c('span',[_vm._v(" "+_vm._s(_vm.moment(_vm.soulLetter.latestRevision.updateTimeStamp).format("DD.MM.YYYY"))+" - "+_vm._s(_vm.moment(_vm.soulLetter.latestRevision.updateTimeStamp).format("HH:mm"))+" ")]):_vm._e()]):_vm._e()]):(_vm.soulLetter.status === 'completed')?_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.moment(_vm.soulLetter.latestRevision.createTimeStamp).format("DD.MM.YYYY"))+" - "+_vm._s(_vm.moment(_vm.soulLetter.latestRevision.createTimeStamp).format("HH:mm"))+" ")])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }