var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-wrapper sidebar-theme theme-border-and-shadow"},[_c('nav',{attrs:{"id":"sidebar"}},[_c('ul',{staticClass:"navbar-nav theme-brand flex-row text-center justify-content-center"},[_c('li',{staticClass:"nav-item theme-logo"},[_c('a',{attrs:{"href":"/"}},[_c('b-img',{staticClass:"navbar-logo",attrs:{"src":require("../../assets/img/logo-white.png"),"alt":"logo"}})],1)])]),(_vm.currentUser)?_c('ul',{staticClass:"list-unstyled menu-categories"},[_c('perfect-scrollbar',_vm._l((_vm.sidebarElements),function(el){return _c('li',{key:`${el.id}`,staticClass:"menu",class:{ active: _vm.selectedParentSidebarElement === el.to || _vm.$route.path.includes(el.to) || (_vm.$route.name === 'companions' && el.to === 'dashboard')}},[(
              el &&
                el.id !== 'conversations' &&
                el.id !== 'soulLetters' &&
                _vm.currentUser.role === _vm.EUserRoles.ADMINISTRATOR &&
                !_vm.getUserId
            )?_c('a',{staticClass:"dropdown-toggle",attrs:{"rel":"noopener noreferrer","target":"_blank"},on:{"click":function($event){return _vm.navigateTo(el.to)}}},[_c('b-img',{staticClass:"p-3 mr-3 bg-dark",class:el.icon}),_c('span',[_vm._v(_vm._s(el.title))])],1):(
              el &&
                el.id !== 'kingOfTheCastle' &&
                el.id !== 'loginAs' &&
                el.id !== 'vouchers' &&
                el.id !== 'unpaidConversations' &&
                _vm.currentUser.role !== _vm.EUserRoles.ADMINISTRATOR
            )?_c('a',{staticClass:"dropdown-toggle",attrs:{"rel":"noopener noreferrer","target":"_blank"},on:{"click":function($event){return _vm.navigateTo(el.to)}}},[_c('b-img',{staticClass:"p-3 mr-3 bg-dark",class:el.icon}),_c('span',[_vm._v(_vm._s(el.title))])],1):_vm._e()])}),0)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }