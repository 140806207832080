export default {
  namespaced: true,
  state: {
    userId:
      localStorage.getItem("userId") != null
        ? JSON.parse(localStorage.getItem("userId"))
        : null
  },
  mutations: {
    setUserId(state, payload) {
      state.userId = payload;
    }
  },
  getters: {
    getUserId(state) {
      return state.userId;
    }
  },
  actions: {
    setUserId({ commit, dispatch, state }, payload) {
      localStorage.setItem("userId", JSON.stringify(payload));
      commit("setUserId", payload);
      if (state.userId) {
        window.location.replace("/dashboard");
      } else {
        window.location.replace("login-as");
      }
    },
    clearUserId({ commit, dispatch, state }, payload) {
      localStorage.removeItem("userId");
      commit("setUserId", null);
    }
  }
};
