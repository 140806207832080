import * as calendarApi from "../calendarModule/calendar/celandar.api";
//import * as defaultSlotApi from "../calendarModule/defaultSlot/defaultSlot.api";
import * as permissionApi from "../calendarModule/permission/permission.api";
//import * as slotApi from "../calendarModule/slot/slot.api";

import * as defaultSlotApi from "../users/defaultTimeSlots/defaultTimeSlots.api";
import * as slotApi from "../users/timeSlots/timeSlots.api";

export const calendar = calendarApi;
export const defaultSlot = defaultSlotApi;
export const permission = permissionApi;
export const slot = slotApi;
