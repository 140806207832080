<template>
  <div>
    <table
      v-if="
        !isMobileScreenSize &&
          !isModalMobileScreenSize &&
          (!isTabletScreenSize || !isSidebarOpen)
      "
      class="table table-bordered table-striped mb-4"
    >
      <thead>
        <tr>
          <th>
            <b-form-checkbox
              id="voucherCheckboxHead"
              v-model="checkAllVouchers"
              @change="emitAddOrSpliceAllChosenVouchersToList()"
            />
          </th>
          <th>{{ $t("vouchers.table.headlines.code") }}</th>
          <th>{{ $t("vouchers.table.headlines.type") }}</th>
          <th>{{ $t("vouchers.table.headlines.value") }}</th>
          <th class="text-center">
            {{ $t("vouchers.table.headlines.method") }}
          </th>
          <th>{{ $t("vouchers.table.headlines.validFromTo") }}</th>
          <th>{{ $t("vouchers.table.headlines.createdAt") }}</th>
          <th class="text-center">
            {{ $t("vouchers.table.headlines.active") }}
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(voucher, vIdx) in sortedVouchers" :key="vIdx">
          <td>
            <b-form-checkbox
              v-if="voucher"
              :id="'voucherCheckbox' + vIdx"
              v-model="checkedVouchers[parseInt(vIdx, 10)]"
              @change="
                emitAddOrSpliceChosenVouchersToList(voucher, vIdx, $event)
              "
            />
          </td>
          <td>
            <span>{{ voucher.code }}</span>
          </td>
          <td>
            <span v-if="voucher.type === EVoucherTypes.CONVERSATIONS">{{
              $t("vouchers.voucherType.conversations")
            }}</span>
            <span v-else-if="voucher.type === EVoucherTypes.LETTERS">{{
              $t("vouchers.voucherType.soulLetters")
            }}</span>
            <span v-else-if="voucher.type === EVoucherTypes.STUDENTS">{{
              $t("vouchers.voucherType.students")
            }}</span>
            <span v-else-if="voucher.type === EVoucherTypes.ALL">{{
              $t("vouchers.voucherType.all")
            }}</span>
          </td>
          <td>
            <span>{{ voucher.value }}</span>
          </td>
          <td class="font-weight-bold color-warning font-size-big text-center">
            <span v-if="voucher.method === EVoucherMethods.PERCENTAGE">%</span>
            <span v-else>€</span>
          </td>
          <td>
            <span
              >{{ moment(voucher.validFrom).format("DD.MM.YYYY") }} -
              {{ moment(voucher.validUntil).format("DD.MM.YYYY") }}</span
            >
          </td>
          <td>
            <span>{{
              moment(voucher.createTimeStamp).format("DD.MM.YYYY")
            }}</span>
          </td>
          <td class="text-center">
            <b-form-checkbox
              @change="emitUpdateVoucher(voucher)"
              size="lg"
              v-model="voucher.isActive"
              name="check-button"
              switch
            />
          </td>
          <td class="text-center">
            <img
              class="feathericons-edit-3 p-3 bg-primary btn mr-3"
              @click="emitEditVoucher(voucher)"
            />
            <img
              class="feathericons-trash p-3 bg-danger btn"
              @click="emitDeleteVoucher(voucher)"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table v-else class="table style-3  table-hover mb-4">
      <thead>
        <tr>
          <th>{{ $t("vouchers.title") }}</th>
        </tr>
        <tr>
          <th>
            <b-form-checkbox
              v-if="sortedVouchers.length > 0"
              id="voucherCheckboxHead"
              v-model="checkAllVouchers"
              @change="emitAddOrSpliceAllChosenVouchersToList()"
            >
              <span class="font-size-small font-weight-regular">
                {{ $t("vouchers.markAllVouchers") }}
              </span>
            </b-form-checkbox>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="tr-border-top-grey"
          v-for="(voucher, vIdx) in sortedVouchers"
          :key="vIdx"
        >
          <td>
            <table>
              <tr>
                <td
                  class="border-0 font-weight-bold td-table-mobile td-first-col-mobile"
                >
                  <span>{{ $t("vouchers.table.headlines.code") }}: </span>
                </td>
                <td class="border-0 td-table-mobile td-first-col-mobile">
                  <span>{{ voucher.code }}</span>
                </td>
              </tr>
              <tr>
                <td class="border-0 font-weight-bold td-table-mobile">
                  <span>{{ $t("vouchers.table.headlines.type") }}: </span>
                </td>
                <td class="border-0 td-table-mobile">
                  <span v-if="voucher.type === EVoucherTypes.CONVERSATIONS">{{
                    $t("vouchers.voucherType.conversations")
                  }}</span>
                  <span v-else-if="voucher.type === EVoucherTypes.LETTERS">{{
                    $t("vouchers.voucherType.soulLetters")
                  }}</span>
                  <span v-else-if="voucher.type === EVoucherTypes.STUDENTS">{{
                    $t("vouchers.voucherType.students")
                  }}</span>
                  <span v-else-if="voucher.type === EVoucherTypes.ALL">{{
                    $t("vouchers.voucherType.all")
                  }}</span>
                </td>
              </tr>
              <tr>
                <td class="border-0 font-weight-bold td-table-mobile">
                  <span>{{ $t("vouchers.table.headlines.value") }}: </span>
                </td>
                <td class="border-0 td-table-mobile">
                  <span>{{ voucher.value }}</span>
                </td>
              </tr>
              <tr>
                <td class="border-0 font-weight-bold td-table-mobile">
                  <span>{{ $t("vouchers.table.headlines.method") }}: </span>
                </td>
                <td
                  class="border-0 td-table-mobile font-weight-bold color-warning font-size-big"
                >
                  <span v-if="voucher.method === EVoucherMethods.PERCENTAGE"
                    >%</span
                  >
                  <span v-else>€</span>
                </td>
              </tr>
              <tr>
                <td class="border-0 font-weight-bold td-table-mobile">
                  <span
                    >{{ $t("vouchers.table.headlines.validFromTo") }}:
                  </span>
                </td>
                <td class="border-0 td-table-mobile">
                  <span>
                    {{ moment(voucher.validFrom).format("DD.MM.YYYY") }} -
                    {{ moment(voucher.validUntil).format("DD.MM.YYYY") }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="border-0 font-weight-bold td-table-mobile">
                  <span>{{ $t("vouchers.table.headlines.createdAt") }}: </span>
                </td>
                <td class="border-0 td-table-mobile">
                  <span>{{
                    moment(voucher.createTimeStamp).format("DD.MM.YYYY")
                  }}</span>
                </td>
              </tr>
              <tr>
                <td class="border-0 font-weight-bold td-table-mobile">
                  <span>{{ $t("vouchers.table.headlines.active") }}: </span>
                </td>
                <td
                  class="border-0 td-table-mobile font-weight-bold color-warning font-size-big"
                >
                  <b-form-checkbox
                    @change="emitUpdateVoucher(voucher)"
                    size="lg"
                    v-model="voucher.isActive"
                    name="check-button"
                    switch
                  />
                </td>
              </tr>
              <tr>
                <td class="border-0 font-weight-bold td-table-mobile">
                  <b-form-checkbox
                    :id="'voucherCheckbox' + vIdx"
                    v-model="checkedVouchers[parseInt(vIdx, 10)]"
                    @change="emitAddOrSpliceChosenVouchersToList(voucher, vIdx, $event)"
                  >
                    <span>
                      {{ $t("vouchers.markVoucher") }}
                    </span>
                  </b-form-checkbox>
                </td>
              </tr>
            </table>

            <div
              class="text-center ml-auto mr-auto pt-4 pl-3 pr-3"
              style="width: 100%;"
            >
              <button
                @click="emitEditVoucher(voucher)"
                class="btn btn-outline-primary w-100 ml-auto mr-auto"
              >
                {{ $t("vouchers.editVoucher") }}
              </button>
            </div>
            <div
              class="text-center ml-auto mr-auto pt-4 pl-3 pr-3 mb-3"
              style="width: 100%;"
            >
              <button
                @click="emitDeleteVoucher(voucher)"
                class="btn btn-outline-danger w-100 ml-auto mr-auto"
              >
                {{ $t("vouchers.editVoucher") }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import screenSizeMixin from "@/mixins/screenSize";
import * as moment from "moment";
import { mapGetters } from "vuex";
import { EVoucherMethods } from "@/store/modules/voucher/EVoucherMethods";
import { EVoucherTypes } from "@/store/modules/voucher/EVoucherTypes";

export default {
  name: "VouchersTable",
  mixins: [screenSizeMixin],
  props: {
    sortedVouchers: Array,
    editVoucher: Function,
    updateVoucher: Function,
    deleteVoucher: Function,
    addOrSpliceChosenVouchersToList: Function
  },
  data() {
    return {
      EVoucherMethods,
      EVoucherTypes,
      moment,
      isActive: false,
      chosenVouchers: [],
      checkedVouchers: [],
      checkAllVouchers: false,
      uncheckAllVouchers: false
    };
  },
  computed: {
    ...mapGetters("sideBar",["isSidebarOpen"])
  },
  methods: {
    initChecks() {
      this.checkedVouchers = [];
      this.sortedVouchers.forEach(voucher => {
        this.checkedVouchers.push(false);
      });
    },
    setChecks() {
      for(let i = 0; i < this.checkedVouchers.length; i++){
        this.checkedVouchers[i] = true;
      }
    },
    unsetChecks() {
      for(let i = 0; i < this.checkedVouchers.length; i++){
        this.checkedVouchers[i] = false;
      }
    },
    emitAddOrSpliceAllChosenVouchersToList() {
      if (this.checkAllVouchers) {
        this.chosenVouchers = [];
        this.chosenVouchers = JSON.parse(JSON.stringify(this.sortedVouchers));
        this.setChecks();
        this.checkedVouchers = JSON.parse(JSON.stringify(this.checkedVouchers));
      } else {
        this.chosenVouchers = [];
        this.unsetChecks();
        this.checkedVouchers = JSON.parse(JSON.stringify(this.checkedVouchers));
      }
      this.addOrSpliceChosenVouchersToList(this.chosenVouchers);
    },
    emitAddOrSpliceChosenVouchersToList(voucher, vIdx, event) {
      const hasVoucherListEntries = this.chosenVouchers.find(
        v => v._id === voucher._id
      );
      if (hasVoucherListEntries) {
        const index = this.chosenVouchers
          .map(function(v) {
            return v._id;
          })
          .indexOf(voucher._id);

        this.chosenVouchers.splice(index, 1);
        this.checkedVouchers[parseInt(vIdx, 10)] = false;

      } else {
        this.checkedVouchers[parseInt(vIdx, 10)] = true;
        this.chosenVouchers.push(voucher);
      }
      this.addOrSpliceChosenVouchersToList(this.chosenVouchers);
    },
    emitEditVoucher(voucher) {
      this.editVoucher(voucher);
    },
    emitUpdateVoucher(voucher) {
      this.updateVoucher(voucher);
    },
    emitDeleteVoucher(voucher) {
      this.deleteVoucher(voucher);
    }
  },
  mounted() {
    this.initChecks();
  }
};
</script>

<style scoped></style>
