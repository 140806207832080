<template>
  <div v-if="currentUser.role === EUserRoles.CLIENT">
    <span v-if="soulLetter.recipient">
      {{ displayUsernameToEmail(soulLetter.recipient.profile) }}
    </span>
  </div>
  <div
    v-else-if="
      currentUser.role === EUserRoles.COMPANION ||
        currentUser.role === EUserRoles.STUDENT ||
        currentUser.role === EUserRoles.ADMINISTRATOR
    "
  >
    <span v-if="soulLetter.sender">
      {{ displayUsernameToEmail(soulLetter.sender.profile) }}
    </span>
  </div>
</template>

<script>
import { displayUsernameToEmail } from "../../../utils/userHelper";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import * as ESoulLetterStatus from "@/store/modules/soulLetters/ESoulLetterStatus";

export default {
  name: "ClientColumn",
  props: {
    currentUser: Object,
    soulLetter: Object
  },
  data() {
    return {
      displayUsernameToEmail,
      EUserRoles,
      ESoulLetterStatus
    };
  }
};
</script>

<style scoped></style>
