<template>
  <div>
    <table
      v-if="!isMobileScreenSize &&
          !isModalMobileScreenSize && (!isTabletScreenSize || !isSidebarOpen)"
      id="style-3"
      class="table style-3  table-hover"
    >
      <thead>
        <tr>
          <th class="text-center">
            {{ $t("soulLetters.tableHeadlines.status") }}
          </th>
          <th class="text-center">
            {{ $t("soulLetters.tableHeadlines.date") }}
          </th>
          <th>
            {{ $t("soulLetters.tableHeadlines.project") }}
          </th>
          <th v-if="currentUser">
            <span
              v-if="
                currentUser.role !== EUserRoles.COMPANION &&
                  currentUser.role !== EUserRoles.ADMINISTRATOR
              "
            >
              {{ $t("soulLetters.tableHeadlines.companion") }}
            </span>
            <span
              v-else-if="
                currentUser.role === EUserRoles.COMPANION ||
                  currentUser.role === EUserRoles.ADMINISTRATOR
              "
            >
              {{ $t("soulLetters.tableHeadlines.client") }}
            </span>
          </th>
          <th class="text-center" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(soulLetter, Idx) in soulLetters" :key="Idx">
          <td class="text-center">
            <StatusColumn
              :current-user="currentUser"
              :soul-letter="soulLetter"
            />
          </td>
          <td class="text-center table-column-min-width-160px">
            <DateColumn :current-user="currentUser" :soul-letter="soulLetter" />
          </td>
          <td>
            <span
              class="break-word"
              :class="
                soulLetter.title.length > 150
                  ? 'restrict-paragraph-two-lines'
                  : ''
              "
              v-if="soulLetter.title"
            >
              {{ soulLetter.title }}
            </span>
            <div
              v-if="soulLetter.title.length > 150 && (isTabletScreenSize || isSmDesktopScreenSize)"
              class="text-center"
            >
              <img
                class="feathericons-plus-circle bg-primary p-3 cursor-pointer"
                @click.prevent="openShowUserCommentModal(soulLetter.title)"
              />
              <span
                class="color-primary cursor-pointer"
                @click.prevent="openShowUserCommentModal(soulLetter.title)"
              >
                {{ $t("actions.showUserComment") }}
              </span>
            </div>
          </td>
          <td v-if="currentUser">
            <ClientColumn
              :soul-letter="soulLetter"
              :current-user="currentUser"
            />
          </td>
          <td class="text-center">
            <ButtonsColumn
              :current-user="currentUser"
              :soul-letter="soulLetter"
              :emit-update-soul-letter="emitUpdateSoulLetter"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <table v-else id="style-2" class="table style-3  table-hover">
      <thead>
        <tr>
          <th class="th-table-mobile">
            {{ $t("soulLetters.altTitle") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="tr-border-top-grey"
          v-for="(soulLetter, Idx) in soulLetters"
          :key="Idx"
        >
          <table style="width: 100%;">
            <tr>
              <td
                class="border-0 font-weight-bold td-table-mobile td-first-col-mobile w-100px"
              >
                <span>{{ $t("soulLetters.tableHeadlines.status") }}: </span>
              </td>
              <td class="border-0 td-table-mobile td-first-col-mobile">
                <StatusColumn
                  :current-user="currentUser"
                  :soul-letter="soulLetter"
                />
              </td>
            </tr>
            <tr>
              <td class="border-0 font-weight-bold td-table-mobile w-100px">
                <span>{{ $t("soulLetters.tableHeadlines.date") }}: </span>
              </td>
              <td class="border-0 td-table-mobile">
                <DateColumn
                  :current-user="currentUser"
                  :soul-letter="soulLetter"
                />
              </td>
            </tr>
            <tr>
              <td class="border-0 font-weight-bold td-table-mobile w-100px">
                <span>{{ $t("soulLetters.tableHeadlines.project") }}: </span>
              </td>
              <td class="border-0 td-table-mobile">
                <span
                  class="break-word"
                  :class="
                    soulLetter.title.length > 150
                      ? 'restrict-paragraph-two-lines'
                      : ''
                  "
                  v-if="soulLetter.title"
                >
                  {{ soulLetter.title }}
                </span>
                <div v-if="soulLetter.title.length > 150">
                  <img
                    class="feathericons-plus-circle bg-primary p-3 cursor-pointer"
                    @click.prevent="openShowUserCommentModal(soulLetter.title)"
                  />
                  <span
                    class="color-primary cursor-pointer"
                    @click.prevent="openShowUserCommentModal(soulLetter.title)"
                  >
                    {{ $t("actions.showUserComment") }}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td class="border-0 font-weight-bold td-table-mobile w-100px">
                <span
                  v-if="
                    currentUser.role !== EUserRoles.COMPANION &&
                      currentUser.role !== EUserRoles.ADMINISTRATOR
                  "
                >
                  {{ $t("soulLetters.tableHeadlines.companion") }}:
                </span>
                <span
                  v-else-if="
                    currentUser.role === EUserRoles.COMPANION ||
                      currentUser.role === EUserRoles.ADMINISTRATOR
                  "
                >
                  {{ $t("soulLetters.tableHeadlines.client") }}:
                </span>
              </td>
              <td class="border-0 td-table-mobile ">
                <ClientColumn
                  :soul-letter="soulLetter"
                  :current-user="currentUser"
                />
              </td>
            </tr>
          </table>

          <div
            class="text-center ml-auto mr-auto pb-4 pt-4 pl-3 pr-3"
            style="width: 100%;"
          >
            <ButtonsColumn
              :current-user="currentUser"
              :soul-letter="soulLetter"
              :emit-update-soul-letter="emitUpdateSoulLetter"
            />
          </div>
        </tr>
      </tbody>
    </table>
    <!-- Show Comment Modal -->
    <b-modal
      ref="showUserCommentModal"
      size="lg"
      :title="$t('modals.showUserCommentModal.title')"
      hide-footer
      @hide="closeShowUserCommentModal()"
    >
      <b-row>
        <b-col>
          <p v-if="userComment" class="break-word">
            {{ userComment }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <button class="btn btn-outline-primary"
          @click.prevent="closeShowUserCommentModal()">
            {{$t("misc.close")}}
          </button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import * as moment from "moment";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import * as ESoulLetterStatus from "@/store/modules/soulLetters/ESoulLetterStatus";
import { displayUsernameToEmail } from "../../utils/userHelper";
import StatusColumn from "@/components/soulLetters/SoulLettersTableColumns/StatusColumn";
import DateColumn from "@/components/soulLetters/SoulLettersTableColumns/DateColumn";
import ClientColumn from "@/components/soulLetters/SoulLettersTableColumns/ClientColumn";
import ButtonsColumn from "@/components/soulLetters/SoulLettersTableColumns/ButtonsColumn";
import {mapGetters} from "vuex";

export default {
  name: "SoulLettersTable",
  components: { ButtonsColumn, ClientColumn, DateColumn, StatusColumn },
  props: {
    currentUser: Object,
    soulLetters: Array,
    isMobileScreenSize: Boolean,
    isModalMobileScreenSize: Boolean,
    isTabletScreenSize: Boolean,
    isSmDesktopScreenSize: Boolean,
    updateSoulLetter: Function
  },
  data() {
    return {
      displayUsernameToEmail,
      EUserRoles,
      ESoulLetterStatus,
      moment,
      userComment: null,
      unpaidInterval: 120000 // 5000 = 5sec / 120000 = 2min
    };
  },
  computed:{
    ...mapGetters("sideBar",["isSidebarOpen"]),
  },
  methods: {
    openShowUserCommentModal(userComment) {
      this.userComment = userComment;
      this.$refs["showUserCommentModal"].show();
    },
    closeShowUserCommentModal() {
      this.userComment = null;
      this.$refs["showUserCommentModal"].hide();
    },
    emitUpdateSoulLetter(soulLetter, isUnpaidStatus) {
      this.updateSoulLetter(soulLetter, isUnpaidStatus);
    },
    isUnpaidButtonTimeout(soulLetter) {
      const timeStampDiff = moment(soulLetter.updateTimeStamp).diff(moment());
      if (timeStampDiff > -this.unpaidInterval) {
        // 2min
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped></style>
