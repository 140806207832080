import apiClient from "../../../auth/apiClient";

export const getAllSoulLetters = (data) => {
  return apiClient.get(`/letters`,data);
};

export const createSoulLetter = data => {
  return apiClient.post(`/letters`, data);
};

export const getSoulLetter = letter => {
  return apiClient.get(`/letters/${letter}`);
};

export const updateSoulLetter = (letter, data) => {
  return apiClient.put(`/letters/${letter}`, data);
};

export const submitSoulLetter = (letter) => {
  return apiClient.post(`/letters/${letter}/submit`);
};

export const cancelSoulLetterTransaction = (letter) => {
  return apiClient.delete(`/letters/${letter}/submit`);
};

export const deleteSoulLetter = letter => {
  return apiClient.delete(`/letters/${letter}`);
};
