var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.soulLetter.status === 'draft' && _vm.currentUser)?_c('div',[(
      _vm.soulLetter.hasOwnProperty('recipient') &&
        _vm.soulLetter.hasOwnProperty('sender')
    )?_c('div',[(
        _vm.currentUser._id === _vm.soulLetter.recipient._id ||
          _vm.currentUser.role === _vm.EUserRoles.ADMINISTRATOR
      )?_c('button',{staticClass:"btn btn-outline-primary btn-width-100p ml-auto mr-auto",class:_vm.isTabletScreenSize || _vm.isMobileScreenSize ?'w-100':'',on:{"click":function($event){return _vm.updateSoulLetter(_vm.soulLetter)}}},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.statusButtons.waitForEntry"))+" ")]):(_vm.currentUser._id === _vm.soulLetter.sender._id)?_c('button',{staticClass:"btn btn-outline-primary btn-width-100p ml-auto mr-auto",class:_vm.isTabletScreenSize || _vm.isMobileScreenSize ?'w-100':'',on:{"click":function($event){return _vm.updateSoulLetter(_vm.soulLetter)}}},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.statusButtons.continueWriting"))+" ")]):_vm._e()]):_c('div',[(_vm.currentUser._id === _vm.soulLetter.sender._id)?_c('button',{staticClass:"btn btn-outline-primary btn-width-100p ml-auto mr-auto",class:_vm.isTabletScreenSize || _vm.isMobileScreenSize ?'w-100':'',on:{"click":function($event){return _vm.updateSoulLetter(_vm.soulLetter)}}},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.statusButtons.continueWriting"))+" ")]):_vm._e()])]):(_vm.soulLetter.status === 'unpaid' && _vm.currentUser)?_c('div',[(
      _vm.soulLetter.hasOwnProperty('recipient') &&
        _vm.soulLetter.hasOwnProperty('sender')
    )?_c('div',[(
        _vm.currentUser._id === _vm.soulLetter.recipient._id ||
          _vm.currentUser.role === _vm.EUserRoles.ADMINISTRATOR
      )?_c('button',{staticClass:"btn btn-outline-success btn-width-100p ml-auto mr-auto",class:_vm.isTabletScreenSize || _vm.isMobileScreenSize ?'w-100':'',on:{"click":function($event){$event.preventDefault();return _vm.updateSoulLetter(_vm.soulLetter)}}},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.statusButtons.waitForEntry"))+" ")]):(_vm.currentUser._id === _vm.soulLetter.sender._id)?_c('div',[(_vm.soulLetter.latestRevision.hasOwnProperty('transaction'))?_c('div',[_c('button',{staticClass:"btn btn-outline-danger btn-width-100p ml-auto mr-auto",class:_vm.isTabletScreenSize || _vm.isMobileScreenSize ?'w-100':'',attrs:{"disabled":_vm.isUnpaidButtonTimeout(_vm.soulLetter)},on:{"click":function($event){$event.preventDefault();return _vm.updateSoulLetter(_vm.soulLetter, true)}}},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.statusButtons.payOrWriteMore"))+" ")])]):_vm._e()]):_vm._e()]):_vm._e()]):(_vm.soulLetter.status === 'submitted' && _vm.currentUser)?_c('div',[(
      _vm.soulLetter.hasOwnProperty('recipient') &&
        _vm.soulLetter.hasOwnProperty('sender')
    )?_c('div',[(
        _vm.currentUser._id === _vm.soulLetter.recipient._id ||
          _vm.currentUser.role === _vm.EUserRoles.ADMINISTRATOR
      )?_c('button',{staticClass:"btn btn-outline-warning btn-width-100p ml-auto mr-auto",class:_vm.isTabletScreenSize || _vm.isMobileScreenSize ?'w-100':'',on:{"click":function($event){return _vm.updateSoulLetter(_vm.soulLetter)}}},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.statusButtons.toCorrect"))+" ")]):_vm._e(),(_vm.currentUser._id === _vm.soulLetter.sender._id)?_c('button',{staticClass:"btn btn-outline-warning btn-width-100p ml-auto mr-auto",class:_vm.isTabletScreenSize || _vm.isMobileScreenSize ?'w-100':'',on:{"click":function($event){return _vm.updateSoulLetter(_vm.soulLetter)}}},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.statusButtons.waitForFeedback"))+" ")]):_vm._e()]):_vm._e()]):(_vm.soulLetter.status === 'reviewed' && _vm.currentUser)?_c('div',[(
      _vm.soulLetter.hasOwnProperty('recipient') &&
        _vm.soulLetter.hasOwnProperty('sender')
    )?_c('div',[(
        _vm.currentUser._id === _vm.soulLetter.recipient._id ||
          _vm.currentUser.role === _vm.EUserRoles.ADMINISTRATOR
      )?_c('button',{staticClass:"btn btn-outline-success btn-width-100p ml-auto mr-auto",class:_vm.isTabletScreenSize || _vm.isMobileScreenSize ?'w-100':'',on:{"click":function($event){return _vm.updateSoulLetter(_vm.soulLetter)}}},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.statusButtons.waitForFeedback"))+" ")]):_vm._e(),(_vm.currentUser._id === _vm.soulLetter.sender._id)?_c('button',{staticClass:"btn btn-outline-success btn-width-100p ml-auto mr-auto",class:_vm.isTabletScreenSize || _vm.isMobileScreenSize ?'w-100':'',on:{"click":function($event){return _vm.updateSoulLetter(_vm.soulLetter)}}},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.statusButtons.continueWriting"))+" ")]):_vm._e()]):_vm._e()]):(_vm.soulLetter.status === 'completed' && _vm.currentUser)?_c('div',[(
      _vm.soulLetter.hasOwnProperty('recipient') &&
        _vm.soulLetter.hasOwnProperty('sender')
    )?_c('div',[(
        _vm.currentUser._id === _vm.soulLetter.recipient._id ||
          _vm.currentUser.role === _vm.EUserRoles.ADMINISTRATOR
      )?_c('button',{staticClass:"btn btn-outline-success btn-width-100p ml-auto mr-auto",class:_vm.isTabletScreenSize || _vm.isMobileScreenSize ?'w-100':'',on:{"click":function($event){return _vm.updateSoulLetter(_vm.soulLetter)}}},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.statusButtons.waitForFeedback"))+" ")]):_vm._e(),(_vm.currentUser._id === _vm.soulLetter.sender._id)?_c('button',{staticClass:"btn btn-outline-success btn-width-100p ml-auto mr-auto",class:_vm.isTabletScreenSize || _vm.isMobileScreenSize ?'w-100':'',on:{"click":function($event){return _vm.updateSoulLetter(_vm.soulLetter)}}},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.statusButtons.continueWriting"))+" ")]):_vm._e()]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }