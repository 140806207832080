import { AppConfig } from "@/config/config";
import developmentConfig from "@/config/environments/config.development";

const config: AppConfig = {
  ...developmentConfig,
  auth0: {
    ...developmentConfig.auth0
  },
  sentry: {
    enabled: true,
    dsn: "https://bb95b45a6014442ab00d657afc9ccb19@sentry.iamds.com/36"
  },
  backendUrl: "https://staging-api.clemenskuby.com/",
  frontendUrl: "https://staging-portal.clemenskuby.com/",
  calendarModuleTimeZone: "Europe/Berlin",
  attendeeCancelPeriod: 86400
};

export default config;
