export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0
      }
    };
  },
  computed: {
    isModalMobileScreenSize(){
      if (this.window.width < 1000) {
        return true;
      } else {
        return false;
      }
    },
    isSmDesktopScreenSize(){
      if (this.window.width < 1450 && this.window.width >= 1350) {
        return true;
      } else {
        return false;
      }
    },
    isTabletScreenSize() {
      if (this.window.width < 1350 && this.window.width >= 768) {
        return true;
      } else {
        return false;
      }
    },
    isMobileScreenSize() {
      if (this.window.width < 768) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    expandHeader() {
      this.toggleSidebar();
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }
};
