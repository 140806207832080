<template>
  <div class="header-container fixed-top">
    <header
      class="header navbar navbar-expand-sm flex-row d-flex"
      :class="{ 'expand-header': !isSidebarOpen }"
      ref="header"
    >
      <a @click="expandHeader" href="#" class="sidebarCollapse">
        <b-img class="feathericons-menu p-3 bg-dark" />
      </a>

      <button
        v-if="!isMobileScreenSize"
        @click="navigateTo('https://clemenskuby.com')"
        class="btn btn-light-success flex-row ml-4"
      >
        {{ $t("header.backToHomepage") }}
      </button>

      <EnvironmentBadge />
      <div v-if="!isMobileScreenSize"
          class="ml-auto mr-auto pl-2 pr-2">
        <button
          v-if="getUserId"
          @click="setImpersonateUserId(null)"
          class="btn btn-outline-primary"
        >
          {{ $t("loginAs.buttons.logoutToOwnUser") }}
        </button>

        <span v-if="getUserId" class="mr-2 ml-3">
          {{ $t("loginAs.loggedInAs") }}:
        </span>
        <span v-if="getUserId && adminAsUserProfile" class="font-weight-bold">
          {{ adminAsUserProfile.name }}
        </span>
      </div>

      <ul class="navbar-item flex-row ml-auto">
        <li class="nav-item dropdown user-profile-dropdown order-lg-0 order-1">
          <b-nav-item-dropdown>
            <template class="dropdown-menu" #button-content>
              <div v-if="currentUser" class="d-flex ml-auto">
                <div
                  class="d-flex flex-column justify-content-center mr-1 header-profile-image"
                >
                  <img
                      v-if="currentUser.role !== EUserRoles.CLIENT"
                    :src="currentUser.profile.picture"
                    class="img-fluid mr-2 rounded shadow-sm max-width-profile-picture-small"
                    alt="avatar"
                  />
                </div>
                <div class="d-flex flex-column justify-content-center mt-2">
                  <h6 class="font-weight-bold">
                    {{ currentUser.profile.name }}
                  </h6>
                </div>
                <div>
                  <img
                    class="feathericons-chevron-down p-3 bg-dark ml-2 mt-1"
                  />
                </div>
              </div>
            </template>
            <UserProfileDropdown />
          </b-nav-item-dropdown>
        </li>
      </ul>
    </header>
  </div>
</template>

<script>
import EnvironmentBadge from "@/components/utilities/EnvironmentBadge";
import UserProfileDropdown from "@/components/utilities/UserProfileDropdown";
import { mapActions, mapGetters } from "vuex";
import screenSizeMixin from "../../mixins/screenSize";
import * as userApi from "@/store/modules/users/users.api";
import { EUserRoles } from "@/store/modules/users/EUserRoles";


export default {
  name: "Header",
  mixins: [screenSizeMixin],
  props: {},
  data() {
    return {
      EUserRoles,
      adminAsUserProfile: null,
      currentUser: null
    };
  },
  components: { UserProfileDropdown, EnvironmentBadge },
  computed: {
    ...mapGetters("sideBar", ["isSidebarOpen"]),
    ...mapGetters("loginAs", ["getUserId"]),
    ...mapGetters("users", ["getCurrentUser"])
  },
  methods: {
    ...mapActions("sideBar",["toggleSidebar"]),
    ...mapActions("loginAs",["setUserId"]),
    setImpersonateUserId(userId) {
      this.setUserId(userId);
    },
    expandHeader() {
      this.toggleSidebar();
    },
    navigateTo(destination) {
      window.location.replace(destination);
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    if (this.getUserId) {
      userApi.getUser(this.getUserId).then(res => {
        const user = res.data.data;
        this.adminAsUserProfile = user.profile;
      });
    }
  }
};
</script>

<style scoped></style>
