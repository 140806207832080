import apiClient from "../../../../auth/apiClient";

export const search = payload => {
  return apiClient.post("/private/calendarModule/calendars/search", payload);
};
export const add = payload => {
  return apiClient.post("/private/calendarModule/calendars", payload);
};

export const get = _id => {
  return apiClient.get(`/private/calendarModule/calendars/${_id}`);
};

export const update = (_id, payload) => {
  delete payload._id;
  delete payload.ownerId;
  delete payload.creationTimeStamp;
  delete payload.updateTimeStamp;
  delete payload.__v;
  return apiClient.put(`/private/calendarModule/calendars/${_id}`, payload);
};

export const remove = _id => {
  return apiClient.delete(`/private/calendarModule/calendars/${_id}`);
};
