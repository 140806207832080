<template>
  <div>
    <!-- Conversation Section -->
    <b-row v-if="historyConversations.length" class="text-center">
      <b-col>
        <h5>{{ $t("soulLetter.conversations") }}</h5>
      </b-col>
    </b-row>
    <b-row v-if="historyConversations" class="mt-3 text-center">
      <b-col v-if="historyConversations.length > 0">
        {{ $t("soulLetter.guidedConversations") }}:
        {{ historyConversations.length }} <br />
      </b-col>
    </b-row>
    <b-row v-if="historyConversations">
      <b-col v-if="historyConversations.length > 0">
        <div class="table-responsive mt-5">
          <perfect-scrollbar>
            <table
              v-if="!isMobileScreenSize || !isModalMobileScreenSize"
              id="style-2"
              class="table style-3  table-hover"
            >
              <thead>
                <tr>
                  <th>
                    {{ $t("soulLetter.tableSlDate") }}
                  </th>
                  <th>
                    {{ $t("conversations.comment") }}
                  </th>
                  <th>
                    {{ $t("soulLetter.tableSlCompanion") }}
                  </th>
                  <th>
                    {{ $t("conversations.duration") }}
                  </th>
                  <th>
                    {{ $t("conversations.history") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(conversation, cIdx) in historyConversations"
                  :key="cIdx"
                >
                  <td class="table-column-min-width-160px">
                    <span
                      >{{
                        moment(conversation.appointment.date).format(
                          "DD.MM.YYYY"
                        )
                      }}

                      - {{ conversation.appointment.time }}</span
                    >
                  </td>
                  <td>
                    <p
                      class="break-word"
                      :class="isTabletScreenSize&&
                        conversation.subject.length > 70
                          ? 'restrict-paragraph-two-lines'
                          : ''
                      "
                    >
                      {{ conversation.subject }}
                    </p>
                    <div
                      v-if="isTabletScreenSize &&
                       conversation.subject.length > 70
                      "
                      class="text-center"
                    >
                      <img
                        class="feathericons-plus-circle bg-primary p-3 cursor-pointer"
                        @click.prevent="
                          openShowUserCommentModal(conversation.subject)
                        "
                      />
                      <span
                        class="color-primary cursor-pointer"
                        @click.prevent="
                          openShowUserCommentModal(conversation.subject)
                        "
                      >
                        {{ $t("actions.showUserComment") }}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span v-if="conversation.host"
                      >{{ conversation.host.profile.givenName }}
                      {{ conversation.host.profile.familyName }}</span
                    >
                  </td>
                  <td>
                    <span v-if="conversation.hasOwnProperty('zoomMeeting')">
                      <span
                        v-if="
                          conversation.zoomMeeting.hasOwnProperty('duration')
                        "
                        >{{ conversation.zoomMeeting.duration }} min</span
                      >
                      <span v-else> - </span>
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <button
                      class="btn btn-outline-primary w-100"
                      @click="openConversationDetailsModal(conversation)"
                    >
                      {{ $t("soulLetter.show") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table v-else id="style-3" class="table style-3  table-hover">
              <tbody>
                <tr
                  class="tr-border-top-grey"
                  v-for="(conversation, cIdx) in historyConversations"
                  :key="cIdx"
                >
                  <table>
                    <tr>
                      <td
                        class="border-0 font-weight-bold td-table-mobile td-first-col-mobile"
                      >
                        <span>{{ $t("soulLetter.tableSlDate") }}: </span>
                      </td>
                      <td class="border-0 td-table-mobile td-first-col-mobile">
                        <span
                          >{{
                            moment(conversation.appointment.date).format(
                              "DD.MM.YYYY"
                            )
                          }}

                          - {{ conversation.appointment.time }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="border-0 font-weight-bold td-table-mobile">
                        <span>{{ $t("conversations.comment") }}: </span>
                      </td>
                      <td class="border-0 td-table-mobile break-word">
                        <p class="restrict-paragraph-two-lines">
                          {{ conversation.subject }}
                        </p>
                        <div v-if="conversation.subject.length > 70">
                          <img
                            class="feathericons-plus-circle bg-primary p-3 cursor-pointer"
                            @click.prevent="
                              openShowUserCommentModal(conversation.subject)
                            "
                          />
                          <span
                            class="color-primary cursor-pointer"
                            @click.prevent="
                              openShowUserCommentModal(conversation.subject)
                            "
                          >
                            {{ $t("actions.showUserComment") }}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="border-0 font-weight-bold td-table-mobile">
                        <span>{{ $t("soulLetter.tableSlCompanion") }}: </span>
                      </td>
                      <td class="border-0 td-table-mobile">
                        <span v-if="conversation.host"
                          >{{ conversation.host.profile.givenName }}
                          {{ conversation.host.profile.familyName }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="border-0 font-weight-bold td-table-mobile">
                        <span>{{ $t("conversations.duration") }}: </span>
                      </td>
                      <td class="border-0 td-table-mobile">
                        <span v-if="conversation.hasOwnProperty('zoomMeeting')">
                          <span
                            v-if="
                              conversation.zoomMeeting.hasOwnProperty(
                                'duration'
                              )
                            "
                            >{{ conversation.zoomMeeting.duration }} min</span
                          >
                          <span v-else> - </span>
                        </span>
                        <span v-else> - </span>
                      </td>
                    </tr>
                  </table>

                  <div
                    class="text-center ml-auto mr-auto pb-4 pt-4 pl-3 pr-3"
                    style="width: 100%;"
                  >
                    <button
                      class="btn btn-outline-primary w-100"
                      @click="openConversationDetailsModal(conversation)"
                    >
                      {{ $t("soulLetter.show") }}
                    </button>
                  </div>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
      </b-col>
    </b-row>
    <!-- Show Comment Modal -->
    <b-modal
      ref="showUserCommentModal"
      size="lg"
      :title="$t('modals.showUserCommentModal.title')"
      hide-footer
      @hide="closeShowUserCommentModal()"
    >
      <b-row>
        <b-col>
          <p v-if="userComment" class="break-word">
            {{ userComment }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <button class="btn btn-outline-primary"
          @click.prevent="closeShowUserCommentModal()">
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Conversation Details Modal -->
    <b-modal
      :title="$t('soulLetter.historyTitle')"
      ref="conversationDetailsModal"
      size="xl"
      hide-footer
      onclose="closeConversationDetailsModal()"
    >
      <b-row>
        <b-col>
          <ConversationSummary :conversation="chosenHistoryConversation" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <button
            @click="closeConversationDetailsModal"
            class="btn btn-primary mb-2 mr-2"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import * as moment from "moment";

export default {
  name: "SoulLetterHistoryTable",
  components: {
    ConversationSummary: () =>
      import("@/components/conversations/ConversationSummary")
  },
  props: {
    historyConversations: Array,
    isMobileScreenSize: Boolean,
    isTabletScreenSize: Boolean,
    isSmDesktopScreenSize: Boolean,
    isModalMobileScreenSize: Boolean
  },
  data() {
    return {
      moment,
      chosenHistoryConversation: null,
      userComment: null
    };
  },
  methods: {
    openShowUserCommentModal(userComment) {
      this.userComment = userComment;
      this.$refs["showUserCommentModal"].show();
    },
    closeShowUserCommentModal() {
      this.userComment = null;
      this.$refs["showUserCommentModal"].hide();
    },
    openConversationDetailsModal(conversation) {
      this.chosenHistoryConversation = conversation;
      this.$refs["conversationDetailsModal"].show();
    },
    closeConversationDetailsModal() {
      this.chosenHistoryConversation = null;
      this.$refs["conversationDetailsModal"].hide();
    }
  }
};
</script>

<style scoped></style>
