import { AppConfig } from "@/config/config";
import developmentConfig from "@/config/environments/config.development";

const config: AppConfig = {
  ...developmentConfig,
  auth0: {
    ...developmentConfig.auth0,
    clientId: "pJxfyP7pfQ0ttV0nelc06OagBtqbmFbS"
  },
  sentry: {
    enabled: true,
    dsn: "https://4bc8ce79aac144b7a9b7a076dfa5e85b@sentry.iamds.com/35"
  },
  backendUrl: "https://api.clemenskuby.com/",
  frontendUrl: "https://portal.clemenskuby.com/",
  calendarModuleTimeZone: "Europe/Berlin",
  gomDuration: 240,
  attendeeCancelPeriod: 86400
};

export default config;
