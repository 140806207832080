<template>
  <b-badge
    v-if="conversation.status === 'pending'"
    class="bg-danger font-size-2 shadow-none"
  >
    {{ $t("conversations.status.pending") }}
  </b-badge>
  <b-badge
    v-else-if="conversation.status === 'scheduled'"
    class="bg-success font-size-2 shadow-none"
  >
    {{ $t("conversations.status.scheduled") }}
  </b-badge>
  <b-badge
    v-else-if="conversation.status === 'unpaid'"
    class="bg-danger font-size-2 shadow-none"
  >
    {{ $t("conversations.status.unpaidClient") }}
  </b-badge>
  <b-badge
    v-else-if="conversation.status === 'completed'"
    class="bg-outline-primary font-size-2 shadow-none"
  >
    {{ $t("conversations.status.completed") }}
  </b-badge>
  <b-badge
    v-else-if="conversation.status === 'failed'"
    class="bg-danger font-size-2 shadow-none"
  >
    {{ $t("conversations.status.failed") }}
  </b-badge>
  <b-badge
    v-else-if="conversation.status === 'cancelled'"
    class="bg-danger font-size-2 shadow-none"
  >
    {{ $t("conversations.status.cancelled") }}
  </b-badge>
</template>

<script>
export default {
  name: "StatusColumn",
  props: {
    conversation: Object
  }
};
</script>

<style scoped></style>
