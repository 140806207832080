import apiClient from "../../../auth/apiClient";

export const listConversations = (data) => {
  return apiClient.get(`/conversations`,data);
};

export const listUnpaidConversations = (data) => {
  return apiClient.get('/conversations/unpaid', data);
};

export const createConversation = data => {
  return apiClient.post(`/conversations/`, data);
};

export const getConversation = conversationId => {
  return apiClient.get(`/conversations/${conversationId}`);
};

export const updateConversation = (conversationId, data)=> {
  return apiClient.put(`/conversations/${conversationId}`, data);
};

export const cancelConversation = conversationId => {
  return apiClient.delete(`/conversations/${conversationId}`);
};

export const doConversationManualBilling = conversationId => {
  return apiClient.post(`/conversations/${conversationId}/manualBilling`);
};

export const getRecording = conversationId => {
  return apiClient.get(`/recordings/${conversationId}`,  {headers: {
    'Content-Type': 'audio/m4a'
  } ,responseType: 'arraybuffer'});
};

export const joinConversation = conversationId => {
  return apiClient.post(`/conversations/${conversationId}/join`);
};

export const leaveConversation = conversationId => {
  return apiClient.post(`/conversations/${conversationId}/leave`);
};