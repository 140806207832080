import Notifications from "vue-notification";
import Vue from "vue";
Vue.use(Notifications);

export function addNotification(type, title, message, duration = 5000) {
  const notificationOptions = {
    type: type,
    title: title,
    text: message,
    duration: duration
  };
  Vue.notify(notificationOptions);
  //Vue.notify(type, title, message, { duration: duration, permanent: false });
}
