<template>
  <div v-if="currentUser">
    <b-dropdown-item v-if="currentUser.role !== EUserRoles.CLIENT" to="profile">
      <div  class="user-profile-section">
        <div  class="media mx-auto">
          <img
            :src="$auth.user.picture"
            class="img-fluid mr-2 max-width-profile-picture-small"
            alt="avatar"
          />
          <div class="media-body">
            <h5 class="max-width-user-profile-dropdown break-word">
              {{ $auth.user.name }}
            </h5>
          </div>
        </div>
      </div>
    </b-dropdown-item>
    <b-dropdown-item to="profile" :class="currentUser.role === EUserRoles.CLIENT ? 'pt-4':''">
      <div class="dropdown-item">
        <a to="profile">
          <img class="feathericons-user p-3 mr-2 bg-dark" />
          <span>{{ $t("actions.profile") }}</span>
        </a>
      </div>
    </b-dropdown-item>
    <ul v-if="currentUser" class="list-unstyled">
      <li
        v-for="el in sidebarElements"
        :key="`${el.id}`"
        :class="{
          active:
            (selectedParentSidebarElement === el.id &&
              viewingParentSidebarElement === '') ||
            viewingParentSidebarElement === el.id
        }"
        class="menu"
      >
        <b-dropdown-item>
          <a
            @click="navigateTo(el.to)"
            rel="noopener noreferrer"
            class="dropdown-toggle"
            target="_blank"
            v-if="
              el &&
                 (el.id !== 'conversations' && el.id !== 'soulLetters') &&
                (currentUser.role === EUserRoles.ADMINISTRATOR && !getUserId)
            "
          >
            <b-img :class="el.icon" class="p-3 mr-2 bg-dark" />
            <span>{{ el.title }}</span>
          </a>
          <a
            @click="navigateTo(el.to)"
            rel="noopener noreferrer"
            class="dropdown-toggle"
            target="_blank"
            v-else-if="  el && (el.id !== 'kingOfTheCastle' && el.id !== 'loginAs' && el.id !== 'vouchers' && el.id !== 'unpaidConversations') &&
                currentUser.role !== EUserRoles.ADMINISTRATOR"
          >
            <b-img :class="el.icon" class="p-3 mr-2 bg-dark" />
            <span class="">{{ el.title }}</span>
          </a>
        </b-dropdown-item>
      </li>
    </ul>
    <b-dropdown-item>
      <div class="dropdown-item">
        <a @click="logout">
          <img class="feathericons-log-out p-3 mr-2 bg-dark" />
          <span>{{ $t("actions.logout") }}</span>
        </a>
      </div>
    </b-dropdown-item>
    <div v-if="isMobileScreenSize" class="border-bottom border-grey"/>
    <b-dropdown-item>
      <div v-if="isMobileScreenSize" class="dropdown-item">
        <a @click="backToHomePage()">
          <img class="feathericons-chevron-left p-3 mr-2 bg-dark" />
          <span> {{ $t("header.backToHomepage") }}</span>
        </a>
      </div>
    </b-dropdown-item>
    <b-dropdown-item>
      <div v-if="isMobileScreenSize && getUserId" class="dropdown-item">
        <a @click="setImpersonateUserId(null)">
          <img class="feathericons-chevron-left p-3 mr-2 bg-dark" />
          <span> {{ $t("loginAs.buttons.logoutToOwnUser") }}</span>
        </a>
      </div>
    </b-dropdown-item>
  </div>
</template>

<script>
import config from "../../config/config";
import sidebarElements from "@/config/sidebar";
import { mapActions, mapGetters } from "vuex";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import screenSizeMixin from "../../mixins/screenSize";


export default {
  name: "UserProfileDropdown",
  mixins: [screenSizeMixin],
  data() {
    return {
      currentUser: null,
      EUserRoles: EUserRoles,
      sidebarElements,
      selectedParentSidebarElement: "",
      viewingParentSidebarElement: ""
    };
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"]),
    ...mapGetters("soulLetters",["getIsEditSoulLetter"]),
    ...mapGetters("loginAs",["getUserId"])
  },
  methods: {
    ...mapActions("users",["clearCurrentUser"]),
    ...mapActions("soulLetters",["setIsEditSoulLetter"]),
    ...mapActions("loginAs",["setUserId", "clearUserId"]),
    setImpersonateUserId(userId) {
      this.setUserId(userId);
    },
    navigateTo(destination) {
      if (this.$route.path.includes(destination)) {
        if (this.getIsEditSoulLetter) {
          let answer = null;
          answer = window.confirm(
            this.$t("soulLetter.leaveSoulLetterModal.descriptionWithoutSave")
          );
          if (answer) {
            this.$router.go();
          }
        } else {
          this.$router.go();
          this.setIsEditSoulLetter(false);
        }
      } else {
        this.setIsEditSoulLetter(false);
        this.$router.push(destination).catch(err => {
          return;
        });
      }
    },
    logout() {
      this.clearCurrentUser();
      this.clearUserId();
      this.$auth.logout({ returnTo: config.logoutReturnUrl });
    },
    backToHomePage(){
      window.location.replace('https://clemenskuby.com/');
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
  }
};
</script>

<style scoped></style>
