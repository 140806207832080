<template>
  <div>
    <table
      v-if="!isMobileScreenSize &&
          !isModalMobileScreenSize && (!isTabletScreenSize || !isSidebarOpen)"
      class="table table-bordered table-striped mb-4"
    >
      <thead>
        <tr>
          <th>{{ $t("loginAs.table.headlines.name") }}</th>
          <th>{{ $t("loginAs.table.headlines.role") }}</th>
          <th>
            {{ $t("loginAs.table.headlines.lastLogin") }}
          </th>
          <th class="text-center"></th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, uIdx) in sortedAuth0Users" :key="uIdx">
          <td>
            <div class="d-flex">
              <div class="usr-img-frame mr-2 rounded-circle">
                <img
                  alt="avatar"
                  class="img-fluid rounded-circle"
                  :src="user.profile.picture"
                />
              </div>
              <p class="align-self-center mb-0">
                <!--{{ user.profile.name }}-->
                {{ displayUsernameToEmail(user.profile) }}
              </p>
            </div>
          </td>
          <td>{{ user.role }}</td>
          <td>
            {{ moment(user.profile.lastLogin).format("DD.MM.YYYY") }}
          </td>
          <td class=" text-center">
            <button
              @click="showUserDetailsModal(user._id)"
              class="btn btn-outline-primary"
            >
              {{ $t("conversations.userDetailsModal.title") }}
            </button>
          </td>
          <td class=" text-center">
            <button
              @click="emitSetImpersonateUserId(user._id)"
              class="btn btn-outline-primary"
            >
              {{ $t("loginAs.buttons.loginAsUser") }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <table v-else id="style-2" class="table style-3  table-hover">
      <thead>
        <tr>
          <th>{{ $t("loginAs.title") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="tr-border-top-grey"
          v-for="(user, uIdx) in sortedAuth0Users"
          :key="uIdx"
        >
          <td>
            <table>
              <tr>
                <td
                  class="border-0 font-weight-bold td-table-mobile td-first-col-mobile"
                >
                  <span>{{ $t("loginAs.table.headlines.name") }}: </span>
                </td>
                <td class="border-0 td-table-mobile td-first-col-mobile">
                  <div class="d-flex">
                    <div
                      style="width: 35px;"
                      class="usr-img-frame mr-2 rounded-circle"
                    >
                      <img
                        alt="avatar"
                        class="img-fluid rounded-circle"
                        :src="user.profile.picture"
                      />
                    </div>
                    <p class="align-self-center mb-0">
                      {{ displayUsernameToEmail(user.profile) }}
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="border-0 font-weight-bold td-table-mobile">
                  <span>{{ $t("loginAs.table.headlines.role") }}: </span>
                </td>
                <td class="border-0 td-table-mobile">
                  <span>{{ user.role }}</span>
                </td>
              </tr>
              <tr>
                <td class="border-0 font-weight-bold td-table-mobile">
                  <span>{{ $t("loginAs.table.headlines.lastLogin") }}: </span>
                </td>
                <td class="border-0 td-table-mobile">
                  <span>{{
                    moment(user.profile.lastLogin).format("DD.MM.YYYY")
                  }}</span>
                </td>
              </tr>
            </table>

            <div
              class="text-center ml-auto mr-auto pt-4 pl-3 pr-3"
              style="width: 100%;"
            >
              <button
                @click="showUserDetailsModal(user._id)"
                class="btn btn-outline-primary w-100 ml-auto mr-auto"
              >
                {{ $t("conversations.userDetailsModal.title") }}
              </button>
            </div>
            <div
              class="text-center ml-auto mr-auto pb-4 pt-4 pl-3 pr-3"
              style="width: 100%;"
            >
              <button
                @click="emitSetImpersonateUserId(user._id)"
                class="btn btn-outline-primary w-100 ml-auto mr-auto"
              >
                {{ $t("loginAs.buttons.loginAsUser") }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- User Details Modal -->
    <b-modal
      :title="$t('conversations.userDetailsModal.title')"
      ref="userDetailsModal"
      size="xl"
      hide-footer
      onclose="closeUserDetailsModal()"
    >
      <div v-if="clientContactInformation">
        <b-row class="mb-4 text-center">
          <b-col>
            <h6 class="font-weight-bold">
              {{ $t("profile.contactInformation.description") }}:
            </h6>
          </b-col>
        </b-row>
        <table class="ml-auto mr-auto">
          <tr v-if="clientContactInformation.hasOwnProperty('givenName')">
            <td class="p-1">
              <span class="font-weight-bold">
                {{ $t("profile.firstName") }}:
              </span>
            </td>
            <td class="p-1">
              {{ clientContactInformation.givenName }}
            </td>
          </tr>
          <tr v-if="clientContactInformation.hasOwnProperty('familyName')">
            <td class="p-1">
              <span class="font-weight-bold">
                {{ $t("profile.lastName") }}:
              </span>
            </td>
            <td class="p-1">
              {{ clientContactInformation.familyName }}
            </td>
          </tr>
          <tr v-if="clientContactInformation.hasOwnProperty('email')">
            <td class="p-1">
              <span class="font-weight-bold"> {{ $t("profile.eMail") }}: </span>
            </td>
            <td class="p-1">
              {{ clientContactInformation.email }}
            </td>
          </tr>
          <tr v-if="clientContactInformation.hasOwnProperty('phoneNumber')">
            <td class="p-1">
              <span class="font-weight-bold"> {{ $t("profile.telNr") }}: </span>
            </td>
            <td class="p-1">
              {{ clientContactInformation.phoneNumber }}
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-5">
        <ConversationHistoryTable
          :is-mobile-screen-size="isMobileScreenSize"
          :is-tablet-screen-size="isTabletScreenSize"
          :is-sm-desktop-screen-size="isSmDesktopScreenSize"
          :is-modal-mobile-screen-size="isModalMobileScreenSize"
          :history-conversations="historyConversations"
        />
        <SoulLetterHistoryTable
          :chosen-client="chosenClientSoulLetter"
          :is-mobile-screen-size="isMobileScreenSize"
          :is-tablet-screen-size="isTabletScreenSize"
          :is-sm-desktop-screen-size="isSmDesktopScreenSize"
          :is-modal-mobile-screen-size="isModalMobileScreenSize"
          :history-soul-letters="historySoulLetters"
        />
      </div>
      <b-row class="mt-5">
        <b-col>
          <button
            @click="closeUserDetailsModal"
            class="btn btn-primary mb-2 mr-2"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { displayUsernameToEmail } from "../../utils/userHelper";
import * as moment from "moment";
import screenSizeMixin from "@/mixins/screenSize";
import * as userApi from "@/store/modules/users/users.api";
import { convertToLocal } from "@/utils/timezoneHelper";
import ConversationHistoryTable from "@/components/conversations/ConversationHistoryTable";
import SoulLetterHistoryTable from "@/components/soulLetters/SoulLetterHistoryTable";
import {mapGetters} from "vuex";

export default {
  name: "LoginAsTable",
  mixins: [screenSizeMixin],
  components: {
    ConversationHistoryTable,
    SoulLetterHistoryTable
  },
  props: {
    sortedAuth0Users: Array,
    setImpersonateUserId: Function
  },
  data() {
    return {
      moment,
      displayUsernameToEmail,
      clientContactInformation: null,
      chosenClientSoulLetter: null,
      historyConversations: [],
      historySoulLetters: []
    };
  },
  computed:{
    ...mapGetters("sideBar",["isSidebarOpen"]),
  },
  methods: {
    closeUserDetailsModal() {
      this.$refs["userDetailsModal"].hide();
    },
    showUserDetailsModal(userId) {
      userApi
        .getUser(userId)
        .then(res => {
          const user = res.data.data;
          this.clientContactInformation = user.profile;
          const clientPayload = {
            familyName: user.profile.familyName,
            givenName: user.profile.givenName,
            name: user.profile.name,
            profileImage: user.profile.picture,
            role: user.role,
            _id: user._id
          };
          this.chosenClientSoulLetter = clientPayload;

          userApi
            .getUserHistory(userId)
            .then(res => {
              const userHistory = res.data.data;
              this.historyConversations = [];
              this.historySoulLetters = [];
              this.historySoulLetters = userHistory.letters;

              userHistory.conversations.forEach(conversation => {
                const convertedUtc = convertToLocal(
                  conversation.appointment.date,
                  conversation.appointment.endDate,
                  conversation.appointment.time,
                  conversation.appointment.endTime
                );
                const formattedAppointment = {
                  date: convertedUtc.date,
                  endDate: convertedUtc.endDate,
                  time: convertedUtc.time,
                  endTime: convertedUtc.endTime,
                  _id: conversation.appointment._id
                };
                const toDay = moment();
                if (
                  moment(formattedAppointment.date, "YYYY-MM-DD").diff(
                    moment(toDay, "YYYY-MM-DD")
                  ) <= 0
                ) {
                  let formattedConversation;
                  if (conversation.zoomMeeting) {
                    formattedConversation = {
                      appointment: formattedAppointment,
                      attendees: conversation.attendees,
                      host: conversation.host,
                      subject: conversation.subject,
                      zoomMeeting: conversation.zoomMeeting,
                      _id: conversation._id
                    };
                  } else {
                    formattedConversation = {
                      appointment: formattedAppointment,
                      attendees: conversation.attendees,
                      host: conversation.host,
                      subject: conversation.subject,
                      _id: conversation._id
                    };
                  }

                  this.historyConversations.push(formattedConversation);
                }
              });
              this.loading = false;
              this.loadingError = false;
            })
            .catch(err => {
              this.loading = false;
              this.loadingError = true;
            });
          this.$refs["userDetailsModal"].show();
        });
    },
    emitSetImpersonateUserId(userId) {
      this.setImpersonateUserId(userId);
    }
  }
};
</script>

<style scoped></style>
