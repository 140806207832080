<template>
  <div id="content" class="main-content">
    <div class="m-4">
      <div class="row layout-spacing layout-top-spacing" id="cancel-row">
        <div class="col-xl-12 col-sm-12 layout-spacing">
          <div class="statbox widget box box-shadow">
            <div class="widget-header">
              <b-row>
                <div class="col-12">
                  <h4>{{ $t("vouchers.title") }}</h4>
                </div>
              </b-row>
            </div>
            <div class="widget-content widget-content-area">
              <div v-if="loading">
                <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                  <div
                    class="spinner-border text-success align-self-center loader-lg"
                  />
                </div>
              </div>
              <div v-else-if="loadingError" class="cm-loading-error">
                {{ $t("calendarModule.errors.loadingError") }}
              </div>
              <div v-else>
                <b-row v-if="!isMobileScreenSize">
                  <b-col>
                    <b-form-group
                      :description="
                        $t('vouchers.searchField.descriptions.search')
                      "
                    >
                      <template v-slot:label>
                        {{ $t("vouchers.searchField.labels.search") }}
                      </template>
                      <b-input v-model="searchText" />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :description="
                        $t('vouchers.searchField.descriptions.filterType')
                      "
                    >
                      <template v-slot:label>
                        {{ $t("vouchers.searchField.labels.filterType") }}
                      </template>
                      <v-select
                        :options="voucherTypeOptions"
                        v-model="voucherTypeOption"
                        :reduce="o => o.code"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col class="col-12">
                    <b-form-group
                      :description="
                        $t('vouchers.searchField.descriptions.search')
                      "
                    >
                      <template v-slot:label>
                        {{ $t("vouchers.searchField.labels.search") }}
                      </template>
                      <b-input v-model="searchText" />
                    </b-form-group>
                    <b-form-group
                      :description="
                        $t('vouchers.searchField.descriptions.filterType')
                      "
                    >
                      <template v-slot:label>
                        {{ $t("vouchers.searchField.labels.filterType") }}
                      </template>
                      <v-select
                        :options="voucherTypeOptions"
                        v-model="voucherTypeOption"
                        :reduce="o => o.code"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-2 mb-2">
                  <b-col>
                    <button
                      @click.prevent="openAddVoucherModal()"
                      class="btn btn-outline-primary mr-2"
                    >
                      {{
                        $t("vouchers.modals.addVoucherModal.buttons.addVoucher")
                      }}
                    </button>
                    <button
                      @click.prevent="openMassDeleteVouchersModal()"
                      class="btn btn-outline-danger"
                      :disabled="emittedChosenVouchers.length <= 0"
                    >
                      {{
                        $t(
                          "vouchers.modals.addVoucherModal.buttons.deleteVouchers"
                        )
                      }}
                    </button>
                  </b-col>
                </b-row>
                <b-row class="mt-5" v-if="currentUser && vouchers">
                  <b-col>
                    <div class="table-responsive">
                      <perfect-scrollbar>
                        <VouchersTable
                          :sorted-vouchers="sortedVouchers"
                          :edit-voucher="openEditVoucherModal"
                          :update-voucher="updateVoucher"
                          :delete-voucher="openDeleteVoucherModal"
                          :add-or-splice-chosen-vouchers-to-list="
                            addOrSpliceChosenVouchersToList
                          "
                        />
                      </perfect-scrollbar>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Voucher Modal -->
    <b-modal
      :title="$t('vouchers.modals.addVoucherModal.title')"
      ref="addVoucherModal"
      hide-footer
      size="md"
      onclose="closeAddVoucherModal()"
    >
      <p>{{ $t("vouchers.modals.addVoucherModal.description") }}</p>
      <div>
        <b-form-group
          :description="
            $t('vouchers.modals.addVoucherModal.labels.codeDescription')
          "
          :label="$t('vouchers.modals.addVoucherModal.labels.code')"
        >
          <b-input v-model="chosenVoucherCode" />
          <span class="inputRequired" v-if="!$v.chosenVoucherCode.required">
            {{ $t("profile.error.inputRequired") }}
          </span>
        </b-form-group>
        <b-form-group
          :description="
            $t('vouchers.modals.addVoucherModal.labels.valueDescription')
          "
          :label="$t('vouchers.modals.addVoucherModal.labels.value')"
        >
          <b-input v-model="$v.chosenVoucherValue.$model" />
          <span class="error" v-if="!$v.chosenVoucherValue.onlyNumbers">
            {{ $t("vouchers.modals.addVoucherModal.errors.onlyNumbersError") }}
          </span>
          <span class="inputRequired" v-if="!$v.chosenVoucherValue.required">
            {{ $t("profile.error.inputRequired") }}
          </span>
        </b-form-group>
        <b-form-group
          :description="
            $t(
              'vouchers.modals.addVoucherModal.labels.maxUsesPerUserDescription'
            )
          "
          :label="$t('vouchers.modals.addVoucherModal.labels.maxUsesPerUser')"
        >
          <b-input v-model="$v.maxUsesPerUser.$model" />
          <span class="error" v-if="!$v.maxUsesPerUser.onlyNumbers">
            {{ $t("vouchers.modals.addVoucherModal.errors.onlyNumbersError") }}
          </span>
          <span class="inputRequired" v-if="!$v.maxUsesPerUser.required">
            {{ $t("profile.error.inputRequired") }}
          </span>
        </b-form-group>
        <b-form-group
          :description="
            $t('vouchers.modals.addVoucherModal.labels.methodDescription')
          "
        >
          <template v-slot:label>
            {{ $t("vouchers.modals.addVoucherModal.labels.method") }}
          </template>
          <v-select
            :options="voucherMethodsOptions"
            v-model="voucherMethodOption"
            :reduce="o => o.code"
          />
          <span class="inputRequired" v-if="!$v.voucherMethodOption.required">
            {{ $t("profile.error.inputRequired") }}
          </span>
        </b-form-group>
        <b-form-group
          :description="
            $t('vouchers.modals.addVoucherModal.labels.typeDescription')
          "
        >
          <template v-slot:label>
            {{ $t("vouchers.modals.addVoucherModal.labels.type") }}
          </template>
          <v-select
            :options="voucherTypeModalOptions"
            v-model="voucherTypeModalOption"
            :reduce="o => o.code"
          />
          <span
            class="inputRequired"
            v-if="!$v.voucherTypeModalOption.required"
          >
            {{ $t("profile.error.inputRequired") }}
          </span>
        </b-form-group>
        <b-form-group
          :description="
            $t('vouchers.modals.addVoucherModal.labels.validFromDescription')
          "
          :label="$t('vouchers.modals.addVoucherModal.labels.validFrom')"
          class="has-float-label"
        >
          <date-picker
            id="validFromAddDateInput"
            ref="validFromAddDateInput"
            class="w-100"
            format="DD.MM.YYYY"
            input-class="form-control pac-target-input"
            v-model="chosenVoucherValidFrom"
          />
          <span
            class="inputRequired"
            v-if="!$v.chosenVoucherValidFrom.required"
          >
            {{ $t("profile.error.inputRequired") }}
          </span>
        </b-form-group>
        <b-form-group
          :description="
            $t('vouchers.modals.addVoucherModal.labels.validUntilDescription')
          "
          :label="$t('vouchers.modals.addVoucherModal.labels.validUntil')"
          class="has-float-label"
        >
          <date-picker
            id="validUntilAddDateInput"
            ref="validUntilAddDateInput"
            class="w-100"
            format="DD.MM.YYYY"
            input-class="form-control pac-target-input"
            v-model="chosenVoucherValidUntil"
          />
          <span
            class="inputRequired"
            v-if="!$v.chosenVoucherValidUntil.required"
          >
            {{ $t("profile.error.inputRequired") }}
          </span>
        </b-form-group>
      </div>
      <button
        @click="createVoucher()"
        :disabled="$v.$invalid"
        class="btn btn-success mb-2 mr-2 mt-4"
      >
        {{ $t("misc.add") }}
      </button>
      <button
        @click="closeAddVoucherModal()"
        class="btn btn-outline-success mb-2 mr-2 mt-4"
      >
        {{ $t("misc.close") }}
      </button>
    </b-modal>
    <!-- Edit Voucher Modal -->
    <b-modal
      :title="$t('vouchers.modals.editVoucherModal.title')"
      ref="editVoucherModal"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @close="closeEditVoucherModal()"
      size="md"
    >
      <p>{{ $t("vouchers.modals.editVoucherModal.description") }}</p>
      <div v-if="chosenVoucher">
        <b-form-group
          :description="
            $t('vouchers.modals.editVoucherModal.labels.codeDescription')
          "
          :label="$t('vouchers.modals.editVoucherModal.labels.code')"
        >
          <b-input v-model="chosenVoucherCode" />
        </b-form-group>
        <b-form-group
          :description="
            $t('vouchers.modals.editVoucherModal.labels.valueDescription')
          "
          :label="$t('vouchers.modals.editVoucherModal.labels.value')"
        >
          <b-input v-model="$v.chosenVoucherValue.$model" />
          <span class="error" v-if="!$v.chosenVoucherValue.onlyNumbers">
            {{ $t("vouchers.modals.editVoucherModal.errors.onlyNumbersError") }}
          </span>
        </b-form-group>
        <b-form-group
          :description="
            $t(
              'vouchers.modals.addVoucherModal.labels.maxUsesPerUserDescription'
            )
          "
          :label="$t('vouchers.modals.addVoucherModal.labels.maxUsesPerUser')"
        >
          <b-input v-model="$v.maxUsesPerUser.$model" />
          <span class="error" v-if="!$v.maxUsesPerUser.onlyNumbers">
            {{ $t("vouchers.modals.addVoucherModal.errors.onlyNumbersError") }}
          </span>
          <span class="inputRequired" v-if="!$v.maxUsesPerUser.required">
            {{ $t("profile.error.inputRequired") }}
          </span>
        </b-form-group>
        <b-form-group
          :description="
            $t('vouchers.modals.editVoucherModal.labels.methodDescription')
          "
        >
          <template v-slot:label>
            {{ $t("vouchers.modals.editVoucherModal.labels.method") }}
          </template>
          <v-select
            :options="voucherMethodsOptions"
            v-model="voucherMethodOption"
            :reduce="o => o.code"
          />
        </b-form-group>
        <b-form-group
          :description="
            $t('vouchers.modals.editVoucherModal.labels.typeDescription')
          "
        >
          <template v-slot:label>
            {{ $t("vouchers.modals.editVoucherModal.labels.type") }}
          </template>
          <v-select
            :options="voucherTypeModalOptions"
            v-model="voucherTypeModalOption"
            :reduce="o => o.code"
          />
        </b-form-group>
        <b-form-group
          :description="
            $t('vouchers.modals.editVoucherModal.labels.validFromDescription')
          "
          :label="$t('vouchers.modals.editVoucherModal.labels.validFrom')"
          class="has-float-label"
        >
          <date-picker
            id="validFromEditDateInput"
            ref="validFromEditDateInput"
            class="w-100"
            format="DD.MM.YYYY"
            input-class="form-control pac-target-input"
            v-model="chosenVoucherValidFrom"
          />
        </b-form-group>
        <b-form-group
          :description="
            $t('vouchers.modals.editVoucherModal.labels.validUntilDescription')
          "
          :label="$t('vouchers.modals.editVoucherModal.labels.validUntil')"
          class="has-float-label"
        >
          <date-picker
            id="validUntilEditDateInput"
            ref="validUntilEditDateInput"
            class="w-100"
            format="DD.MM.YYYY"
            input-class="form-control pac-target-input"
            v-model="chosenVoucherValidUntil"
          />
        </b-form-group>
      </div>
      <button
        @click="updateVoucher(chosenVoucher, true)"
        class="btn btn-success mb-2 mr-2 mt-4"
      >
        {{ $t("vouchers.modals.editVoucherModal.buttons.editVoucher") }}
      </button>
      <button
        @click="closeEditVoucherModal()"
        class="btn btn-outline-success mb-2 mr-2 mt-4"
      >
        {{ $t("misc.close") }}
      </button>
    </b-modal>
    <!-- Delete Voucher Modal -->
    <b-modal
      :title="$t('vouchers.modals.deleteVoucherModal.title')"
      ref="deleteVoucherModal"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @close="closeDeleteVoucherModal()"
      size="md"
    >
      <p>{{ $t("vouchers.modals.deleteVoucherModal.description") }}</p>
      <button
        @click="deleteVoucher(chosenVoucher)"
        class="btn btn-danger mb-2 mr-2 mt-4"
      >
        {{ $t("vouchers.modals.deleteVoucherModal.buttons.deleteVouchers") }}
      </button>
      <button
        @click="closeDeleteVoucherModal()"
        class="btn btn-outline-success mb-2 mr-2 mt-4"
      >
        {{ $t("misc.close") }}
      </button>
    </b-modal>
    <!-- Mass Delete Vouchers Modal -->
    <b-modal
      :title="$t('vouchers.modals.massDeleteVouchersModal.title')"
      ref="massDeleteVouchers"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @close="closeMassDeleteVouchersModal()"
      size="md"
    >
      <p>{{ $t("vouchers.modals.massDeleteVouchersModal.description") }}</p>
      <button
        @click="massDeleteVouchers(emittedChosenVouchers)"
        class="btn btn-danger mb-2 mr-2 mt-4"
      >
        {{
          $t("vouchers.modals.massDeleteVouchersModal.buttons.deleteVouchers")
        }}
      </button>
      <button
        @click="closeMassDeleteVouchersModal()"
        class="btn btn-outline-success mb-2 mr-2 mt-4"
      >
        {{ $t("misc.close") }}
      </button>
    </b-modal>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/de";
import "vue2-datepicker/index.css";
import * as voucherApi from "@/store/modules/voucher/voucher.api";
import { mapGetters } from "vuex";
import VouchersTable from "@/components/admin/VouchersTable";
import screenSizeMixin from "@/mixins/screenSize";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { EVoucherMethods } from "@/store/modules/voucher/EVoucherMethods";
import { EVoucherTypes } from "@/store/modules/voucher/EVoucherTypes";
import { helpers, required } from "vuelidate/lib/validators";
import * as userApi from "@/store/modules/users/users.api";
import { EUserRoles } from "@/store/modules/users/EUserRoles";

const onlyNumbers = helpers.regex("phoneNumber", /^[0-9]*$/);

export default {
  name: "Vouchers",
  title: "Gutscheine",
  components: { VouchersTable, vSelect, DatePicker },
  mixins: [screenSizeMixin],
  data() {
    return {
      loading: false,
      loadingError: false,
      reloadContent: false,
      allUsers: [],
      vouchers: [],
      chosenVoucher: null,
      emittedChosenVouchers: [],
      currentUser: null,
      searchText: "",
      voucherMethodOption: EVoucherMethods.PERCENTAGE,
      voucherTypeModalOption: EVoucherTypes.CONVERSATIONS,
      voucherTypeOption: null,
      chosenVoucherCode: null,
      chosenVoucherValidFrom: null,
      chosenVoucherValidUntil: null,
      chosenVoucherValue: null,
      maxUsesPerUser: null
    };
  },
  validations: {
    voucherMethodOption: { required },
    voucherTypeModalOption: { required },
    chosenVoucherCode: { required },
    chosenVoucherValidFrom: { required },
    chosenVoucherValidUntil: { required },
    chosenVoucherValue: { onlyNumbers, required },
    maxUsesPerUser: { onlyNumbers, required }
  },
  computed: {
    ...mapGetters("users", ["getCurrentUser"]),
    voucherTypeModalOptions() {
      return [
        {
          label: this.$t("vouchers.voucherType.conversationsWithoutKuby"),
          code: "withoutClemensKuby"
        },
        {
          label: this.$t("vouchers.voucherType.conversations"),
          code: EVoucherTypes.CONVERSATIONS
        },
        {
          label: this.$t("vouchers.voucherType.soulLetters"),
          code: EVoucherTypes.LETTERS
        },
        {
          label: this.$t("vouchers.voucherType.students"),
          code: EVoucherTypes.STUDENTS
        },
        {
          label: this.$t("vouchers.voucherType.all"),
          code: EVoucherTypes.ALL
        }
      ];
    },
    voucherTypeOptions() {
      return [
        {
          label: this.$t("vouchers.voucherType.showAll"),
          code: null
        },
        {
          label: this.$t("vouchers.voucherType.conversations"),
          code: EVoucherTypes.CONVERSATIONS
        },
        {
          label: this.$t("vouchers.voucherType.soulLetters"),
          code: EVoucherTypes.LETTERS
        },
        {
          label: this.$t("vouchers.voucherType.students"),
          code: EVoucherTypes.STUDENTS
        },
        {
          label: this.$t("vouchers.voucherType.all"),
          code: EVoucherTypes.ALL
        }
      ];
    },
    voucherMethodsOptions() {
      return [
        {
          label: this.$t("vouchers.voucherMethod.percent"),
          code: EVoucherMethods.PERCENTAGE
        },
        {
          label: this.$t("vouchers.voucherMethod.euro"),
          code: EVoucherMethods.DIRECT
        }
      ];
    },
    sortedVouchers() {
      const searchParts = this.searchText
        .toLowerCase()
        .trim()
        .split(" ");
      let hasParts = false;
      for (const searchPart of searchParts) {
        if (searchPart) {
          hasParts = true;
        }
      }

      const baseVouchers =
        this.vouchers.filter(v => v.type === this.voucherTypeOption).length > 0
          ? this.vouchers.filter(v => v.type === this.voucherTypeOption)
          : this.voucherTypeOption === null
          ? this.vouchers
          : [];
      if (hasParts) {
        return this.filterVoucherNameField(baseVouchers, searchParts);
      }

      return baseVouchers;
    }
  },
  methods: {
    addOrSpliceChosenVouchersToList(chosenVouchers) {
      this.emittedChosenVouchers = [];
      this.emittedChosenVouchers = chosenVouchers;
    },
    openMassDeleteVouchersModal() {
      this.$refs["massDeleteVouchers"].show();
    },
    closeMassDeleteVouchersModal() {
      this.emittedChosenVouchers = [];
      this.getAllVouchers();
      this.$refs["massDeleteVouchers"].hide();
    },
    openDeleteVoucherModal(voucher) {
      this.chosenVoucher = voucher;
      this.$refs["deleteVoucherModal"].show();
    },
    closeDeleteVoucherModal() {
      this.chosenVoucher = null;
      this.getAllVouchers();
      this.$refs["deleteVoucherModal"].hide();
    },
    openAddVoucherModal() {
      this.$refs["addVoucherModal"].show();
    },
    closeAddVoucherModal() {
      this.$refs["addVoucherModal"].hide();
    },
    openEditVoucherModal(voucher) {
      this.chosenVoucher = voucher;
      this.setEditVoucherFields();
      this.$refs["editVoucherModal"].show();
    },
    setEditVoucherFields() {
      this.chosenVoucherCode = this.chosenVoucher.code;
      this.chosenVoucherValue = this.chosenVoucher.value;
      this.maxUsesPerUser = this.chosenVoucher.maxUsesPerUser;
      this.voucherTypeModalOption = this.chosenVoucher.type;
      this.voucherMethodOption = this.chosenVoucher.method;
      this.chosenVoucherValidFrom = this.chosenVoucher.validFrom;
      this.chosenVoucherValidUntil = this.chosenVoucher.validUntil;
    },
    unsetEditVoucherFields() {
      this.chosenVoucherCode = null;
      this.chosenVoucherValue = null;
      this.maxUsesPerUser = null;
      this.voucherTypeModalOption = null;
      this.voucherMethodOption = null;
      this.chosenVoucherValidFrom = null;
      this.chosenVoucherValidUntil = null;
    },
    closeEditVoucherModal() {
      this.chosenVoucher = null;
      this.unsetEditVoucherFields();
      this.getAllVouchers();
      this.$refs["editVoucherModal"].hide();
    },
    getAllUsers() {
      userApi.getAllUsers().then(res => {
        this.allUsers = res.data.data;
      });
    },
    getAllVouchers() {
      this.loading = true;
      this.loadingError = false;
      voucherApi
        .getAllVouchers()
        .then(res => {
          const vouchers = res.data.data;
          this.vouchers = vouchers;
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    createVoucher() {
      let voucherTypeOption;
      const excludedUsers = [];
      if (this.voucherTypeModalOption === "withoutClemensKuby") {
        voucherTypeOption = EVoucherTypes.CONVERSATIONS;
        const clemens = "5675bf7f-af3a-4d86-b7f9-2d11fee03c5a";
        excludedUsers.push(clemens);
      } else {
        voucherTypeOption = this.voucherTypeModalOption;
      }

      let payload;
      if (excludedUsers.length > 0) {
        payload = {
          code: this.chosenVoucherCode,
          type: voucherTypeOption,
          method: this.voucherMethodOption,
          value: this.chosenVoucherValue,
          validFrom: this.chosenVoucherValidFrom,
          validUntil: this.chosenVoucherValidUntil,
          excludeUsers: excludedUsers,
          maxUsesPerUser: this.maxUsesPerUser,
          isActive: true
        };
      } else {
        payload = {
          code: this.chosenVoucherCode,
          type: voucherTypeOption,
          method: this.voucherMethodOption,
          value: this.chosenVoucherValue,
          validFrom: this.chosenVoucherValidFrom,
          validUntil: this.chosenVoucherValidUntil,
          maxUsesPerUser: this.maxUsesPerUser,
          isActive: true
        };
      }

      voucherApi.createVoucher(payload).then(res => {
        this.getAllVouchers();
        this.closeAddVoucherModal();
      });
    },
    updateVoucher(voucher, isEditVoucher) {
      let payload;
      if (isEditVoucher) {
        let voucherTypeOption;
        const excludedUsers = [];
        if (this.voucherTypeModalOption === "withoutClemensKuby") {
          voucherTypeOption = EVoucherTypes.CONVERSATIONS;
          const clemens = "5675bf7f-af3a-4d86-b7f9-2d11fee03c5a";
          excludedUsers.push(clemens);
        } else {
          voucherTypeOption = this.voucherTypeModalOption;
        }

        if (excludedUsers.length > 0) {
          payload = {
            code: this.chosenVoucherCode,
            type: voucherTypeOption,
            method: this.voucherMethodOption,
            value: this.chosenVoucherValue,
            validFrom: this.chosenVoucherValidFrom,
            validUntil: this.chosenVoucherValidUntil,
            excludeUsers: excludedUsers,
            maxUsesPerUser: this.maxUsesPerUser,
            isActive: voucher.isActive
          };
        } else {
          payload = {
            code: this.chosenVoucherCode,
            type: voucherTypeOption,
            method: this.voucherMethodOption,
            value: this.chosenVoucherValue,
            validFrom: this.chosenVoucherValidFrom,
            validUntil: this.chosenVoucherValidUntil,
            maxUsesPerUser: this.maxUsesPerUser,
            isActive: voucher.isActive
          };
        }
      } else {
        payload = {
          isActive: voucher.isActive
        };
      }
      /*if (isEditVoucher) {
        payload = {
          code: this.chosenVoucherCode,
          type: this.voucherTypeModalOption,
          method: this.voucherMethodOption,
          value: this.chosenVoucherValue,
          validFrom: this.chosenVoucherValidFrom,
          validUntil: this.chosenVoucherValidUntil,
          isActive: voucher.isActive
        };
      } else {
        payload = {
          isActive: voucher.isActive
        };
      }*/
      voucherApi
        .updateVoucher(voucher._id, payload)
        .then(res => {
          this.closeEditVoucherModal();
        });
    },
    massDeleteVouchers(vouchers) {
      this.loading = true;
      this.loadingError = false;
      vouchers.forEach(voucher => {
        voucherApi
          .deleteVoucher(voucher._id)
          .catch(err => {
            this.loading = false;
            this.loadingError = true;
          });
      });
      setTimeout(() => {
        this.closeMassDeleteVouchersModal();
      }, 1000);
      this.loading = false;
      this.loadingError = false;
    },
    deleteVoucher() {
      voucherApi
        .deleteVoucher(this.chosenVoucher._id)
        .then(res => {
          this.closeDeleteVoucherModal();
        });
    },
    filterVoucherNameField(baseVouchers, searchParts) {
      return baseVouchers.filter(voucher => {
        for (const searchPart of searchParts) {
          if (searchPart) {
            if (!voucher.code.toLowerCase().includes(searchPart)) {
              return false;
            }
          }
        }
        return true;
      });
    }
  },
  mounted() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    this.getAllVouchers();
    // this.getAllUsers();
  }
};
</script>

<style scoped></style>
