<template>
  <div id="content" class="main-content">
    <div class="layout-px-spacing">
      <div class="account-settings-container layout-top-spacing">
        <div class="account-content">
          <div
            class="scrollspy-example"
            data-spy="scroll"
            data-target="#account-settings-scroll"
            data-offset="-100"
          >
            <div v-if="loading">
              <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                <div
                  class="spinner-border text-success align-self-center loader-lg"
                />
              </div>
            </div>
            <div v-else-if="loadingError" class="cm-loading-error">
              {{ $t("calendarModule.errors.loadingError") }}
            </div>
            <b-row v-else>
              <div class="col-xl-12 col-lg-12 col-md-12 layout-spacing">
                <form id="general-info" class="section general-info">
                  <div class="info">
                    <h6>{{ $t("profile.personalData") }}</h6>
                    <b-row>
                      <!--  mx-auto -->
                      <div class="col-lg-11">
                        <b-row>
                          <div class="col-xl-2 col-lg-12 col-md-4">
                            <div class="upload mt-4 pr-md-4 mb-5">
                              <div
                                class="dropify-wrapper has-preview"
                                @click="$refs.avatarInput.click()"
                              >
                                <b-img
                                  v-if="this.selectedFile"
                                  class="imageUploaderPreview"
                                  :src="selectedFile"
                                />
                                <div class="dropify-message">
                                  <span class="file-icon"> </span>
                                  <p>
                                    {{ $t("profile.uploadProfilePicture") }}
                                  </p>
                                  <p class="dropify-error">
                                    {{ $t("profile.error.somethingWentWrong") }}
                                  </p>
                                </div>
                                <div class="dropify-loader">
                                  <div class="dropify-errors-container">
                                    <ul></ul>
                                  </div>
                                  <input
                                    ref="avatarInput"
                                    type="file"
                                    id="input-file-max-fs"
                                    name="avatar"
                                    @change="saveAvatar"
                                    accept=".png, .jpeg, .jpg"
                                    class="dropify"
                                    data-default-file="../../assets/img/200x200.jpg"
                                  />
                                  <button type="button" class="dropify-clear">
                                    <i class="flaticon-close-fill"></i>
                                  </button>
                                  <div class="dropify-preview">
                                    <span class="dropify-render"> </span>
                                    <div class="dropify-infos">
                                      <div class="dropify-infos-inner">
                                        <p class="dropify-filename">
                                          <span class="file-icon"></span>
                                          <span class="dropify-filename-inner"
                                            >200x200.jpg</span
                                          >
                                        </p>
                                        <p class="dropify-infos-message">
                                          {{
                                            $t("profile.uploadProfilePicture")
                                          }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-xl-10 col-lg-12 col-md-8 mt-md-0 mt-4"
                          >
                            <b-form class="form">
                              <!-- firstName lastName -->
                              <b-row>
                                <b-col cols="6" sm="6">
                                  <b-form-group>
                                    <label>{{ $t("profile.firstName") }}</label>
                                    <b-form-input
                                      v-model.trim="$v.profileFirstName.$model"
                                      :placeholder="$t('profile.firstName')"
                                      class="form-control mb-1"
                                    ></b-form-input>
                                    <span
                                      class="inputRequired"
                                      v-if="!$v.profileFirstName.required"
                                    >
                                      {{ $t("profile.error.inputRequired") }}
                                    </span>
                                    <span
                                      class="error"
                                      v-if="!$v.profileFirstName.germanAlpha"
                                    >
                                      {{ $t("profile.error.firstNameError") }}
                                    </span>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="6" sm="6">
                                  <b-form-group>
                                    <label>{{ $t("profile.lastName") }}</label>
                                    <b-form-input
                                      v-model.trim="$v.profileLastName.$model"
                                      :placeholder="$t('profile.lastName')"
                                      class="form-control mb-1"
                                    ></b-form-input>
                                    <span
                                      class="inputRequired"
                                      v-if="!$v.profileLastName.required"
                                    >
                                      {{ $t("profile.error.inputRequired") }}
                                    </span>
                                    <span
                                      class="error"
                                      v-if="!$v.profileLastName.germanAlpha"
                                    >
                                      {{ $t("profile.error.firstNameError") }}
                                    </span>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <!-- Email Phonenumber -->
                              <b-row>
                                <b-col class="col-sm-6">
                                  <b-form>
                                    <label>{{
                                      $t("profile.wantToChangeMore")
                                    }}</label>
                                    <v-select
                                      v-model="chosenChangeMoreOption"
                                      :options="optionsChangeMore"
                                      class="rounded-0"
                                      :reduce="o => o.code"
                                    />
                                  </b-form>
                                </b-col>
                                <b-col
                                  v-if="
                                    chosenChangeMoreOption === 'changeEmail'
                                  "
                                  class="col-sm-6"
                                >
                                  <b-form-group>
                                    <label>{{ $t("profile.eMail") }}</label>
                                    <b-form-input
                                      v-model.trim="$v.profileEmail.$model"
                                      :placeholder="$t('profile.eMail')"
                                      class="form-control mb-1"
                                    ></b-form-input>
                                    <!-- <span
                                      class="inputRequired"
                                      v-if="!$v.profileEmail.required"
                                    >
                                      {{ $t("profile.error.inputRequired") }}
                                    </span>-->
                                    <span
                                      class="error"
                                      v-if="!$v.profileEmail.email"
                                    >
                                      {{ $t("profile.error.emailError") }}
                                    </span>
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  v-if="
                                    chosenChangeMoreOption === 'changePhone'
                                  "
                                  class="col-sm-6"
                                >
                                  <b-form-group>
                                    <label>{{ $t("profile.telNr") }}</label>
                                    <b-form-input
                                      v-model.trim="$v.profileTelNr.$model"
                                      :placeholder="$t('profile.telNr')"
                                      class="form-control mb-1"
                                    ></b-form-input>
                                    <!--<span
                                      class="inputRequired"
                                      v-if="!$v.profileTelNr.required"
                                    >
                                      {{ $t("profile.error.inputRequired") }}
                                    </span>-->
                                    <span
                                      class="error"
                                      v-if="!$v.profileTelNr.telephoneNumber"
                                    >
                                      {{ $t("profile.error.telNrErrorFormat") }}
                                    </span>
                                    <span
                                      class="error"
                                      v-if="!$v.profileTelNr.telNrMaxLength"
                                    >
                                      {{ $t("profile.error.telNrErrorLength") }}
                                    </span>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <!-- Vita YoutubeLink -->
                              <b-row
                                v-if="
                                  currentUser.role !== EUserRoles.CLIENT &&
                                    currentUser.role !==
                                      EUserRoles.ADMINISTRATOR
                                "
                                class="mt-5"
                              >
                                <b-col class="col-12">
                                  <b-form-group>
                                    <label>{{
                                      $t("profile.youtubeLink")
                                    }}</label>
                                    <b-form-input
                                      v-model.trim="youtubeLink"
                                      :placeholder="$t('profile.youtubeLink')"
                                      class="form-control mb-1"
                                    ></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col class="col-12">
                                  <b-form-group>
                                    <label>{{ $t("profile.vita") }}</label>
                                    <b-textarea
                                      v-model.trim="vita"
                                      class="form-control mb-1"
                                    ></b-textarea>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-form>
                          </div>
                        </b-row>
                      </div>
                    </b-row>
                  </div>
                </form>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 layout-spacing">
                <b-form class="section contact">
                  <div class="info">
                    <h5 class="">{{ $t("profile.changePasswd") }}</h5>
                    <b-row>
                      <b-col class="col-md-11 mx-auto">
                        <b-row>
                          <b-col md="12">
                            <!--<b-form-group>
                              <label> {{ $t("profile.oldPasswd") }}</label>
                              <b-form-input
                                type="password"
                                id="profileOldPassword"
                                v-model="$v.profileOldPassword.$model"
                                :placeholder="$t('profile.oldPasswd')"
                                class="form-control"
                              />
                              <span
                                class="error"
                                v-if="!$v.profileOldPassword.newPasswordIsSet"
                              >
                                {{ $t("profile.error.oldPasswdError") }}
                              </span>
                            </b-form-group>-->
                            <b-form-group>
                              <label> {{ $t("profile.newPasswd") }}</label>
                              <b-form-input
                                type="password"
                                id="profileNewPassword"
                                v-model="$v.profileNewPassword.$model"
                                :placeholder="$t('profile.newPasswd')"
                                class="form-control"
                              ></b-form-input>
                              <!-- <span
                                class="error"
                                v-if="!$v.profileNewPassword.isNotOldPasswd"
                              >
                                {{ $t("profile.error.newPasswdErrorSameAs") }}
                              </span>-->
                              <span
                                class="error"
                                v-if="!$v.profileNewPassword.validPassword"
                              >
                                {{ $t("profile.error.newPasswdErrorValid") }}
                              </span>
                            </b-form-group>
                            <b-form-group>
                              <label>{{ $t("profile.newPasswdRepeat") }}</label>
                              <b-form-input
                                type="password"
                                id="profileNewPasswordRepeat"
                                v-model="$v.profileNewPasswordRepeat.$model"
                                :placeholder="$t('profile.newPasswdRepeat')"
                                class="form-control"
                              ></b-form-input>
                              <span
                                class="error"
                                v-if="
                                  !$v.profileNewPasswordRepeat.sameAsNewPassword
                                "
                              >
                                {{
                                  $t(
                                    "profile.error.newPasswdRepeatErrorNotSameAs"
                                  )
                                }}
                              </span>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-form>
              </div>
            </b-row>
          </div>
        </div>
        <div
          class="account-settings-footer"
          :class="{ 'account-settings-sidebar-open': isSidebarOpen }"
          ref="account-settings-footer"
        >
          <div class="as-footer-container">
            <b-button
              :class="isMobileScreenSize ? 'btn-sm' : ''"
              class="btn btn-warning"
              @click="resetAllInputValues"
            >
              {{ $t("profile.resetAll") }}
            </b-button>
            <b-button
              :class="isMobileScreenSize ? 'btn-sm' : ''"
              class="btn btn-primary"
              @click="saveAccountData"
              :disabled="$v.$invalid"
            >
              {{ $t("profile.submit") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <notifications classes="top-right-notification" />
  </div>
</template>

<script>
import {
  email,
  helpers,
  maxLength,
  not,
  required,
  requiredIf,
  sameAs
} from "vuelidate/lib/validators";
import Vue from "vue";
import Vuelidate from "vuelidate";
import { mapActions, mapGetters } from "vuex";
import * as userApi from "@/store/modules/users/users.api";
import { addNotification } from "@/utils/notificationHandler";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import { getCurrentHub } from "@sentry/browser";
Vue.use(Vuelidate);
const germanAlpha = helpers.regex("gerAlpha", /^[a-zA-ZäöüÄÖÜß]+$/);
const telephoneNumber = helpers.regex("phoneNumber", /^[+0][0-9+]{1,15}$/);
const validPassword = helpers.regex(
  "passwordVal",
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
);

import screenSizeMixin from "../../mixins/screenSize";

export default {
  name: "MyAccountSettings",
  title: "Profil",
  mixins: [screenSizeMixin],
  components: { vSelect },
  data() {
    return {
      EUserRoles: EUserRoles,
      currentUser: null,
      loading: false,
      loadingError: false,
      changePhoneOption: "changePhone",
      changeEmailOption: "changeEmail",
      chosenChangeMoreOption: "",
      vita: null,
      youtubeLink: null,
      profileFirstName: null,
      profileLastName: null,
      profileEmail: null,
      profileTelNr: null,
      profileOldPassword: null,
      profileNewPassword: null,
      profileNewPasswordRepeat: null,
      selectedFile: "",
      profileImage: null
    };
  },
  validations: {
    profileFirstName: { germanAlpha, required },
    profileLastName: { germanAlpha, required },
    profileEmail: { email },
    profileTelNr: {
      telephoneNumber,
      telNrMaxLength: maxLength(15)
    },
    //profileOldPassword: { newPasswordIsSet: requiredIf("profileNewPassword") },
    profileNewPassword: {
      /*isNotOldPasswd: not(sameAs("profileOldPassword")),*/
      validPassword
    },
    profileNewPasswordRepeat: {
      sameAsNewPassword: sameAs("profileNewPassword")
    }
  },
  computed: {
    ...mapGetters("sideBar", ["isSidebarOpen"]),
    ...mapGetters("users", ["getCurrentUser"]),
    optionsChangeMore() {
      const options = [
        {
          label: this.$t("profile.changeEmail"),
          code: this.changeEmailOption
        },
        {
          label: this.$t("profile.changePhone"),
          code: this.changePhoneOption
        }
      ];
      return options;
    }
  },
  methods: {
    ...mapActions("users", ["setCurrentUser", "setCurrentUserExtern"]),
    onFileSelected(e) {
      const input = e.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = e => {
          this.selectedFile = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
      /* this.selectedFile = e.target.files[0];
      if(!this.selectedFile.length){
        return;
      }
      this.createImage();
      */
    },
    removeImage() {
      this.profileImage = false;
    },
    resetAllInputValues() {
      this.profileFirstName = this.currentUser.profile.givenName; //this.$auth.user.given_name;
      this.profileLastName = this.currentUser.currentUser.profile.familyName; //this.$auth.user.family_name;
      this.profileEmail = this.currentUser.profile.email;
      this.profileTelNr = this.currentUser.profile.phoneNumber;
      this.youtubeLink = this.currentUser.companionStudentSettings.introductionVideo;
      this.vita = this.currentUser.companionStudentSettings.vita;
      this.profileOldPassword = null;
      this.profileNewPassword = null;
      this.profileNewPasswordRepeat = null;
      this.selectedFile = null;
    },
    setValidProfileData(user) {
      let profileData = {};
      profileData = {
        profile: {
          givenName: this.profileFirstName,
          familyName: this.profileLastName
        }
      };

      if (this.chosenChangeMoreOption === "changePhone") {
        profileData.profile.phoneNumber = this.profileTelNr;
      } else if (this.chosenChangeMoreOption === "changeEmail") {
        profileData.profile.email = this.profileEmail;
      }

      if (
        this.profileNewPasswordRepeat !== "" &&
        this.profileNewPasswordRepeat
      ) {
        profileData.profile.password = this.profileNewPasswordRepeat;
      }

      // Companion and Student
      if ([EUserRoles.COMPANION, EUserRoles.STUDENT].includes(user.role)) {
        profileData.companionStudentSettings = {
          vita: this.vita || "",
          introductionVideo: this.youtubeLink || ""
        };
      }

      return profileData;
    },
    saveAvatar() {
      const files = this.$refs.avatarInput.files;
      const formData = new FormData();

      // if you want to upload multiple files at once loop
      // through the array of files
      formData.append("attachment", files[0]);

      userApi.getOwnUser().then(res => {
        const user = res.data.data;
        const userId = user._id;

        if (userId) {
          userApi
            .setAvatar(userId, formData)
            .then(res => {
              addNotification(
                "success",
                this.$t("misc.success"),
                this.$t("profile.avatarUploaded")
              );
            })
            .catch(error => {
              addNotification(
                "error",
                this.$t("misc.error"),
                this.$t("profile.error.avatarUploadError")
              );
            });
        }
      });
    },

    saveAccountData() {
      this.loading = true;
      this.loadingError = false;
      userApi
        .getOwnUser()
        .then(res => {
          const user = res.data.data;
          const userId = user._id;
          if (userId) {
            const profileData = this.setValidProfileData(user);
            userApi
              .updateUser(userId, profileData)
              .then(res => {
                const user = res.data.data;
                this.setCurrentUserExtern(user);
                addNotification(
                  "success",
                  this.$t("misc.success"),
                  this.$t("profile.accountSettingsSaved")
                );
                setTimeout(() => {
                  window.location.reload();
                }, 200);
              })
              .catch(err => {
                addNotification(
                  "error",
                  this.$t("misc.error"),
                  this.$t("profile.error.somethingWentWrong")
                );
              });
          }
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
      this.profileFirstName = this.currentUser.profile.givenName;
      this.profileLastName = this.currentUser.profile.familyName;
      this.profileEmail = this.currentUser.profile.email;
      this.profileTelNr = this.currentUser.profile.phoneNumber;
      if (this.currentUser.companionStudentSettings) {
        if (this.currentUser.companionStudentSettings.vita) {
          this.vita = this.currentUser.companionStudentSettings.vita;
        }
        if (this.currentUser.companionStudentSettings.introductionVideo) {
          this.youtubeLink = this.currentUser.companionStudentSettings.introductionVideo;
        }
      }
    }
  }
};
</script>

<style scoped></style>
