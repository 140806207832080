import { render, staticRenderFns } from "./BookingSelectionGoM.vue?vue&type=template&id=34ba9e1a&"
import script from "./BookingSelectionGoM.vue?vue&type=script&lang=js&"
export * from "./BookingSelectionGoM.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports