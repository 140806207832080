var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentUser)?_c('div',[(_vm.currentUser.role !== _vm.EUserRoles.CLIENT)?_c('b-dropdown-item',{attrs:{"to":"profile"}},[_c('div',{staticClass:"user-profile-section"},[_c('div',{staticClass:"media mx-auto"},[_c('img',{staticClass:"img-fluid mr-2 max-width-profile-picture-small",attrs:{"src":_vm.$auth.user.picture,"alt":"avatar"}}),_c('div',{staticClass:"media-body"},[_c('h5',{staticClass:"max-width-user-profile-dropdown break-word"},[_vm._v(" "+_vm._s(_vm.$auth.user.name)+" ")])])])])]):_vm._e(),_c('b-dropdown-item',{class:_vm.currentUser.role === _vm.EUserRoles.CLIENT ? 'pt-4':'',attrs:{"to":"profile"}},[_c('div',{staticClass:"dropdown-item"},[_c('a',{attrs:{"to":"profile"}},[_c('img',{staticClass:"feathericons-user p-3 mr-2 bg-dark"}),_c('span',[_vm._v(_vm._s(_vm.$t("actions.profile")))])])])]),(_vm.currentUser)?_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.sidebarElements),function(el){return _c('li',{key:`${el.id}`,staticClass:"menu",class:{
        active:
          (_vm.selectedParentSidebarElement === el.id &&
            _vm.viewingParentSidebarElement === '') ||
          _vm.viewingParentSidebarElement === el.id
      }},[_c('b-dropdown-item',[(
            el &&
               (el.id !== 'conversations' && el.id !== 'soulLetters') &&
              (_vm.currentUser.role === _vm.EUserRoles.ADMINISTRATOR && !_vm.getUserId)
          )?_c('a',{staticClass:"dropdown-toggle",attrs:{"rel":"noopener noreferrer","target":"_blank"},on:{"click":function($event){return _vm.navigateTo(el.to)}}},[_c('b-img',{staticClass:"p-3 mr-2 bg-dark",class:el.icon}),_c('span',[_vm._v(_vm._s(el.title))])],1):(  el && (el.id !== 'kingOfTheCastle' && el.id !== 'loginAs' && el.id !== 'vouchers' && el.id !== 'unpaidConversations') &&
              _vm.currentUser.role !== _vm.EUserRoles.ADMINISTRATOR)?_c('a',{staticClass:"dropdown-toggle",attrs:{"rel":"noopener noreferrer","target":"_blank"},on:{"click":function($event){return _vm.navigateTo(el.to)}}},[_c('b-img',{staticClass:"p-3 mr-2 bg-dark",class:el.icon}),_c('span',{},[_vm._v(_vm._s(el.title))])],1):_vm._e()])],1)}),0):_vm._e(),_c('b-dropdown-item',[_c('div',{staticClass:"dropdown-item"},[_c('a',{on:{"click":_vm.logout}},[_c('img',{staticClass:"feathericons-log-out p-3 mr-2 bg-dark"}),_c('span',[_vm._v(_vm._s(_vm.$t("actions.logout")))])])])]),(_vm.isMobileScreenSize)?_c('div',{staticClass:"border-bottom border-grey"}):_vm._e(),_c('b-dropdown-item',[(_vm.isMobileScreenSize)?_c('div',{staticClass:"dropdown-item"},[_c('a',{on:{"click":function($event){return _vm.backToHomePage()}}},[_c('img',{staticClass:"feathericons-chevron-left p-3 mr-2 bg-dark"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("header.backToHomepage")))])])]):_vm._e()]),_c('b-dropdown-item',[(_vm.isMobileScreenSize && _vm.getUserId)?_c('div',{staticClass:"dropdown-item"},[_c('a',{on:{"click":function($event){return _vm.setImpersonateUserId(null)}}},[_c('img',{staticClass:"feathericons-chevron-left p-3 mr-2 bg-dark"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("loginAs.buttons.logoutToOwnUser")))])])]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }