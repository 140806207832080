var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.soulLetter.status === 'draft' && _vm.currentUser)?_c('div',[_c('span',{staticClass:"shadow-none badge badge-info"},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.status.draft"))+" ")])]):(_vm.soulLetter.status === 'unpaid' && _vm.currentUser)?_c('div',[(
      _vm.soulLetter.hasOwnProperty('recipient') &&
        _vm.soulLetter.hasOwnProperty('sender')
    )?_c('div',[(
        _vm.currentUser._id === _vm.soulLetter.recipient._id ||
          _vm.currentUser.role === _vm.EUserRoles.ADMINISTRATOR
      )?_c('span',{staticClass:"shadow-none badge badge-info"},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.status.draft"))+" ")]):(_vm.currentUser._id === _vm.soulLetter.sender._id)?_c('span',{staticClass:"shadow-none badge badge-danger"},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.status.notYetPaid"))+" ")]):_vm._e()]):_vm._e()]):(_vm.soulLetter.status === 'submitted' && _vm.currentUser)?_c('div',[(
      _vm.soulLetter.hasOwnProperty('recipient') &&
        _vm.soulLetter.hasOwnProperty('sender')
    )?_c('div',[(
        _vm.currentUser._id === _vm.soulLetter.recipient._id ||
          _vm.currentUser.role === _vm.EUserRoles.ADMINISTRATOR
      )?_c('span',{staticClass:"shadow-none badge badge-warning"},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.status.delivered"))+" ")]):(_vm.currentUser._id === _vm.soulLetter.sender._id)?_c('span',{staticClass:"shadow-none badge badge-warning"},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.status.submitted"))+" ")]):_vm._e()]):_vm._e()]):(_vm.soulLetter.status === 'reviewed' && _vm.currentUser)?_c('div',[(
      _vm.soulLetter.hasOwnProperty('recipient') &&
        _vm.soulLetter.hasOwnProperty('sender')
    )?_c('div',[(
        _vm.currentUser._id === _vm.soulLetter.recipient._id ||
          _vm.currentUser.role === _vm.EUserRoles.ADMINISTRATOR
      )?_c('div',[(
          _vm.soulLetter.latestRevision.createTimeStamp ===
            _vm.soulLetter.latestRevision.updateTimeStamp
        )?_c('span',{staticClass:"shadow-none badge badge-success"},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.status.reviewed"))+" ")]):(
          _vm.soulLetter.latestRevision.createTimeStamp !==
            _vm.soulLetter.latestRevision.updateTimeStamp
        )?_c('span',{staticClass:"shadow-none badge badge-success"},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.status.clientWrites"))+" ")]):_vm._e()]):(_vm.currentUser._id === _vm.soulLetter.sender._id)?_c('div',[(
          _vm.soulLetter.latestRevision.createTimeStamp ===
            _vm.soulLetter.latestRevision.updateTimeStamp
        )?_c('span',{staticClass:"shadow-none badge badge-success"},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.status.reviewed"))+" ")]):(
          _vm.soulLetter.latestRevision.createTimeStamp !==
            _vm.soulLetter.latestRevision.updateTimeStamp
        )?_c('span',{staticClass:"shadow-none badge badge-success"},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.status.inProgress"))+" ")]):_vm._e()]):_vm._e()]):_vm._e()]):(_vm.soulLetter.status === 'completed')?_c('span',{staticClass:"shadow-none badge badge-primary"},[_vm._v(" "+_vm._s(_vm.$t("soulLetters.status.completed"))+" ")]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }