import * as userApi from "./users.api";
import * as Sentry from "@sentry/vue";

export default {
  namespaced: true,
  state: {
    currentUser:
      localStorage.getItem("user") != null
        ? JSON.parse(localStorage.getItem("user"))
        : null
  },
  getters: {
    getCurrentUser: state => state.currentUser
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    }
  },
  actions: {
    clearCurrentUser({ commit, dispatch, state }) {
      localStorage.removeItem("user");
      Sentry.setUser(null);
      commit("setCurrentUser", undefined);
    },
    setCurrentUser({ commit, dispatch, state }) {
      userApi.getOwnUser().then(res => {
        const user = res.data.data;
        localStorage.setItem("user", JSON.stringify(user));
        Sentry.setUser({
          id: user._id,
          ...(user.profile
            ? {
                username: user.profile.name
                  ? user.profile.name
                  : user.profile.email,
                email: user.profile.email
              }
            : {})
        });
        commit("setCurrentUser", res.data.data);
      });
    },
    setCurrentUserExtern({ commit, dispatch, state }, payload) {
      localStorage.setItem("user", JSON.stringify(payload));
      Sentry.setUser({
        id: payload._id,
        ...(payload.profile
          ? {
              username: payload.profile.name
                ? payload.profile.name
                : payload.profile.email,
              email: payload.profile.email
            }
          : {})
      });
      commit("setCurrentUser", payload);
    }
  },
  modules: {}
};
