export default {
  namespaced: true,
  state: {
    isEditSoulLetter: false
  },
  getters: {
    getIsEditSoulLetter: state => state.isEditSoulLetter
  },
  mutations: {
    isEditSoulLetter(state, payload) {
      state.isEditSoulLetter = payload;
    }
  },
  actions: {
    setIsEditSoulLetter({ commit, dispatch, state }, payload) {
      commit("isEditSoulLetter", payload);
    }
  },
  modules: {}
};
