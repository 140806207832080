import apiClient from "../../../../auth/apiClient";

export const getAllTimeSlots = (userId,data) => {
  return apiClient.get(`/users/${userId}/timeSlots`, data);
};

export const createTimeSlot = (userId, data) => {
  return apiClient.post(`/users/${userId}/timeSlots`, data);
};

export const getTimeSlot = (userId, timeSlotId) => {
  return apiClient.get(`/users/${userId}/timeSlots/${timeSlotId}`);
};

export const updateTimeSlot = (userId, timeSlotId, data) => {
  return apiClient.put(`/users/${userId}/timeSlots/${timeSlotId}`, data);
};

export const deleteTimeSlot = (userId, timeSlotId) => {
  return apiClient.delete(`/users/${userId}/timeSlots/${timeSlotId}`);
};
