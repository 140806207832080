<template>
  <div id="content" class="main-content">
    <div class="m-4">
      <b-row class="layout-spacing layout-top-spacing" id="cancel-row">
        <div class="col-xl-12 col-sm-12 layout-spacing">
          <div class="statbox widget box box-shadow">
            <div class="widget-header">
              <b-row>
                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                  <h4>{{ $t("conversations.myConversations") }}</h4>
                </div>
              </b-row>
            </div>
            <div class="widget-content widget-content-area">
              <div v-if="loading">
                <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                  <div
                    class="spinner-border text-success align-self-center loader-lg"
                  />
                </div>
              </div>
              <div v-else-if="loadingError" class="cm-loading-error m-4">
                {{ $t("calendarModule.errors.loadingError") }}
              </div>
              <div v-else>
                <div v-if="currentUser.role === EUserRoles.ADMINISTRATOR">
                  <h6 class="font-weight-bold color-primary">
                    {{ $t("conversations.notForAdmin") }}
                  </h6>
                </div>
                <div
                  v-if="
                    currentUser.role === EUserRoles.COMPANION ||
                      currentUser.role === EUserRoles.STUDENT
                  "
                  class="d-flex flex-row justify-flex-end"
                  :class="isMobileScreenSize ? 'mt-4' : ''"
                >
                  <div
                    @click="openEditCalendarModal()"
                    class="mb-5 mt-n6 mr-5 d-flex flex-row justify-flex-end cursor-pointer btn bg-transparent shadow-none border-0"
                  >
                    <img class="feathericons-settings p-3 bg-primary" />
                    <span
                      class="font-weight-bold font-size-middle ml-2 color-primary"
                      >{{ $t("conversations.editCalendar") }}</span
                    >
                  </div>
                </div>
                <p
                  v-if="
                    currentUser.role === EUserRoles.COMPANION ||
                      currentUser.role === EUserRoles.STUDENT
                  "
                  class="mb-5"
                >
                  {{ $t("conversations.descriptionForList") }}
                </p>
                <button
                  v-if="currentUser.role === EUserRoles.CLIENT"
                  @click="navigateTo('companions')"
                  class="btn btn-primary mb-4"
                >
                  {{ $t("conversations.scheduleNewConversation") }}
                  <img class="feathericons-plus-circle p-3 ml-1 bg-white" />
                </button>
                <button
                  v-if="currentUser.role === EUserRoles.CLIENT"
                  @click="openStatusInformationModal()"
                  class="btn btn-outline-primary mb-4 ml-3"
                >
                  {{ $t("soulLetters.status.titleInformation") }}
                </button>
                <div v-if="currentUser.role === EUserRoles.CLIENT">
                  <div
                    v-if="userHasAppointmentsScheduled"
                    class="table-responsive mt-5"
                  >
                    <perfect-scrollbar>
                      <ConversationsTable
                        id="conversationTable"
                        :current-user="currentUser"
                        :sorted-conversations="sortedConversations"
                        :is-mobile-screen-size="isMobileScreenSize"
                        :is-modal-mobile-screen-size="isModalMobileScreenSize"
                        :is-tablet-screen-size="isTabletScreenSize"
                        :is-sm-desktop-screen-size="isSmDesktopScreenSize"
                        :open-conversation-details-modal="
                          openConversationDetailsModal
                        "
                        :open-edit-appointment-modal="openEditAppointmentModal"
                        :open-leave-appointment-modal="
                          openLeaveAppointmentModal
                        "
                        :open-transaction-maybe-paid-modal="
                          openTransactionMaybePaidModal
                        "
                        :open-cancel-appointment-modal="
                          openCancelAppointmentModal
                        "
                      />
                    </perfect-scrollbar>
                  </div>
                  <div v-else class="text-center mt-3">
                    <h5>{{ $t("conversations.noConversationsScheduled") }}</h5>
                  </div>
                </div>
                <div v-if="currentUser">
                  <SlotList
                    v-if="
                      currentUser.role !== EUserRoles.CLIENT &&
                        currentUser.role !== EUserRoles.ADMINISTRATOR
                    "
                    :with-edit="true"
                    :edit-in-list="true"
                    :is-front-page="true"
                    :key="isCalendarChanged"
                    @calendarChangedSuccess="calendarChangedSuccess"
                    @calendarChangedError="calendarChangedError"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </div>
    <!-- Leave Appointment Modal -->
    <b-modal
      ref="leaveAppointmentModal"
      size="xl"
      @hide="isLeaveAppointment = false"
      :title="$t('calendarModule.modalTitles.leaveAppointment')"
      hide-footer
    >
      <b-row>
        <b-col>
          <p>{{ $t("conversations.gomLeaveDescription") }}</p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <button
            @click="commitLeaveAppointmentModal"
            class="btn btn-outline-danger mb-2 mr-2"
          >
            {{ $t("conversations.gomLeave") }}
          </button>
          <button
            @click="closeLeaveAppointmentModal"
            class="btn btn-outline-primary mb-2 mr-2"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Cancel Appointment Modal -->
    <b-modal
      ref="cancelAppointmentModal"
      size="xl"
      @hide="isCancelAppointment = false"
      :title="$t('calendarModule.modalTitles.cancelAppointment')"
      hide-footer
    >
      <b-row>
        <b-col>
          <p>{{ $t("conversations.cancelAppointmentDescription") }}</p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <button
            @click="commitCancelAppointmentModal"
            class="btn btn-outline-danger mb-2 mr-2"
          >
            {{ $t("conversations.cancelAppointment") }}
          </button>
          <button
            @click="closeCancelAppointmentModal"
            class="btn btn-outline-primary mb-2 mr-2"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Edit Appointment Modal -->
    <b-modal
      ref="editAppointmentModal"
      size="xl"
      @hide="isEditAppointment = false"
      :title="$t('calendarModule.modalTitles.editAppointment')"
      hide-footer
    >
      <h5
        v-if="
          (isSlotMinBookingHoursAbove && isSlotMinBookingToday) ||
            isSlotMinBookingPastDays
        "
        class="text-center font-weight-bold color-danger"
      >
        {{ $t("conversations.error.minBookingSlotDurationAbove") }}
      </h5>
      <div v-else>
        <div v-if="conversationForClientAction">
          <BookingSelectionCalendar
            :companion-for-edit="conversationForClientAction.host"
            :is-edit-appointment="true"
            :edit-appointment-chosen-conversation="conversationForClientAction"
            :key="isReloadBookingSelection"
            @conversationSavedSuccess="conversationSavedSuccess"
            @conversationSavedError="conversationSavedError"
          />
        </div>
      </div>
    </b-modal>
    <!-- Edit Calendar Modal -->
    <b-modal
      ref="editCalendarModal"
      size="xl"
      @hide="calendarFormChanged"
      :title="$t('calendarModule.modalTitles.editCalendar')"
      hide-footer
    >
      <CalendarModule />
    </b-modal>
    <!-- Conversation  Details Modal -->
    <b-modal
      :title="$t('soulLetter.historyTitle')"
      ref="conversationDetailsModal"
      size="lg"
      hide-footer
      onclose="closeConversationDetailsModal()"
    >
      <b-row>
        <b-col>
          <ConversationSummary :conversation-slot="chosenHistoryConversation" />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <button
            @click="closeConversationDetailsModal"
            class="btn btn-primary mb-2 mr-2"
          >
            {{ $t("misc.close") }}
          </button>
        </b-col>
      </b-row>
    </b-modal>
    <!-- Transaction Maybe Paid Modal -->
    <b-modal
      :title="$t('conversations.maybePaidModal.title')"
      ref="transactionMaybePaidModal"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      onclose="closeTransactionMaybePaidModal()"
    >
      <b-row class="mb-4">
        <b-col>
          <h6 class="color-primary">
            {{ $t("conversations.maybePaidModal.paid") }}
          </h6>
          <p class="font-weight-bold">
            {{ $t("conversations.maybePaidModal.descriptionPaid") }}
          </p>
          <h6 class="color-danger">
            {{ $t("conversations.maybePaidModal.notPaid") }}
          </h6>
          <p class="font-weight-bold">
            {{ $t("conversations.maybePaidModal.descriptionNotPaid") }}
          </p>
        </b-col>
      </b-row>
      <button @click="payForConversation()" class="btn btn-success mb-2 mr-2">
        {{ $t("misc.pay") }}
      </button>
      <button
        @click="closeTransactionMaybePaidModal()"
        class="btn btn-outline-success mb-2 mr-2"
      >
        {{ $t("misc.close") }}
      </button>
    </b-modal>
    <!-- Status Information Modal -->
    <b-modal
      :title="$t('soulLetters.status.titleInformation')"
      ref="statusInformationModal"
      hide-footer
      size="md"
      onclose="closeStatusInformationModal()"
    >
      <table
        v-if="currentUser.role === EUserRoles.CLIENT"
        class="table-vertical-align"
      >
        <tbody>
          <tr>
            <td class="p-2">
              <b-badge class="bg-warning font-size-2 shadow-none">
                {{ $t("conversations.status.scheduled") }}
              </b-badge>
            </td>
            <td class="p-2">
              <span>
                {{ $t("conversations.status.scheduledExplanationClient") }}
              </span>
            </td>
          </tr>
          <tr class="border-top border-grey">
            <td class="p-2">
              <b-badge class="bg-danger shadow-none">
                {{ $t("conversations.status.unpaidCompanion") }}
              </b-badge>
            </td>
            <td class="p-2">
              <span>
                {{
                  $t("conversations.status.unpaidCompanionExplanationClient")
                }}
              </span>
            </td>
          </tr>
          <tr class="border-top border-grey">
            <td class="p-2">
              <b-badge class="bg-success shadow-none">
                {{ $t("conversations.status.completed") }}
              </b-badge>
            </td>
            <td class="p-2">
              <span>
                {{ $t("conversations.status.completedExplanationClient") }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        @click="closeStatusInformationModal()"
        class="btn btn-outline-success mb-2 mr-2 mt-4"
      >
        {{ $t("misc.close") }}
      </button>
    </b-modal>
    <div v-if="conversations">
      <div
        v-if="conversations.length"
        v-observe-visibility="handleScrolledToBottom"
      ></div>
    </div>
  </div>
</template>

<script>
import CalendarModule from "../../components/calendarModule/CalendarModule";
import moment from "moment";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import { mapActions, mapGetters } from "vuex";
import * as cmApi from "@/store/modules/calendarModule/calendarModule.api";
import * as conversationApi from "@/store/modules/conversations/conversations.api";
import * as usersApi from "@/store/modules/users/users.api";
import { convertToLocal } from "@/utils/timezoneHelper";
import ConversationSummary from "../../components/conversations/ConversationSummary";
import ConversationsTable from "@/components/conversations/ConversationsTable";
import screenSizeMixin from "@/mixins/screenSize";

export default {
  name: "Conversations",
  title: "Einzelgespräche",
  components: {
    ConversationsTable,
    ConversationSummary,
    CalendarModule,
    SlotList: () => import("../../components/calendarModule/common/SlotList"),
    BookingSelectionCalendar: () => import("./BookingSelectionCalendar")
  },
  mixins: [screenSizeMixin],
  data() {
    return {
      scrolledToBottom: false,
      offset: 0,
      limit: 20,
      totalAvailableConversations: 0,
      currentBuyUrl: null,
      unpaidInterval: 120000, // 5sec / 120000, // 2min
      isUnpaidDisableButton: false,
      chosenHistoryConversation: null,
      isReloadBookingSelection: false,
      loading: false,
      loadingError: false,
      conversationForClientAction: null,
      isCalendarChanged: false,
      isSlotMinBookingHoursAbove: false,
      isSlotMinBookingToday: false,
      isSlotMinBookingPastDays: false,
      massModalSlots: null,
      chosenRecipient: null,
      conversationSubject: null,
      chosenSlot: null,
      conversations: null,
      EUserRoles,
      moment,
      cmApi: cmApi,
      currentUser: null,
      isEditAppointment: false,
      isLeaveAppointment: false,
      isCancelAppointment: false,
      leaveAppointmentObj: null,
      cancelAppointmentObj: null,
      userHasAppointmentsScheduled: false,
      dummyBookedAppointment: {
        date: "27.06.2021",
        fromTime: "13:00",
        toTime: "14:00",
        description: "Beschreibung 123"
      }
    };
  },
  computed: {
    ...mapGetters("users", ["getCurrentUser"]),
    sortedConversations() {
      const conversations = this.conversations;
      const sortedArray = conversations.sort(
        (a, b) =>
          parseInt(
            moment(
              b.appointment.date + b.appointment.time,
              "YYYY-MM-DD HH:mm"
            ).format("YYYYMMDDHHmm")
          ) -
          parseInt(
            moment(
              a.appointment.date + a.appointment.time,
              "YYYY-MM-DD HH:mm"
            ).format("YYYYMMDDHHmm")
          )
        // moment(a.appointment.date).format("YYYYMMDD") -
        // moment(b.appointment.date).format("YYYYMMDD")
      );
      return sortedArray;
    }
  },
  methods: {
    ...mapActions("users", ["setCurrentUser"]),
    scroll() {
      window.onscroll = () => {
        const conversationTable = document.querySelector("#conversationTable");
        const bottomOfWindow = window.pageYOffset >= window.innerHeight;
        if (conversationTable) {
          if (
            window.innerHeight + (window.scrollY - 350) >=
            conversationTable.clientHeight
          ) {
            // alert("near bottom!");
          }
        }

        if (bottomOfWindow) {
          this.scrolledToBottom = true; // replace it with your code
        }
      };
    },
    handleScrolledToBottom(isVisible) {
      if (!isVisible) {
        return;
      }
      if (this.conversations.length >= this.totalAvailableConversations) {
        return;
      } else {
        setTimeout(() => {
          this.listConversations(true);
        }, 0);
      }
      //this.page++;
    },
    closeStatusInformationModal() {
      this.$refs["statusInformationModal"].hide();
    },
    openStatusInformationModal() {
      this.$refs["statusInformationModal"].show();
    },
    closeTransactionMaybePaidModal() {
      this.currentBuyUrl = null;
      this.listConversations();
      this.$refs["transactionMaybePaidModal"].hide();
    },
    openTransactionMaybePaidModal(buyUrl) {
      this.currentBuyUrl = buyUrl;
      this.$refs["transactionMaybePaidModal"].show();
    },
    openConversationDetailsModal(conversation) {
      this.chosenHistoryConversation = conversation;
      this.$refs["conversationDetailsModal"].show();
    },
    closeConversationDetailsModal() {
      this.chosenHistoryConversation = null;
      this.$refs["conversationDetailsModal"].hide();
    },
    calendarChangedSuccess() {
      this.isCalendarChanged = !this.isCalendarChanged;
      this.listConversations();
    },
    calendarChangedError() {
      this.isCalendarChanged = !this.isCalendarChanged;
      this.listConversations();
    },
    conversationSavedSuccess() {
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
      this.listConversations();
      this.$refs["editAppointmentModal"].hide();
    },
    conversationSavedError() {
      this.isReloadBookingSelection = !this.isReloadBookingSelection;
      this.listConversations();
      this.$refs["editAppointmentModal"].hide();
    },
    calendarFormChanged() {
      this.isCalendarChanged = !this.isCalendarChanged;
    },
    slotsChanged() {
      this.listConversations();
      this.$refs["editAppointmentModal"].hide();
    },
    joinZoomConversation(conversation) {
      window.open(conversation.zoomMeeting.joinUrl);
    },
    isShowZoomMeetingTimeCorrect(conversation) {
      const today = moment().format("YYYY-MM-DD");
      const toDayTime = moment().format("HH:mm");
      const toDayTimeFormatted = moment(toDayTime, "HH:mm");

      if (moment(conversation.appointment.date).diff(moment(today)) >= 0) {
        if (
          moment(conversation.appointment.time, "HH:mm").diff(
            toDayTimeFormatted
          ) > -1200000 &&
          moment(conversation.appointment.time, "HH:mm").diff(
            toDayTimeFormatted
          ) < 0
        ) {
          // 1200000 ms === 20 min
          // 3600000 ms === 60 min
          return true;
        }
      }
      return false;
    },
    openEditAppointmentModal(conversation) {
      this.isEditAppointment = true;
      const formattedMassModalSlots = {
        _id: conversation.appointment._id,
        date: conversation.appointment.date,
        endDate: conversation.appointment.endDate,
        time: conversation.appointment.time,
        endTime: conversation.appointment.endTime,
        free: false
      };

      const today = moment();
      const todayTime = moment(today).format("HH:mm");
      const todayDate = moment().format("YYYY-MM-DD");
      const todayDateMoment = moment(todayDate);
      const slotDateMoment = moment(formattedMassModalSlots.date);
      const subtractedTime = moment(formattedMassModalSlots.time, "HH:mm")
        .subtract("30", "minutes")
        .format("HH:mm");

      this.isSlotMinBookingHoursAbove =
        moment(todayTime, "HH:mm").diff(moment(subtractedTime, "HH:mm")) >= 0;
      this.isSlotMinBookingToday =
        moment(slotDateMoment, "YYYY-MM-DD").diff(
          moment(todayDateMoment, "YYYY-MM-DD")
        ) === 0;
      this.isSlotMinBookingPastDays =
        moment(slotDateMoment, "YYYY-MM-DD").diff(todayDateMoment) < 0;

      this.massModalSlots = [formattedMassModalSlots];
      this.conversationForClientAction = conversation;

      this.$refs["editAppointmentModal"].show();
    },
    openLeaveAppointmentModal(conversation) {
      this.leaveAppointmentObj = conversation;
      this.isLeaveAppointment = true;
      this.$refs["leaveAppointmentModal"].show();
    },
    openCancelAppointmentModal(conversation) {
      console.log("OPENING!");
      this.cancelAppointmentObj = conversation;
      this.isCancelAppointment = true;
      this.$refs["cancelAppointmentModal"].show();
    },
    commitLeaveAppointmentModal() {
      conversationApi
        .leaveConversation(this.leaveAppointmentObj._id)
        .then(() => {
          this.setCurrentUser();
          this.closeLeaveAppointmentModal();
          this.$router.push("/dashboard");
        })
        .catch(() => {
          this.closeLeaveAppointmentModal();
        })
        .finally(() => {
          this.conversations = [];
          this.listConversations();
        });
    },
    closeLeaveAppointmentModal() {
      this.leaveAppointmentObj = null;
      this.isLeaveAppointment = false;
      this.$refs["leaveAppointmentModal"].hide();
    },
    commitCancelAppointmentModal() {
      conversationApi
        .cancelConversation(this.cancelAppointmentObj._id)
        .then(() => {
          this.setCurrentUser();
          this.closeCancelAppointmentModal();
          this.$router.push("/dashboard");
        })
        .catch(() => {
          this.closeCancelAppointmentModal();
        })
        .finally(() => {
          this.conversations = [];
          this.listConversations();
        });
    },
    closeCancelAppointmentModal() {
      this.cancelAppointmentObj = null;
      this.isCancelAppointment = false;
      this.$refs["cancelAppointmentModal"].hide();
    },
    closeEditAppointmentModal() {
      this.isEditAppointment = false;
      this.$refs["editAppointmentModal"].hide();
    },
    openEditCalendarModal() {
      this.$refs["editCalendarModal"].show();
    },
    closeEditCalendarModal() {
      this.$refs["editCalendarModal"].hide();
    },
    getAllTimeSlots() {
      this.loading = true;
      this.loadingError = false;
      cmApi.slot
        .getAllTimeSlots(this.currentUser._id)
        .then(res => {
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    payForConversation(destination) {
      if (this.currentBuyUrl) {
        window.location.replace(this.currentBuyUrl);
      }
      // window.location.replace(destination);
    },
    navigateTo(destination) {
      if (this.$route.path.includes(destination)) {
        this.$router.go();
      } else {
        this.$router.push(destination).catch(err => {
          return;
        });
      }
    },
    getSlot(slot) {
      this.loading = true;
      this.loadingError = false;
      cmApi.slot
        .getTimeSlot(this.currentUser._id, slot)
        .then(res => {
          const timeSlot = res.data.data;
          this.loading = false;
          this.loadingError = false;
          this.chosenSlot = timeSlot;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    listConversations(isPagination) {
      // this.loading = true;
      //this.loadingError = false;
      if (!isPagination) {
        this.offset = 0;
      } else {
        this.offset = this.offset + this.limit;
      }
      conversationApi
        .listConversations({
          params: {
            limit: this.limit,
            offset: this.offset,
            sortField: "updateTimeStamp",
            sortDir: "desc"
          }
        })
        .then(res => {
          const conversations = res.data.data;
          const pagination = res.data.pagination;
          if (conversations.length > 0) {
            this.userHasAppointmentsScheduled = true;
            if (!isPagination) {
              this.conversations = [];
            }
            this.totalAvailableConversations = pagination.total;

            conversations.forEach(slot => {
              const convertUtc = convertToLocal(
                slot.appointment.date,
                slot.appointment.endDate,
                slot.appointment.time,
                slot.appointment.endTime
              );
              const appointment = {
                date: convertUtc.date,
                endDate: convertUtc.endDate,
                time: convertUtc.time,
                endTime: convertUtc.endTime,
                _id: slot.appointment._id,
                metadata: slot.appointment.metadata
              };

              let zoomMeeting = null;
              if (slot.zoomMeeting) {
                zoomMeeting = {
                  _id: slot.zoomMeeting._id,
                  startUrl: slot.zoomMeeting.startUrl,
                  joinUrl: slot.zoomMeeting.joinUrl,
                  duration: slot.zoomMeeting.duration,
                  recordingReceived: slot.zoomMeeting.recordingReceived
                };
              }

              let payload;
              if (slot.transaction) {
                payload = {
                  transaction: slot.transaction,
                  zoomMeeting: zoomMeeting,
                  appointment: appointment,
                  attendees: slot.attendees,
                  createTimeStamp: slot.createTimeStamp,
                  host: slot.host,
                  status: slot.status,
                  subject: slot.subject,
                  updateTimeStamp: slot.updateTimeStamp,
                  type: slot.type,
                  _id: slot._id
                };
              } else {
                payload = {
                  zoomMeeting: zoomMeeting,
                  appointment: appointment,
                  attendees: slot.attendees,
                  createTimeStamp: slot.createTimeStamp,
                  host: slot.host,
                  status: slot.status,
                  subject: slot.subject,
                  updateTimeStamp: slot.updateTimeStamp,
                  type: slot.type,
                  _id: slot._id
                };
              }
              this.conversations.push(payload);
            });
          }
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    },
    isUnpaidButtonTimeout(conversation) {
      const timeStampDiff = moment(conversation.updateTimeStamp).diff(moment());
      if (timeStampDiff > -this.unpaidInterval) {
        // 2min
        return true;
      } else {
        return false;
      }
    },
    initUnpaidButtonTimeout() {
      this.isUnpaidDisableButton = true;
      setTimeout(() => {
        this.isUnpaidDisableButton = false;
        this.listConversations();
      }, this.unpaidInterval);
    }
  },
  mounted() {
    this.initUnpaidButtonTimeout();
    this.scroll();
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    if (this.currentUser.role !== EUserRoles.ADMINISTRATOR) {
      this.listConversations();
    }
  }
};
</script>
