<template>
  <div id="content" class="main-content">
    <div class="m-4">
      <div class="row layout-spacing layout-top-spacing" id="cancel-row">
        <div class="col-xl-12 col-sm-12 layout-spacing">
          <div class="statbox widget box box-shadow">
            <div class="widget-header">
              <b-row>
                <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                  <h4>{{ $t("loginAs.title") }}</h4>
                </div>
              </b-row>
            </div>
            <div class="widget-content widget-content-area">
              <div v-if="loading">
                <div class="d-flex justify-content-center mx-5 mt-3 mb-5">
                  <div
                    class="spinner-border text-success align-self-center loader-lg"
                  />
                </div>
              </div>
              <div v-else-if="loadingError" class="cm-loading-error">
                {{ $t("calendarModule.errors.loadingError") }}
              </div>
              <div v-else>
                <div v-if="getUserId">
                  <h6 class="font-weight-bold">
                    {{ $t("loginAs.alreadyLoggedIn") }}
                  </h6>
                </div>
                <div v-else>
                  <b-row>
                    <b-col>
                      <p>{{ $t("loginAs.description") }}</p>
                    </b-col>
                  </b-row>
                  <b-row v-if="!isMobileScreenSize">
                    <b-col>
                      <b-form-group
                        :description="
                          $t('loginAs.searchField.descriptions.search')
                        "
                      >
                        <template v-slot:label>
                          {{ $t("loginAs.searchField.labels.search") }}
                        </template>
                        <b-input v-model="searchText" />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        :description="
                          $t('loginAs.searchField.descriptions.filterRole')
                        "
                      >
                        <template v-slot:label>
                          {{ $t("loginAs.searchField.labels.filterRole") }}
                        </template>
                        <v-select
                          :options="userRoleOptions"
                          v-model="userRoleOption"
                          :reduce="o => o.code"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col>
                      <b-form-group
                        :description="
                          $t('loginAs.searchField.descriptions.search')
                        "
                      >
                        <template v-slot:label>
                          {{ $t("loginAs.searchField.labels.search") }}
                        </template>
                        <b-input v-model="searchText" />
                      </b-form-group>
                      <b-form-group
                        :description="
                          $t('loginAs.searchField.descriptions.filterRole')
                        "
                      >
                        <template v-slot:label>
                          {{ $t("loginAs.searchField.labels.filterRole") }}
                        </template>
                        <v-select
                          :options="userRoleOptions"
                          v-model="userRoleOption"
                          :reduce="o => o.code"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="mt-5" v-if="auth0Users && currentUser">
                    <b-col>
                      <div class="table-responsive">
                        <perfect-scrollbar>
                          <LoginAsTable
                            :sorted-auth0-users="sortedAuth0Users"
                            :set-impersonate-user-id="setImpersonateUserId"
                          />
                        </perfect-scrollbar>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as userApi from "@/store/modules/users/users.api";
import { EUserRoles } from "@/store/modules/users/EUserRoles";
import * as moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import {displayUsernameToEmail} from "../../utils/userHelper";
import LoginAsTable from "@/components/admin/LoginAsTable";
import screenSizeMixin from "@/mixins/screenSize";


export default {
  name: "LoginAs",
  title: "Einloggen als anderer Benutzer",
  components: {LoginAsTable, vSelect },
  mixins: [screenSizeMixin],
  data() {
    return {
      displayUsernameToEmail,
      moment,
      loading: false,
      loadingError: false,
      auth0Users: null,
      searchText: "",
      currentUser: null,
      userRoleOption: null
    };
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"]),
    ...mapGetters("loginAs",["getUserId"]),
    userRoleOptions() {
      return [
        {
          label: this.$t("kingOfTheCastle.companions"),
          code: EUserRoles.COMPANION
        },
        {
          label: this.$t("kingOfTheCastle.students"),
          code: EUserRoles.STUDENT
        },
        {
          label: this.$t("kingOfTheCastle.clients"),
          code: EUserRoles.CLIENT
        },
        {
          label: this.$t("loginAs.showAll"),
          code: null
        }
      ];
    },
    sortedAuth0Users() {
      const searchParts = this.searchText
        .toLowerCase()
        .trim()
        .split(" ");
      let hasParts = false;
      for (const searchPart of searchParts) {
        if (searchPart) {
          hasParts = true;
        }
      }
      const aut0Companions = this.auth0Users.filter(
        user => user.role === EUserRoles.COMPANION
      );
      const auth0Students = this.auth0Users.filter(
        user => user.role === EUserRoles.STUDENT
      );
      const auth0Clients = this.auth0Users.filter(
        user => user.role === EUserRoles.CLIENT
      );

      let tmpAuth0Users;
      if (this.userRoleOption === null) {
        tmpAuth0Users = aut0Companions.concat(
          auth0Students.concat(auth0Clients)
        );
      } else if (this.userRoleOption === EUserRoles.COMPANION) {
        tmpAuth0Users = aut0Companions;
      } else if (this.userRoleOption === EUserRoles.STUDENT) {
        tmpAuth0Users = auth0Students;
      } else if (this.userRoleOption === EUserRoles.CLIENT) {
        tmpAuth0Users = auth0Clients;
      }

      if (hasParts) {
        return this.filterUsersSearchField(
            tmpAuth0Users,
            searchParts
        );
      }

      return tmpAuth0Users;
    }
  },
  methods: {
    ...mapActions("loginAs",["setUserId"]),
    filterUsersSearchField(tmpAuth0Users, searchParts){
      return tmpAuth0Users.filter(user => {
        for (const searchPart of searchParts) {
          if (searchPart) {
            if (!user.profile.name.toLowerCase().includes(searchPart)) {
              return false;
            }
          }
        }
        return true;
      });
    },
    setImpersonateUserId(userId) {
      this.setUserId(userId);
    },
    getAllUsers() {
      this.loading = true;
      this.loadingError = false;
      userApi
        .getAllUsers()
        .then(res => {
          const users = res.data.data;
          this.auth0Users = [];
          this.auth0Companions = [];
          this.auth0Students = [];
          this.auth0Clients = [];

          const companionsAndStudents = users.filter(
            user =>
              user.role === EUserRoles.COMPANION ||
              user.role === EUserRoles.STUDENT ||
              user.role === EUserRoles.CLIENT
          );
          const auth0Users = companionsAndStudents.filter(user =>
            user.identity.includes("auth0")
          );

          this.auth0Users = auth0Users;
          this.loading = false;
          this.loadingError = false;
        })
        .catch(err => {
          this.loadingError = true;
          this.loading = false;
        });
    }
  },
  created() {
    this.getAllUsers();
  },
  mounted() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
  }
};
</script>

<style scoped></style>
